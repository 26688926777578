import routes from 'src/router/routes';
import locales_es from 'src/locales/es.json';

// Status labels
export const STATUS_PENDING = 'pending';
export const STATUS_NEXT = 'next';
export const STATUS_CANCELED = 'canceled';
export const STATUS_SOBRETURNO = 'sobreturno';

//Messages
export const API_ERROR_MESSAGE = 'Ha ocurrido un error';

// User Types

export const USER_TYPE_MEDIC = 'medic';
export const USER_TYPE_PATIENT = 'patient';

export const PRIMARY_COLOR = '#1cac81';
export const PRIMARY_BG_COLOR = '#ffffff';
export const DANGER_COLOR = '#fd397a';

export const PATIENT_MENU_ITEMS = [
  {
    to: routes.home,
    icon: 'calendar',
    label: locales_es.homeHeader.myAppointments,
  },
  {
    to: routes.my_medics,
    icon: 'stethoscope',
    label: locales_es.homeHeader.myMedics,
  },
];

export const MEDIC_MENU_ITEMS = [
  { to: routes.home, icon: 'stethoscope', label: locales_es.homeHeader.home },
  {
    to: routes.home_schedule,
    icon: 'calendar',
    label: locales_es.homeHeader.schedule,
  },
  {
    to: routes.home_patients,
    icon: 'address-book',
    label: locales_es.homeHeader.patients,
  },
  {
    to: routes.home_prescriptions,
    icon: 'add-document',
    label: locales_es.homeHeader.prescriptions,
  },
];
