import { actionsArrayType, ListAction } from './ListAction';
import IconRounded from '../IconRounded';
import ImageBlurhash from '../ImageBlurhash';
import styles from './ListItem.module.scss';
import { Reorder } from 'framer-motion';

interface Props {
  item: any;
  actionsArray: actionsArrayType;
  children: JSX.Element | JSX.Element[];
}

const ListItem = ({ item, actionsArray, children }: Props) => {
  const mapActions = actionsArray({ itemId: item.id.toString() });
  return (
    <Reorder.Item value={item} style={{ borderRadius: 24, margin: 10 }}>
      <div className={styles.container}>
        <div className={styles.data_container}>
          <div className={styles.icon_drag}>
            <IconRounded
              size={40}
              icon='drag'
              iconColor='#1cac81'
              cursor='grab'
            />
          </div>
          {item.logo_image && (
            <div className={styles.logo_image_container}>
              <ImageBlurhash
                hashUrl={item.logo_image}
                imageUrl='https://img.freepik.com/vector-gratis/diseno-logotipo-degradado-colorido-letra_474888-2309.jpg'
                height={100}
                width={100}
              />
            </div>
          )}
          {children}
        </div>
        <div className={styles.actions_container}>
          {mapActions.map((cli, i) => (
            <ListAction key={i} {...cli} id={item.id.toString()} />
          ))}
        </div>
      </div>
    </Reorder.Item>
  );
};

export default ListItem;
