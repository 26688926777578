import React from 'react';
import { TextInput } from '../FormInput';
import { TextInput as TextInputProps } from 'src/utils/models';
import styles from './AmountTypeConfig.module.scss';
import clsx from 'clsx';

interface Props {
  title: string;
  subtitle: string;
  notes: string[];
  inputSimbol: string;
  field: TextInputProps;
  selected: boolean;
  onClick: () => void;
}

const AmountTypeConfig = ({
  title,
  subtitle,
  notes,
  inputSimbol,
  field,
  selected,
  onClick,
}: Props) => {
  return (
    <div
      className='container'
      onClick={onClick}
      role='button'
      aria-roledescription='in advanced payment type (percentage or fixed amount)'
    >
      <div
        className={clsx(
          styles.container,
          'col-12',
          selected ? styles.active : styles.inactive
        )}
      >
        <div className='container p-3'>
          <h6>{title}</h6>
          <p>{subtitle}</p>
          <ul>
            {notes.map((note) => (
              <li key={note}>
                <small className={styles.noteText}>{note}</small>
              </li>
            ))}
          </ul>
          <div className='d-flex align-items-center'>
            <div className={styles.inputSimbol}>{inputSimbol}</div>
            <div className={styles.inputContainer}>
              <TextInput
                name={field.name}
                disabled={!selected}
                errorStyles={{ color: selected ? '#ffffff' : '#b25357' }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AmountTypeConfig;
