import styles from '../IconRounded/IconRounded.module.scss';

interface Props {
  size?: number;
  classes?: string;
  roundness?: string;
  alt: string;
  src: string;
  componentStyles?: React.CSSProperties;
  imageStyles?: React.CSSProperties;
  onClick?: (e?: any) => void;
}

const ImagePreview = ({
  src,
  alt,
  size = 100,
  classes = '',
  roundness = '100%',
  imageStyles = {},
  componentStyles = {},
  onClick = () => {},
}: Props) => {
  return (
    <div
      style={{
        width: size,
        height: size,
        borderRadius: roundness,
        cursor: !!onClick && 'pointer',
        ...componentStyles,
      }}
      className={`${styles.icon_container}  d-flex align-items-center justify-content-center ${classes}`}
      onClick={onClick}
    >
      <img
        src={src}
        alt={alt}
        style={{
          width: size,
          height: size,
          borderRadius: roundness,
          ...imageStyles,
        }}
      />
    </div>
  );
};

export default ImagePreview;
