import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import styles from './Alert.module.scss';
import { icons } from '../IconButton/IconDictionary';
import { icon } from 'src/utils/models';
import IconButton from '../IconButton';

interface Props {
  open: boolean;
  close?: () => void;
  variant: 'danger' | 'primary' | 'warning' | 'success' | 'message';
  icon?: boolean;
  dismissable: boolean;
  children: React.ReactNode;
  className?: string;
}

const mapIcon = {
  danger: 'alertTriangle',
  primary: 'bell',
  warning: 'bell',
  message: 'bell',
  success: 'checkCircle',
};

const Alert: React.FC<Props> = ({
  open,
  close,
  variant,
  icon = false,
  dismissable,
  children,
  className = '',
}) => {
  return (
    <AnimatePresence>
      {open && (
        <motion.div
          className={`${styles.alert} ${styles[variant]}`}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          {icon && (
            <div className={styles.icon}>{icons[mapIcon[variant] as icon]}</div>
          )}
          <div
            className={`${styles.alert_content} ${styles[variant]} ${className}`}
          >
            <>{children}</>
          </div>

          {dismissable && (
            <div className={`${styles.alert_close}`}>
              <IconButton
                icon='cross'
                variant={'transparent'}
                buttonSize={40}
                iconSize={40}
                handleClick={close}
              />
            </div>
          )}
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default Alert;
