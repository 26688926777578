import Navbar from 'src/components/Navbar';
import { HeaderProps } from '.';
import MyClinics from '../MyClinics';
import { Logo } from './Logo/Logo';
import { UserInfo } from './UserInfo/UserInfo';
import { useAppSelector } from 'src/app/hooks';
import styles from './HomeHeader.module.scss';
import { MEDIC_MENU_ITEMS } from 'src/utils/constants';

export const MedicDesktopHomeHeader = ({ toggleLogoutModal }: HeaderProps) => {
  const user = useAppSelector((state) => state.user?.data);
  const fullName = `${user?.name} ${user?.lastname}` || '';

  return (
    <div className={`d-flex align-items-center w-100 ${styles.container}`}>
      <Logo />
      <div
        className={`align-items-center justify-content-between  ${styles.info_container}`}
      >
        <div
          className={`d-flex align-items-center ${styles.userInfo_container}`}
        >
          <MyClinics />
          <div className='d-block'>
            <UserInfo
              avatarSize={40}
              name={fullName}
              toggleLogoutModal={toggleLogoutModal}
            />
          </div>
        </div>

        <div className='d-flex align-items-center'>
          <Navbar
            style={{ backgroundColor: '#e2e7e5' }}
            items={MEDIC_MENU_ITEMS}
          />
        </div>
      </div>
    </div>
  );
};
