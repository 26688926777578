import { useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import useWindowSize from 'src/hooks/useWindowSize';
import FloatingActionButton from 'src/components/FloatingActionButton';
import NewButtonsMenu from 'src/components/NewButtonsMenu';
import { ButtonProps, icon } from 'src/utils/models';
import routes from 'src/router/routes';
import Button from 'src/components/Button';
import IconRounded from 'src/components/IconRounded';
import { Link } from 'react-router-dom';
import styles from 'src/pages/ClinicUsers/ClinicUsers.module.scss';
import { buttons } from 'src/utils/clinics';
import { BUTTONS_ANIMATION } from 'src/utils/home';

export interface MobileButtonMenu {
  variant: ButtonProps['variant'];
  backgroundColor: string;
  borderColor: string;
  shadow: boolean;
  text: string;
  icon: icon;
  onClick: { (): void };
}

const ClinicUsers = () => {
  const [showButtons, setShowButtons] = useState(false);

  const { isMobile } = useWindowSize();
  const handleButtons = () => {
    setShowButtons(!showButtons);
  };

  if (isMobile === undefined) return <></>;
  return (
    <div className={styles.clinicUsersScreen_container}>
      <div className={`d-flex p-5 ${styles.layout_container}`}>
        <div className={styles.clinics_container}>
          <Link to={routes.clinics_new}>
            <Button
              variant='primary'
              style={{
                margin: '30px auto',
                width: '50%',
                height: 50,
              }}
            >
              <div className={styles.button_content}>
                <IconRounded
                  size={30}
                  icon='plus'
                  iconColor='#FFFFFF'
                  onClick={() => {}}
                  classes={'border border-1 border-white'}
                />
                <span>Invitar Usuario</span>
              </div>
            </Button>
          </Link>
        </div>

        <AnimatePresence>
          {showButtons && (
            <NewButtonsMenu
              buttons={buttons}
              animate
              animation={BUTTONS_ANIMATION}
            />
          )}
        </AnimatePresence>
        {isMobile && (
          <FloatingActionButton
            buttonSize={60}
            variant='primary'
            iconSize={30}
            icon='plus'
            handleClick={handleButtons}
            active={showButtons}
          />
        )}

        {!isMobile && (
          <div
            className={`d-flex flex-column justify-content-start ${styles.layout_right_container}`}
          >
            <NewButtonsMenu buttons={buttons} animate={false} />
          </div>
        )}
      </div>
    </div>
  );
};

export default ClinicUsers;
