import { motion } from 'framer-motion';
import useWindowSize from 'src/hooks/useWindowSize';
import DesktopDashboardSchedule from './DesktopDashboardSchedule';
import MobileDashboardSchedule from './MobileDashboardSchedule';

const ScheduleScreen = () => {
  const { isMobile } = useWindowSize();
  if (isMobile === undefined) return <></>;
  return (
    <motion.div
      animate={{ opacity: 1, transition: { duration: 1 } }}
      initial={{ opacity: 0 }}
    >
      {isMobile ? <MobileDashboardSchedule /> : <DesktopDashboardSchedule />}
    </motion.div>
  );
};

export default ScheduleScreen;
