import clsx from 'clsx';

interface Props {
  title: string;
  subtitle: string;
  classes?: string;
  titleStyles?: React.CSSProperties;
  subTitleStyles?: React.CSSProperties;
}

const Heading = ({
  classes = '',
  title,
  subtitle,
  titleStyles = {},
  subTitleStyles = {},
}: Props) => {
  return (
    <div className={classes}>
      <div className='text-center '>
        <h2
          className={clsx(!titleStyles.color && 'text-primary')}
          style={{ fontWeight: 500, ...titleStyles }}
        >
          {title}
        </h2>
        <p
          className='text-dark lh-base'
          style={{ fontWeight: 400, ...subTitleStyles }}
        >
          {subtitle}
        </p>
      </div>
    </div>
  );
};

export default Heading;
