import { ClipLoader } from 'react-spinners';
import { useNavigate } from 'react-router-dom';
import Button from 'src/components/Button';
import IconButton from 'src/components/IconButton';
import routes from 'src/router/routes';
import { Select } from 'src/components/FormInput/Select';
import { clsx } from 'clsx';
import styles from './MyClinics.module.scss';
import locales_es from 'src/locales/es.json';
import { useMyClinics } from 'src/queries/queries';
import useWindowSize from 'src/hooks/useWindowSize';

const MyClinics = () => {
  const { data: clinics, isLoading } = useMyClinics();
  const navigate = useNavigate();
  const { isMobile } = useWindowSize();

  return isLoading ? (
    <ClipLoader color='#ffffff' />
  ) : (
    <div
      className={clsx(
        'd-flex align-items-center justify-content-between w-100',
        styles.clinic,
        isMobile && styles.mobile_clinic
      )}
    >
      {!clinics?.data.data.length && (
        <Button
          variant='outline-white'
          handleClick={() => {
            navigate(routes.clinics);
          }}
        >
          <p className='m-0'>{locales_es.addClinic}</p>
        </Button>
      )}
      {clinics?.data.data.length === 1 && (
        <>
          <div className={styles.clinics_select_container}>
            <h4 className={styles.clinics_clinic_text}>
              {clinics.data?.data[0].name}
            </h4>
          </div>

          <div className='d-none d-md-block'>
            <Button
              variant='outline-white'
              handleClick={() => {
                navigate(routes.clinics);
              }}
            >
              <p className='m-0'>{locales_es.seeAll}</p>
            </Button>
          </div>
        </>
      )}
      {clinics?.data.data.length > 1 && (
        <>
          <div className={styles.clinics_select_container}>
            <Select
              list={clinics?.data.data}
              name='clinics'
              handleBlur={() => {}}
              variant='outline'
              label=''
              selectFirstElement
              isForm={false}
              onChange={() => {}}
            />
          </div>

          <div className='d-none d-md-block'>
            <Button
              variant='outline-white'
              handleClick={() => {
                navigate(routes.clinics);
              }}
            >
              <p className='m-0'>{locales_es.seeAll}</p>
            </Button>
          </div>
        </>
      )}

      <div className='d-flex align-items-center justify-content-center p-1 d-md-none'>
        <IconButton
          icon={'downArrow'}
          classes='border border-customGrey'
          buttonSize={30}
          iconSize={10}
          variant='white'
          handleClick={() => {
            navigate(routes.clinics);
          }}
        />
      </div>
    </div>
  );
};

export default MyClinics;
