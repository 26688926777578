import { motion } from 'framer-motion';
import ClinicUsersComponent from 'src/components/ClinicUsers';
import styles from './ClinicUsers.module.scss';

const ClinicUsers = () => {
  return (
    <motion.div
      animate={{ opacity: 1, transition: { duration: 1 } }}
      initial={{ opacity: 0 }}
      className={styles.dashboardClinicsScreen_container}
    >
      <ClinicUsersComponent />
    </motion.div>
  );
};

export default ClinicUsers;
