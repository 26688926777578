import React, { useEffect, useRef, useState, CSSProperties } from 'react';
import { ErrorMessage } from 'formik';
import styles from './FormInput.module.scss';
import { clsx } from 'clsx';

const RADIUS = 24;

type variant = 'primary' | 'secondary' | 'grey' | 'outline' | 'disabled';
interface Item {
  id: string;
  name: string;
  color?: string;
}

interface Props {
  label: string;
  name: string;
  list: any;
  variant: variant;
  onChange?: any;
  placeholder?: string;
  classes?: string;
  style?: React.CSSProperties;
  disabled?: boolean;
  handleBlur?: () => void;
  [x: string]: any;
  testId?: string;
  isForm?: boolean;
  selectFirstElement?: boolean;
  labelStyles?: CSSProperties;
  initialValueName?: string;
}

export const Select = ({
  label,
  classes = '',
  style = {},
  onChange = () => {},
  disabled = false,
  placeholder = 'Selecciona una opción',
  list = [],
  variant,
  testId,
  handleBlur,
  isForm = true,
  selectFirstElement = false,
  labelStyles,
  initialValueName = '',
  ...props
}: Props) => {
  const [showList, setShowList] = useState(false);
  const [selected, setSelected] = useState(initialValueName);
  const selectRef = useRef<any>(null);
  const handleShowList = () => {
    if (disabled || variant === 'disabled') return;

    setShowList(!showList);
  };
  useEffect(() => {
    if (disabled) {
      setSelected('');
    }
  }, [disabled]);

  useEffect(() => {
    if (list.length === 1 || selectFirstElement) {
      setSelected(list[0]?.name);
      onChange(list[0]?.id);
    }
  }, [list.length]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setShowList(false);
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [setShowList]);

  return (
    <div className={classes} style={style}>
      <label htmlFor={props.name} className={styles.label} style={labelStyles}>
        {label}
      </label>

      <div
        className={clsx(
          showList && styles.extendedTagInput_Suggestions,
          styles.simpleField,
          styles.select,
          styles[variant],
          'd-flex justify-content-between'
        )}
        onClick={handleShowList}
        onFocus={() => {}}
        onBlur={handleBlur}
        style={{
          cursor: 'pointer',
          borderBottomLeftRadius: showList ? 0 : RADIUS,
          borderBottomRightRadius: showList ? 0 : RADIUS,
          borderBottomWidth: showList && variant === 'outline' ? 0 : 1,
        }}
        ref={selectRef}
        data-cy={testId}
      >
        <div className='w-100' style={{ maxHeight: 200 }} data-testid={testId}>
          <p className={styles.select_text}>
            {selected ? selected : placeholder}
          </p>
          {showList && (
            <ul className={`${styles.selectList}`}>
              {list.map((item: Item, i: number) => (
                <li
                  key={item.id}
                  onClick={() => {
                    onChange(item.id);
                    setSelected(item.name);
                  }}
                  data-cy={`${testId}_${i}`}
                >
                  <div className='d-flex align-items-center justify-content-start'>
                    {item.color && (
                      <div
                        style={{
                          backgroundColor: item.color,
                          width: 20,
                          height: 20,
                          marginLeft: 5,
                          marginRight: 5,
                          borderRadius: 100,
                        }}
                      />
                    )}
                    {item.name}
                  </div>
                </li>
              ))}
            </ul>
          )}
        </div>

        <div className={`${styles.icon_container}`}>V</div>
      </div>

      {isForm && (
        <div className='p2' style={{ height: 12 }}>
          <ErrorMessage
            name={props.name}
            component='div'
            className={styles.errorMessage}
            data-cy='select-errorMessage'
          />
        </div>
      )}
    </div>
  );
};
