import * as Yup from 'yup';
import {
  DateInput,
  SelectInput,
  SwitchInput,
  TextAreaInput,
  TextInput,
} from 'src/utils/models';
import locales_es from 'src/locales/es.json';

const REQUIRED = 'Campo requerido';
const INVALID_EMAIL = 'Correo electrónico inválido';

export const firstName_input: TextInput = {
  type: 'text',
  name: 'name',
  label: locales_es.firstName,
  variant: '',
  validation: Yup.string().trim().required(REQUIRED),
  wrapperClassName: 'd-inline-block col-md-6',
  dataCy: 'registerForm_name_textField',
  initialValue: '',
};
export const lastName_input: TextInput = {
  type: 'text',
  name: 'lastname',
  label: locales_es.lastName,
  variant: '',
  validation: Yup.string().trim().required(REQUIRED),
  wrapperClassName: 'd-inline-block col-md-6',
  dataCy: 'registerForm_lastName_textField',
  initialValue: '',
};

export const email_input: TextInput = {
  type: 'text',
  name: 'email',
  label: locales_es.emailAddress,
  variant: '',
  validation: Yup.string().email(INVALID_EMAIL).required(REQUIRED),
  wrapperClassName: 'd-inline-block col-md-6',
  dataCy: 'registerForm_email_textField',
  initialValue: '',
};

export const emailRepeat_input: TextInput = {
  type: 'text',
  name: 'email_repeat',
  label: locales_es.repeatEmailAddress,
  variant: '',
  validation: Yup.string().email(INVALID_EMAIL).required(REQUIRED),
  wrapperClassName: 'd-inline-block col-md-6',
  dataCy: 'registerForm_emailRepeat_textField',
  initialValue: '',
};
export const password_input: TextInput = {
  type: 'password',
  name: 'password',
  label: locales_es.password,
  variant: '',
  validation: Yup.string().trim().required(REQUIRED),
  wrapperClassName: 'd-inline-block col-md-6',
  dataCy: 'registerForm_password_textField',
  initialValue: '',
};

export const passwordConfirmation_input: TextInput = {
  type: 'password',
  name: 'password_confirmation',
  label: locales_es.repeatPassword,
  variant: '',
  validation: Yup.string().trim().required(REQUIRED),
  wrapperClassName: 'd-inline-block col-md-6',
  dataCy: 'registerForm_passwordConfirmation_textField',
  initialValue: '',
};

export const phoneCode_input: TextInput = {
  type: 'countryCode',
  name: 'cellphone',
  label: locales_es.phoneNumber,
  variant: 'grey',
  validation: Yup.string().trim().required(REQUIRED).min(6, REQUIRED),
  wrapperClassName: 'd-inline-block col-6',
  dataCy: 'registerForm_phoneCode_selectField',
  initialValue: '',
  children: '',
};

export const cellphone_input: TextInput = {
  type: 'text',
  name: 'cellphone',
  label: locales_es.mobileNumber,
  variant: '',
  validation: Yup.string().trim().required(REQUIRED),
  wrapperClassName: 'd-inline-block col-6',
  dataCy: 'registerForm_cellphone_textField',
  initialValue: '',
};

export const identification_type_id_input: SelectInput = {
  type: 'select',
  name: 'identification_type_id',
  placeholder: locales_es.identificationType,
  options: [],
  label: 'Tipo de documento',
  variant: 'grey',
  validation: Yup.string().trim().required(REQUIRED),
  wrapperClassName: 'd-inline-block col-md-6',
  dataCy: 'registerForm_identification_type_id_selectField',
  initialValue: '',
};

export const identification_input: TextInput = {
  type: 'text',
  name: 'identification',
  label: locales_es.identificationNumber,
  variant: '',
  validation: Yup.string().trim().required(REQUIRED),
  wrapperClassName: 'd-inline-block col-md-6',
  dataCy: 'registerForm_identification_textField',
  initialValue: '',
};

export const genders_input: SelectInput = {
  type: 'select',
  name: 'gender_id',
  placeholder: locales_es.autoperceivedGender,
  options: [],
  label: 'Género Autopercibido',
  variant: 'grey',
  validation: Yup.string().trim().required(REQUIRED),
  wrapperClassName: 'd-inline-block col-md-6',
  dataCy: 'registerForm_genders_selectField',
  initialValue: '',
};

export const specialties_input: SelectInput = {
  type: 'select',
  name: 'specialty_id',
  placeholder: 'Especialidad',
  options: [],
  label: locales_es.specialty,
  variant: 'grey',
  validation: Yup.string().trim().required(REQUIRED),
  wrapperClassName: 'd-inline-block col-md-6',
  dataCy: 'registerForm_specialties_selectField',
  initialValue: '',
};
export const timeZone_input: SelectInput = {
  type: 'select',
  name: 'time_zone',
  placeholder: 'Huso horario predeterminado *',
  options: [],
  label: locales_es.timeZone,
  variant: 'grey',
  validation: Yup.string().trim().required(REQUIRED),
  wrapperClassName: 'd-inline-block col-md-6',
  dataCy: 'registerForm_timezone_selectField',
  selectFirstElement: true,
  initialValue: '',
};
export const date_of_birth_input: DateInput = {
  type: 'date',
  name: 'date_of_birth',
  label: locales_es.date_of_birth,
  placeholder: locales_es.date_of_birth,
  variant: '',
  validation: Yup.date().typeError(REQUIRED).required(REQUIRED),
  wrapperClassName: 'd-inline-block col-md-6',
  dataCy: 'registerForm_date_of_birth_textField',
};

export const country_input: TextInput = {
  type: 'text',
  name: 'country',
  label: locales_es.country,
  variant: '',
  validation: Yup.string().trim().required(REQUIRED),
  wrapperClassName: 'd-inline-block col-md-6',
  dataCy: 'clinicsForm_country_textField',
  initialValue: '',
};

export const province_input: TextInput = {
  type: 'text',
  name: 'province',
  label: 'Provincia y Localidad o Estado de la Clínica*',
  variant: '',
  validation: Yup.string().trim().required(REQUIRED),
  wrapperClassName: 'd-inline-block col-md-6',
  dataCy: 'clinicsForm_province_textField',
  initialValue: '',
};

export const clinicName_input: TextInput = {
  type: 'text',
  name: 'clinicName',
  label: 'Nombre de la Clínica*',
  variant: '',
  validation: Yup.string().trim().required(REQUIRED),
  wrapperClassName: 'd-inline-block col-md-6',
  dataCy: 'clinicsForm_clinicName_textField',
  initialValue: '',
};

export const address_input: TextInput = {
  type: 'text',
  name: 'address',
  label: 'Dirección de la Clínica*',
  variant: '',
  validation: Yup.string().trim().required(REQUIRED),
  wrapperClassName: 'd-inline-block col-md-6',
  dataCy: 'clinicsForm_address_textField',
  initialValue: '',
};

export const clinicDescription_input: TextInput = {
  type: 'text',
  name: 'clinicDescription',
  label: 'Dirección de la Clínica*',
  variant: '',
  validation: Yup.string().trim().required(REQUIRED),
  wrapperClassName: 'd-inline-block col-md-6',
  dataCy: 'clinicsForm_clinicDescription_textField',
  initialValue: '',
};

export const webSite_input: TextInput = {
  type: 'text',
  name: 'webSite',
  label: locales_es.website,
  variant: '',
  validation: Yup.string(),
  wrapperClassName: 'd-inline-block col-md-6',
  dataCy: 'clinicsForm_webSite_textField',
  initialValue: '',
};

export const aditionalData_input: TextInput = {
  type: 'text',
  name: 'aditionalData',
  label: 'Dirección de la Clínica*',
  variant: '',
  validation: Yup.string().trim().required(REQUIRED),
  wrapperClassName: 'd-inline-block col-md-6',
  dataCy: 'clinicsForm_aditionalData_textField',
  initialValue: '',
};

export const slugname_input: TextInput = {
  type: 'text',
  name: 'slugname',
  label: locales_es.slugName,
  variant: '',
  validation: Yup.string(),
  wrapperClassName: 'd-inline-block col-md-6',
  dataCy: 'editProfile_slugname_textField',
  initialValue: '',
};

export const prefix_input: TextInput = {
  type: 'text',
  name: 'prefix',
  label: locales_es.prefixNamePlaceholder,
  variant: '',
  validation: Yup.string(),
  wrapperClassName: 'd-inline-block col-md-6',
  dataCy: 'editProfile_prefix_textField',
  initialValue: '',
};

export const license_input: TextInput = {
  type: 'text',
  name: 'license',
  label: 'RN: 123456/678901',
  variant: '',
  validation: Yup.string(),
  wrapperClassName: 'd-inline-block col-md-6',
  dataCy: 'editProfile_license_textField',
  initialValue: '',
};

export const bio_input: TextInput = {
  type: 'text',
  name: 'bio',
  label: locales_es.medicBio,
  variant: '',
  validation: Yup.string(),
  wrapperClassName: 'd-inline-block col-md-6',
  dataCy: 'editProfile_bio_textField',
  initialValue: '',
};

export const facebook_input: TextInput = {
  type: 'text',
  name: 'facebook',
  label: locales_es.facebook,
  variant: '',
  validation: Yup.string(),
  wrapperClassName: 'd-inline-block col-md-6',
  dataCy: 'editProfile_facebook_textField',
  initialValue: '',
};

export const twitter_input: TextInput = {
  type: 'text',
  name: 'twitter',
  label: locales_es.twitter,
  variant: '',
  validation: Yup.string(),
  wrapperClassName: 'd-inline-block col-md-6',
  dataCy: 'editProfile_twitter_textField',
  initialValue: '',
};

export const instagram_input: TextInput = {
  type: 'text',
  name: 'instagram',
  label: locales_es.instagram,
  variant: '',
  validation: Yup.string(),
  wrapperClassName: 'd-inline-block col-md-6',
  dataCy: 'editProfile_instagram_textField',
  initialValue: '',
};

export const linkedin_input: TextInput = {
  type: 'text',
  name: 'linkedin',
  label: locales_es.linkedIn,
  variant: '',
  validation: Yup.string(),
  wrapperClassName: 'd-inline-block col-md-6',
  dataCy: 'editProfile_linkedin_textField',
  initialValue: '',
};

export const publicEmail_input: TextInput = {
  type: 'text',
  name: 'publicEmail',
  label: locales_es.publicEmail,
  variant: '',
  validation: Yup.string().email(INVALID_EMAIL),
  wrapperClassName: 'd-inline-block col-md-6',
  dataCy: 'editProfile_publicEmail_textField',
  initialValue: '',
};

export const publicPhone_input: TextInput = {
  type: 'text',
  name: 'publicPhone',
  label: locales_es.publicPhone,
  variant: '',
  validation: Yup.string(),
  wrapperClassName: 'd-inline-block col-md-6',
  dataCy: 'editProfile_publicPhone_textField',
  initialValue: '',
};

export const online_payment_input: SwitchInput = {
  type: 'switch',
  name: 'onlinePayment',
  label: locales_es.onlinePayment,
  title: false,
  validation: Yup.boolean(),
  dataCy: 'configs_onlinePayment_switch',
  wrapperClassName: 'd-flex align-items-center justify-content-center',
};
export const arrange_with_patient_input: SwitchInput = {
  type: 'switch',
  name: 'arrangeWithPatient',
  label: locales_es.toBeArrangedWithPacient,
  title: false,
  validation: Yup.boolean(),
  dataCy: 'configs_arrangeWithPatient_switch',
  wrapperClassName: 'd-flex align-items-center justify-content-center',
};
export const debit_or_credit_input: SwitchInput = {
  type: 'switch',
  name: 'debitOrCredit',
  label: locales_es.debitOrCreditCardPayment,
  title: false,
  validation: Yup.boolean(),
  dataCy: 'configs_debitOrCredit_switch',
  wrapperClassName: 'd-flex align-items-center justify-content-center',
};

export const bank_wire_input: SwitchInput = {
  type: 'switch',
  name: 'bankWire',
  label: locales_es.bankWire,
  title: false,
  validation: Yup.boolean(),
  dataCy: 'configs_bankWire_switch',
  wrapperClassName: 'd-flex align-items-center justify-content-center',
};

export const bank_wire_info_input: TextAreaInput = {
  type: 'textArea',
  name: 'bankWireInfo',
  label: locales_es.bankWireInfo,
  placeholder: locales_es.bankWireInfoPlaceholder,
  variant: '',
  validation: Yup.string(),
  wrapperClassName: 'd-block',
  dataCy: 'configs_publicPhone_textField',
  initialValue: '',
};

export const percentage_input: TextInput = {
  type: 'text',
  name: 'before_payment_amount',
  label: '',
  variant: '',
  validation: Yup.number(),
  wrapperClassName: 'd-block',
  dataCy: 'configs_percentage_textField',
  initialValue: '',
};
export const fixed_amount_input: TextInput = {
  type: 'text',
  name: 'before_payment_amount',
  label: '',
  variant: '',
  validation: Yup.number(),
  wrapperClassName: 'd-block',
  dataCy: 'configs_fixedAmount_textField',
  initialValue: '',
};
