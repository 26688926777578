import { CSSProperties } from 'react';
import { icon } from 'src/utils/models';
import { ErrorMessage, useField } from 'formik';
import { icons } from '../IconButton/IconDictionary';
import styles from './FormInput.module.scss';
import { InputValidation } from './InputValidation/InputValidation';
import { clsx } from 'clsx';

interface Props {
  name: string;
  label?: string;
  defaultValue?: string;
  type?: 'text' | 'email' | 'password';
  placeholder?: string;
  id?: string;
  classes?: string;
  showPass?: boolean;
  iconLeft?: icon;
  handleEyeClick?: () => void;
  disabled?: boolean;
  [x: string]: any;
  style?: React.CSSProperties;
  variant?: 'textInput_transparent' | null;
  testId?: string;
  labelStyles?: CSSProperties;
  errorStyles?: CSSProperties;
  isRequired?: boolean;
}

export const TextInput = ({
  label,
  classes = '',
  showPass = false,
  iconLeft,
  handleEyeClick,
  disabled = false,
  style = {},
  variant,
  testId,
  labelStyles,
  errorStyles,
  isRequired,
  ...props
}: Props) => {
  const [field, meta] = useField(props);
  return (
    <div className={classes} style={style}>
      <label
        htmlFor={props.id || props.name}
        className={styles.label}
        style={labelStyles}
      >
        {label}
      </label>

      <div
        className='d-flex align-items-center'
        style={{ position: 'relative' }}
      >
        {iconLeft?.length && (
          <div
            className={`${styles.icon_container_left}`}
            style={{ position: 'absolute' }}
          >
            <i className={`${styles.icon_left}`}>{icons[iconLeft]}</i>
          </div>
        )}
        <input
          className={clsx(
            styles.simpleField,
            iconLeft?.length && styles.morePaddingLeft,
            variant && styles[variant],
            'w-100'
          )}
          autoComplete='off'
          disabled={disabled}
          id={props.id || props.name}
          aria-label={props.id || props.name}
          data-testid={testId}
          {...field}
          {...props}
        />
        {isRequired && meta.touched && !showPass && (
          <InputValidation error={meta.error} />
        )}

        {showPass && (
          <div
            className={`${styles.icon_container}`}
            style={{ position: 'absolute' }}
            onClick={handleEyeClick}
            aria-label='icon-eye-password'
            data-cy='TextInput-eyeBtn'
          >
            <i className={`${styles.icon}`}>{icons.eye}</i>
          </div>
        )}
      </div>

      <div
        className='p2'
        style={{
          minHeight: 12,
          lineHeight: 1.2,
        }}
      >
        <ErrorMessage name={props.name} data-cy='textInput-errorMessage'>
          {(msg) => (
            <p className={styles.errorMessage} style={errorStyles}>
              {msg}
            </p>
          )}
        </ErrorMessage>
      </div>
    </div>
  );
};
