import DashboardDrafts from 'src/components/DashboardDrafts';

const DraftsScreen = () => {
  return (
    <div>
      <DashboardDrafts />
    </div>
  );
};

export default DraftsScreen;
