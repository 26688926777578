import dayjs from 'dayjs';
import { BsFileEarmarkMedical } from 'react-icons/bs';
import { FiTrash2 } from 'react-icons/fi';
import locales_es from '../../locales/es.json';
import styles from './MedicalRecord.module.scss';
import Button from '../Button';
import { DANGER_COLOR } from 'src/utils/constants';

export interface Record {
  id: number;
  medical_records_type_id: number;
  medic_id: number;
  patient_id: number;
  title: string;
  text: string;
  internal_notes: string;
  weight: number;
  height: number;
  date: Date;
  created_at: Date;
  updated_at: Date;
  deleted_at: null;
  medical_records_type: null;
}

interface Props {
  record: Record;
  handleDeleteMedicalRecord: (id: number) => void;
}

const MedicalRecord = ({ record, handleDeleteMedicalRecord }: Props) => {
  return (
    <div className='p-3'>
      <div>
        <div className='d-flex align-items-center'>
          <div className={styles.iconRoundedContainer}>
            <BsFileEarmarkMedical />
          </div>
          <div className='text-muted fw-bold ps-2 '>
            {dayjs(record.date).format('DD MMMM YYYY')}
          </div>
          {Math.abs(dayjs(record.created_at).diff(dayjs(), 'hour')) < 24 ? (
            <div>
              <Button
                variant='light'
                style={{ padding: 0, minWidth: 40 }}
                handleClick={() => handleDeleteMedicalRecord(record.id)}
              >
                <FiTrash2 size={20} color={DANGER_COLOR} />
              </Button>
            </div>
          ) : null}
        </div>
        <div className='d-flex align-items-center position-relative p-2'>
          <div className={styles.lineContainer}>
            <div className={styles.line} />
          </div>
          <div className={styles.medicalDataContainer}>
            <div className='h5'>
              <strong>{record.title}</strong>
            </div>
            <div>{record.text}</div>
            <div className='pt-2 pb-2'>
              <div className={styles.medicalData}>
                {locales_es.kgWeight}: {record.weight}
              </div>
              <div className={styles.medicalData}>
                {locales_es.cmHeight}: {record.height}
              </div>
            </div>

            <div className='text-muted'>{record.internal_notes}</div>
          </div>
        </div>
        <div></div>
      </div>
    </div>
  );
};

export default MedicalRecord;
