import { Navigate } from 'react-router-dom';
import routes from 'src/router/routes';
import { logout } from 'src/utils/auth';
import { queryCache, queryClient } from 'src';

const Logout = () => {
  logout();
  queryCache.clear();
  queryClient.clear();
  return <Navigate to={routes.auth_login} />;
};

export default Logout;

