import { AppointmentForm } from '@devexpress/dx-react-scheduler-material-ui';
import styles from '../CustomComponents.module.scss';

export const DateEditor = (props: any) => {
  return (
    <div className={`${styles.date_editor}`}>
      <AppointmentForm.DateEditor {...props} />
    </div>
  );
};
