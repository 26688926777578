import { alreadyHaveAccount_action, submit_action } from './actions';
import {
  date_of_birth_input,
  emailRepeat_input,
  email_input,
  firstName_input,
  genders_input,
  identification_input,
  identification_type_id_input,
  lastName_input,
  passwordConfirmation_input,
  password_input,
  phoneCode_input,
  timeZone_input,
} from './inputs';

const registerPatientForm = {
  inputs: [
    firstName_input,
    lastName_input,
    email_input,
    emailRepeat_input,
    password_input,
    passwordConfirmation_input,
    phoneCode_input,
    identification_type_id_input,
    identification_input,
    genders_input,
    timeZone_input,
    date_of_birth_input,
  ],
  actions: [
    alreadyHaveAccount_action,
    { ...submit_action, variant: 'patient' },
  ],
  actionWrapperClassName:
    'container d-flex align-items-center justify-content-between',
};

export default registerPatientForm;
