import { GoogleMap, MarkerF } from '@react-google-maps/api';
import useWindowSize from 'src/hooks/useWindowSize';
import IconButton from '../IconButton';
import styles from './Map.module.scss';
import { MapConfirmBox } from './MapConfirmBox';
import { MapInfoBox } from './MapInfoBox';
import mapStyles from './mapStyle';

interface Center {
  lat: number;
  lng: number;
}
interface Props {
  center: Center;
  handleMarkerDrag: ({ lat, lng }: { lat: number; lng: number }) => void;
  maxMap: boolean;
  closeMap: () => void;
  confirmLocation: () => void;
}

const options = {
  styles: mapStyles,
  fullscreenControl: false,
  streetViewControl: false,
  disableDefaultUI: true,
  zoomControl: false,
};

const Map = ({
  center,
  handleMarkerDrag,
  maxMap,
  closeMap,
  confirmLocation,
}: Props) => {
  const { isDesktop } = useWindowSize();
  return (
    <>
      <GoogleMap
        zoom={17}
        center={center}
        mapContainerClassName={`${
          maxMap ? styles.map_container : styles.mini_map_container
        }`}
        options={options}
      >
        {isDesktop ? (
          <MapInfoBox
            title='Confirme su dirección'
            subTitle='Mueva el PIN para ajustar la ubicación deseada'
            closeMap={closeMap}
          />
        ) : (
          <div className={`${styles.back_container} `}>
            <IconButton
              buttonSize={40}
              icon='angleLeft'
              variant='primary'
              iconSize={40}
              handleClick={closeMap}
            />
          </div>
        )}
        <MarkerF
          position={center}
          draggable
          icon={{
            url: '/images/map_marker.svg',
            scaledSize: new window.google.maps.Size(50, 50),
          }}
          onDragEnd={(data) => {
            const lat = data.latLng?.lat();
            const lng = data.latLng?.lng();
            if (lat && lng) {
              handleMarkerDrag({ lat, lng });
            }
          }}
        />
      </GoogleMap>
      <MapConfirmBox confirmLocation={confirmLocation} />
    </>
  );
};

export default Map;
