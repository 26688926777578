import locales_es from 'src/locales/es.json';
import { MobileButtonMenu } from 'src/components/NewButtonsMenu';
import { actionsArrayType } from 'src/components/List/ListAction';

export const clinicActions: actionsArrayType = ({ itemId }) => [
  {
    icon: 'edit',
    link: '#',
    classes: 'border border-1 border-primary',
    tooltip: 'Editar',
  },
  {
    icon: 'user',
    link: '#',
    classes: 'border border-1 border-primary',
    tooltip: 'Usuarios',
  },
  {
    icon: 'trash',
    link: '',
    classes: 'border border-1 border-primary',
    tooltip: 'Eliminar',
  },
];

export const buttons: MobileButtonMenu[] = [
  {
    variant: 'primary',
    backgroundColor: '#22d29d',
    borderColor: '#199f76',
    shadow: false,
    text: locales_es.newTurn,
    icon: 'calendar',
    onClick: () => {},
  },
  {
    variant: 'primary',
    backgroundColor: '#25ebae',
    borderColor: '#1cb788',
    shadow: false,
    text: locales_es.newPatient,
    icon: 'addressBook',
    onClick: () => {},
  },
  {
    variant: 'primary',
    backgroundColor: '#21dda4',
    borderColor: '#26ffcb',
    shadow: false,
    text: locales_es.newPrescription,
    icon: 'document',
    onClick: () => {},
  },
];
