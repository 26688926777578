import { ReactNode } from 'react';
import { Link } from 'react-router-dom';

import styles from './IconCard.module.scss';
import { FaUser } from 'react-icons/fa6';

interface Data {
  type: string;
  title: string;
  text: string;
  link: string;
  footer: string;
  bg: string;
  footerBg: string;
  icon: ReactNode;
}

interface Props {
  data: Data;
}

const IconCard = ({ data }: Props) => {
  return (
    <Link
      to={data.link}
      key={data.type}
      target={data.type === 'clinic' ? '_blank' : ''}
    >
      <div
        className={styles.cardContainer}
        style={{ backgroundColor: data.bg }}
      >
        <header className={styles.cardHeader}>
          <FaUser />
          <span className={styles.cardHeaderTitle}>{data.title}</span>
        </header>
        <main className={styles.cardMainContent}>
          <div className={styles.mainIconContainer}>{data.icon}</div>
          <div className={styles.cardMainContentText}>{data.text}</div>
        </main>
        <footer
          className={styles.cardFooter}
          style={{ backgroundColor: data.footerBg }}
        >
          {data.footer}
        </footer>
      </div>
    </Link>
  );
};

export default IconCard;
