import { motion } from 'framer-motion';
import { ClipLoader } from 'react-spinners';
import MedicCard from 'src/components/MedicCard';
import SectionTitle from 'src/components/SectionTitle';
import { useMyMedics } from 'src/queries/queries';
import locales_es from 'src/locales/es.json';
import { Medic } from 'src/components/MedicCard/interfaces';
import Search from 'src/components/Search';
import { useState } from 'react';

const MyMedics = () => {
  const { data, isLoading } = useMyMedics();
  const medicsData: Medic[] = data?.data?.data ?? [];
  const [value, setValue] = useState('');

  const filteredData = medicsData.filter((data) =>
    `${data.specialty_name?.toLocaleLowerCase()} ${data.prefix_name?.toLocaleLowerCase()} ${data.name.toLocaleLowerCase()} ${data.lastname.toLocaleLowerCase()}`.includes(
      value.toLocaleLowerCase()
    )
  );

  return (
    <motion.div
      animate={{ opacity: 1, transition: { duration: 1 } }}
      initial={{ opacity: 0 }}
    >
      <SectionTitle
        sectionText={{
          textTitle: locales_es.homeHeader.myMedics,
          textSubtitle: '',
        }}
        showAvatar={false}
        endLine={false}
      />
      {isLoading ? (
        <ClipLoader className='m-auto d-block mt-4' />
      ) : (
        <>
          <div style={{ maxWidth: 600 }} className='m-auto mt-4 p-3'>
            <Search
              placeholder={locales_es.searchByNameOrLastName}
              searchBarStyles={{ width: '100%' }}
              onChange={setValue}
            />
          </div>
          {filteredData.length > 0 ? (
            <div className='row row-gap-4 m-2 pt-3 pb-4'>
              {filteredData.map((medic: Medic) => (
                <div className='col-xl-4 col-lg-6 d-flex' key={medic.id}>
                  <MedicCard medic={medic} />
                </div>
              ))}
            </div>
          ) : (
            <div className='m-auto d-flex mt-4 justify-content-center'>
              {locales_es.noResultsForThisSearch}
            </div>
          )}
        </>
      )}
    </motion.div>
  );
};

export default MyMedics;
