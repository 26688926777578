import styles from 'src/pages/NewClinic/NewClinicScreen.module.scss';
import { useNavigate } from 'react-router-dom';
import BusinessLocationForm from '../BusinessLocationForm';

const NewClinic = () => {
  const navigation = useNavigate();

  return (
    <div className={styles.container}>
      <BusinessLocationForm navigation={navigation} />
    </div>
  );
};

export default NewClinic;
