import IconRounded from 'src/components/IconRounded';
import styles from 'src/pages/DashboardClinics/DashboardClinicsScreen.module.scss';
import { Clinic as ClinicType } from 'src/utils/models';
import Avatar from 'src/components/Avatar';

interface Props {
  clinic: ClinicType;
}

const Clinic = ({ clinic }: Props) => {
  return (
    <div className={styles.clinicList_item_container}>
      <div className='p-2'>
        <Avatar imageUrl={clinic.full_image} size={80} variant='grey' />
      </div>
      <div className='p-2'>
        <div className={styles.icon_text}>
          <p className={`${styles.text} ${styles.title}`}>{clinic.name}</p>
        </div>
        <div className={styles.icon_text}>
          <div className={styles.icon}>
            <IconRounded
              size={30}
              icon='mapMarker'
              iconColor='#969696'
              onClick={() => {}}
            />
          </div>

          <p className={`${styles.text}`}>{clinic.address}</p>
        </div>
        {clinic.phone && (
          <div className={styles.icon_text}>
            <IconRounded
              size={30}
              icon='phone'
              iconColor='#969696'
              onClick={() => {}}
            />
            <p className={`${styles.text}`}>{clinic.phone}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Clinic;
