import { useState, useEffect, useRef } from 'react';

const useImage = () => {
  const [image, setImage] = useState<File | null>(null);
  const [preview, setPreview] = useState<string | null>(null);
  const [imageURL, setImageURL] = useState<any>(null);
  const [openCropper, setOpenCropper] = useState(false);
  const inputHiddenRef = useRef<HTMLInputElement>(null);

  const closeImageCropper = () => {
    setOpenCropper(false);
  };

  const openImageCropper = () => {
    setOpenCropper(true);
  };

  useEffect(() => {
    if (image) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result as string);
      };
      reader.readAsDataURL(image);
    } else {
      setPreview(null);
    }
  }, [image]);

  const handleImageChange = (event: any) => {
    const file = event.target.files[0];
    if (file && file.type.substr(0, 5) === 'image') {
      setImage(file);
      setImageURL(URL.createObjectURL(file) as any);
    } else {
      setImage(null);
    }
  };

  const editImage = (file: any, url: any) => {
    setImage(file);
    setImageURL(url);
  };

  const selectPhoto = () => {
    if (inputHiddenRef.current !== null) {
      inputHiddenRef.current.click();
    }
  };

  return {
    image,
    preview,
    inputHiddenRef,
    imageURL,
    handleImageChange,
    selectPhoto,
    openCropper,
    openImageCropper,
    closeImageCropper,
    editImage,
  };
};

export default useImage;
