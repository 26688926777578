import { Appointments } from '@devexpress/dx-react-scheduler-material-ui';

import styles from './CustomComponents.module.scss';

export const Appointment = ({ children, style, ...restProps }: any) => {
  return (
    <Appointments.Appointment
      {...restProps}
      style={{
        ...style,
        borderRadius: 8,
      }}
      className={`${styles.main_font}`}
    >
      <div className={styles.appointment_coontainer}>{children}</div>
    </Appointments.Appointment>
  );
};
