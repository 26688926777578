import Heading from 'src/components/Heading';
import { RegisterNewForm, userTypesType } from './RegisterNewForm';
import locales_es from 'src/locales/es.json';
import { useSearchParams } from 'react-router-dom';
import clsx from 'clsx';

type colorsType = 'medic' | 'patient' | 'clinic';
const colors = {
  medic: '#9816f4',
  patient: '#5578eb',
  clinic: '#1cac81',
};

const RegisterNew = () => {
  const [searchParams] = useSearchParams();
  const userType = searchParams.get('type') || 'clinic';
  return (
    <>
      <Heading
        title={locales_es.createAccountForFree}
        subtitle=''
        classes={clsx('container d-flex flex-column justify-content-start p-3')}
        titleStyles={{ color: colors[userType as colorsType] }}
      />
      <RegisterNewForm userType={userType as userTypesType} />
    </>
  );
};

export default RegisterNew;
