import { createColumnHelper } from '@tanstack/react-table';
import IconRounded from 'src/components/IconRounded';
import ImageBlurhash from 'src/components/ImageBlurhash';
import styles from './PatientsScreen.module.scss';
import { PRIMARY_COLOR } from 'src/utils/constants';
import routes from 'src/router/routes';

const columnHelper = createColumnHelper<any>();
export const patientTableColumns = (navigate: any) => [
  columnHelper.accessor('full_profile_image', {
    cell: (info) => (
      <ImageBlurhash
        hashUrl={'LEHV6nWB2yk8pyo0adR*.7kCMdnj'}
        height={50}
        width={50}
        imageUrl={info.getValue()}
      />
    ),
    header: () => <>Avatar</>,
  }),
  columnHelper.accessor('name', {
    header: () => <>Nombre</>,
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor('lastname', {
    header: () => <>Apellido</>,
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor('user.email', {
    header: () => <>Email</>,
    cell: (info) => info.getValue() || '-',
  }),
  columnHelper.accessor('user.cellphone', {
    header: () => <>Teléfono</>,
    cell: (info) => info.getValue() || '-',
  }),
  columnHelper.accessor('id', {
    header: () => <>Acciónes</>,
    cell: (info) => (
      <div className={styles.actions_container}>
        <IconRounded
          size={40}
          icon='user'
          iconColor={PRIMARY_COLOR}
          cursor='pointer'
          onClick={() =>
            navigate(
              `${routes.home_patients}/${info.getValue()}/historiaClinica`
            )
          }
        />

        <IconRounded
          size={40}
          icon='edit'
          iconColor={PRIMARY_COLOR}
          onClick={() => {
            navigate(`${info.getValue()}/edit`);
          }}
        />

        <IconRounded
          size={40}
          icon='trash'
          iconColor={PRIMARY_COLOR}
          onClick={() => {}}
        />
      </div>
    ),
  }),
];
