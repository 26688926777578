import { useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import IconButton from 'src/components/IconButton';
import MobileMenu from 'src/components/MobileMenu';
import styles from './HomeHeader.module.scss';
import { Logo } from './Logo/Logo';
import { HeaderProps } from '.';
import { useAppSelector } from 'src/app/hooks';

export const MobileHomeHeader = ({ toggleLogoutModal }: HeaderProps) => {
  const userData = useAppSelector((state) => state.user?.data);
  const [toggleMenu, setToggleMenu] = useState(false);

  const handleMenu = () => {
    setToggleMenu(!toggleMenu);
  };
  return (
    <div
      className={`d-flex align-items-center justify-content-between w-100 ${styles.container} ${styles.mobile_container}`}
    >
      <Logo mobile />
      {/* <div
        className={`dflex align-items-center justify-content-between ${styles.info_container} ${styles.mobile_info_container}`}
      >
        <div
          className={`d-flex align-items-center ${styles.userInfo_container} ${styles.mobile_userInfo_container}`}
        >
          <Hospitals mobile /> */}

      <div
        className={`d-flex align-items-center justify-content-center ${styles.menu_burger_container}`}
      >
        <motion.div whileTap={{ scale: 0.7 }}>
          <IconButton
            icon='menuBurger'
            iconSize={40}
            buttonSize={40}
            variant='marineBlue'
            handleClick={handleMenu}
          />
        </motion.div>
      </div>
      {/* </div>
      </div> */}
      <AnimatePresence>
        {toggleMenu && (
          <MobileMenu
            userData={userData}
            closeMenu={handleMenu}
            toggleLogoutModal={toggleLogoutModal}
          />
        )}
      </AnimatePresence>
    </div>
  );
};
