import Button from 'src/components/Button';

export const ConfirmationButton = (props: any) => {
  return (
    <Button
      variant={props.color || 'outline'}
      handleClick={props.onClick}
      type='button'
    >
      {props.title}
    </Button>
  );
};
