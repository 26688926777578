import { createContext, useState, useContext, useEffect } from 'react';
import { useAppSelector } from 'src/app/hooks';
import Modal, { Action } from 'src/components/Modal';
import { API_ERROR_ICON, Icon, NO_ICON } from 'src/icons/constants';
import { API_ERROR_MESSAGE } from 'src/utils/constants';

interface OpenModalParams {
  title: string;
  subTitle?: string;
  cancellable?: boolean;
  closeButton?: boolean;
  icon?: Icon;
  onClose?: { (): void } | null;
  actions?: Action[];
  children?: JSX.Element;
}

export type openModalType = (params: OpenModalParams) => void;

export interface ModalContextType {
  openModal: openModalType;
}

export const ModalContext = createContext<ModalContextType>({
  openModal: () => {},
});

interface State {
  open: boolean;
  title: string;
  subTitle?: string;
  cancellable?: boolean;
  closeButton?: boolean;
  icon?: Icon;
  onClose?: { (): void } | null;
  actions?: Action[];
  children?: JSX.Element;
}

const initialState: State = {
  open: false,
  title: '',
  subTitle: '',
  cancellable: false,
  closeButton: true,
  icon: NO_ICON,
  onClose: null,
  actions: [],
};

export const ModalContextProvider = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const [state, setState] = useState<State>(initialState);
  const { error: userError } = useAppSelector((state) => state.user);

  useEffect(() => {
    if (userError.isError) {
      const message = userError.message || API_ERROR_MESSAGE;
      openModal({
        title: 'Error',
        subTitle: message,
        icon: API_ERROR_ICON,
      });
    }
  }, [userError.isError]);

  const openModal: openModalType = ({
    title,
    subTitle,
    cancellable = true,
    closeButton = true,
    icon = NO_ICON,
    onClose = null,
    actions = [],
    children,
  }) => {
    setState({
      open: true,
      title,
      subTitle,
      cancellable,
      closeButton,
      icon,
      onClose,
      actions,
      children,
    });
  };

  const closeModal = () => {
    if (state.onClose) {
      state.onClose();
    }
    setState(initialState);
  };

  return (
    <ModalContext.Provider value={{ openModal }}>
      <Modal
        openModal={state.open}
        cancellable={state.cancellable}
        closeModal={closeModal}
        title={state.title}
        subTitle={state.subTitle}
        closeButton={state.closeButton}
        icon={state.icon}
        actions={state.actions}
      >
        {state.children}
      </Modal>

      {children}
    </ModalContext.Provider>
  );
};

export const useModalContext = () => useContext(ModalContext);
