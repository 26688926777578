import { useAppSelector } from 'src/app/hooks';
import { USER_TYPE_MEDIC } from 'src/utils/constants';
import { NewAppointmentForm } from '../NewForms';
import styles from './Dock.module.scss';

const Dock = () => {
  const { drafts } = useAppSelector((state) => state.dock);
  const user = useAppSelector((state) => state.user?.data);
  const openDrafts = drafts.filter((draft) => draft.windowStatus !== 'CLOSED');

  return (
    <>
      {user?.user_type === USER_TYPE_MEDIC && openDrafts.length > 0 && (
        <div className={styles.container}>
          {openDrafts.map((draft) => (
            <NewAppointmentForm draft={draft} key={draft.data.id} />
          ))}
        </div>
      )}
    </>
  );
};

export default Dock;
