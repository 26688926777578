import { useState, useEffect, useRef, useMemo } from 'react';
import { ClipLoader } from 'react-spinners';
import {
  getCoreRowModel,
  useReactTable,
  PaginationState,
} from '@tanstack/react-table';
import { useNavigate, Link } from 'react-router-dom';
import { usePatientsData, useSearchPatients } from 'src/queries/queries';
import locales_es from 'src/locales/es.json';
import { Patient } from 'src/components/PatientCard/interfaces';
import Search from 'src/components/Search';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import Button from 'src/components/Button';
import { FaDownload } from 'react-icons/fa6';
import { patientTableColumns } from './patientsTable';
import routes from 'src/router/routes';
import { queryClient } from 'src';
import { queries } from 'src/queries/constants';
import { getMyPatients, searchMyPatients } from 'src/services/apiService';
import {
  FiChevronsLeft,
  FiChevronLeft,
  FiChevronRight,
  FiChevronsRight,
} from 'react-icons/fi';
import { useDebounce } from 'src/hooks/useDebounce';
import Table from 'src/components/Table';
import Alert from 'src/components/Alert';

export const MyPatientsDesktop = () => {
  const [filteredPatients, setFilteredPatients] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const debouncedSearch = useDebounce({ value: searchQuery, delay: 500 });
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const { data: patientsApi, isLoading: patientsLoading } = usePatientsData({
    currentPage: pageIndex + 1,
    per_page: pageSize,
  });

  const { data: searchPatientsApi } = useSearchPatients({
    query: debouncedSearch,
    page: pageIndex + 1,
    per_page: pageSize,
    enabled: !!debouncedSearch,
  });

  const tableRef = useRef(null);
  const navigate = useNavigate();

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );
  const tablecolumns = useMemo(() => patientTableColumns(navigate), []);

  const { data: patients } = patientsApi?.data || [];
  const { data: searchPatients } = searchPatientsApi?.data || [];

  const table = useReactTable({
    data: filteredPatients,
    columns: tablecolumns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      pagination,
    },
    pageCount: searchPatients?.length
      ? Math.ceil(searchPatientsApi?.data.total / pageSize)
      : Math.ceil(patientsApi?.data.total / pageSize),
    onPaginationChange: setPagination,
    manualPagination: true,
  });

  useEffect(() => {
    if (patients?.length) {
      setFilteredPatients(patients);
    }
  }, [patients]);

  useEffect(() => {
    if (table.getPageCount()) {
      prefetchData();
    }
  }, [pageIndex, table.getPageCount()]);

  useEffect(() => {
    if (!!debouncedSearch && searchPatients?.length) {
      setFilteredPatients(searchPatients);
    } else if (!debouncedSearch && patients?.length) {
      setFilteredPatients(patients);
    } else {
      setFilteredPatients([]);
    }
  }, [searchPatients]);

  const prefetchData = async () => {
    const nextfetch = pageIndex + 2;
    if (nextfetch > table.getPageCount()) return;
    if (searchQuery) {
      await queryClient.prefetchQuery(
        [queries.searchPatients, searchQuery, nextfetch, pageSize],
        async () => await searchMyPatients(searchQuery, nextfetch, pageSize)
      );
    } else {
      await queryClient.prefetchQuery(
        [queries.patients, nextfetch, pageSize],
        async () => await getMyPatients(nextfetch, pageSize)
      );
    }
  };

  const handleFilterPatients = (patient: string) => {
    setSearchQuery(patient);
  };

  return patientsLoading ? (
    <ClipLoader />
  ) : (
    <>
      <div className='pt-5'>
        <div className='container d-flex align-items-center justify-content-center position-relative'>
          <Link to={routes.home_patients_add}>
            <Button variant='primary'>{locales_es.addPatient}</Button>
          </Link>

          {patients.length ? (
            <Search
              placeholder={locales_es.searchByNameOrLastName}
              searchBarStyles={{ maxWidth: 430 }}
              onChange={handleFilterPatients}
            />
          ) : null}
          {filteredPatients.length ? (
            <div style={{ position: 'absolute', right: 0 }}>
              <DownloadTableExcel
                filename='Mis-Pacientes'
                sheet='tablexls'
                currentTableRef={tableRef.current}
              >
                <Button
                  variant='outline-secondary'
                  style={{ margin: 0, minWidth: 0 }}
                >
                  <FaDownload size={15} />
                </Button>
              </DownloadTableExcel>
            </div>
          ) : null}
        </div>
        <table id='table-to-xls' className='d-none' ref={tableRef}>
          <thead>
            <tr>
              <th>Nombre</th>
              <th>Apellido</th>
              <th>E-Mail</th>
              <th>N° de Documento</th>
              <th>Teléfono</th>
              <th>Fecha de Nacimiento</th>
            </tr>
          </thead>
          <tbody>
            {filteredPatients.length
              ? filteredPatients.map((patient: Patient) => {
                  return (
                    <tr key={patient.id}>
                      <td>{patient.name}</td>
                      <td>{patient.lastname}</td>
                      <td>{patient.user?.email || '-'}</td>
                      <td>{patient.identification}</td>
                      <td>{patient.user?.cellphone || '-'}</td>
                      <td>{patient.date_of_birth.toString()}</td>
                    </tr>
                  );
                })
              : null}
          </tbody>
        </table>
        {filteredPatients.length > 0 ? (
          <>
            <Table table={table} />
            <div
              className='d-flex align-items-center justify-content-center pt-3 p-4 gap-5'
              style={{ background: 'white' }}
            >
              <div className='d-flex align-items-center justify-content-center gap-2'>
                <Button
                  variant='outline'
                  handleClick={() => table.setPageIndex(0)}
                  disabled={!table.getCanPreviousPage()}
                  style={{ margin: 0, minWidth: 0 }}
                >
                  <FiChevronsLeft size={20} />
                </Button>
                <Button
                  variant='outline'
                  handleClick={() => table.previousPage()}
                  disabled={!table.getCanPreviousPage()}
                  style={{ margin: 0, minWidth: 0 }}
                >
                  <FiChevronLeft size={20} />
                </Button>
                <span className='d-flex align-items-center gap-1'>
                  <div>{locales_es.page}</div>
                  <strong>
                    {table.getState().pagination.pageIndex + 1} {locales_es.of}{' '}
                    {table.getPageCount()}
                  </strong>
                </span>
                <Button
                  variant='outline'
                  handleClick={() => {
                    console.log('TABLE', pageIndex);
                    table.nextPage();
                  }}
                  disabled={!table.getCanNextPage()}
                  style={{ margin: 0, minWidth: 0 }}
                >
                  <FiChevronRight size={20} />
                </Button>
                <Button
                  variant='outline'
                  handleClick={() =>
                    table.setPageIndex(table.getPageCount() - 1)
                  }
                  disabled={!table.getCanNextPage()}
                  style={{ margin: 0, minWidth: 0 }}
                >
                  <FiChevronsRight size={20} />
                </Button>
              </div>
              <select
                value={table.getState().pagination.pageSize}
                onChange={(e) => {
                  setSearchQuery('');
                  table.setPageSize(Number(e.target.value));
                }}
              >
                {[5, 10, 15, 25, 30, 40, 50].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    Mostrar {pageSize}
                  </option>
                ))}
              </select>
            </div>
          </>
        ) : (
          <Alert variant='message' open dismissable={false}>
            {locales_es.noPatientsFound}
          </Alert>
        )}
      </div>
    </>
  );
};
