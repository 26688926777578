import { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import IconButton from '../IconButton';
import styles from './DayShifts.module.scss';

interface Text {
  bigText: string;
  title: string;
  subTitle: string;
}

interface Props {
  text: Text;
  children: JSX.Element | JSX.Element[];
}

export const DayShifts = ({ text, children }: Props) => {
  const [openShifts, setOpenShifts] = useState(false);
  const [rotate, setRotate] = useState(0);

  const handleShiftsBtnClick = () => {
    setOpenShifts(!openShifts);
    setRotate((prev) => {
      if (prev >= 0) return prev + 180;
      return prev - 180;
    });
  };
  return (
    <div className={`${styles.container}`}>
      <div className={`${styles.shifts_container}`}>
        <div className={`${styles.shifts_data}`}>
          <div className={`${styles.shifts_number}`}>
            <p>{text.bigText}</p>
          </div>
          <div className={`${styles.shifts_text}`}>
            <p className={`${styles.shifts_text_title}`}>{text.title}</p>
            <p className={`${styles.shifts_text_subtitle}`}>{text.subTitle}</p>
          </div>
        </div>

        <motion.div animate={{ rotate }}>
          <IconButton
            icon={'downArrow'}
            classes='border border-lightPurple'
            buttonSize={40}
            iconSize={10}
            variant='transparent'
            handleClick={handleShiftsBtnClick}
          />
        </motion.div>
      </div>

      <AnimatePresence>
        {openShifts && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ ease: 'easeIn', duration: 0.2 }}
          >
            {children}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
