import { alreadyHaveAccount_action, submit_action } from './actions';
import {
  address_input,
  aditionalData_input,
  clinicDescription_input,
  clinicName_input,
  country_input,
  email_input,
  firstName_input,
  identification_input,
  identification_type_id_input,
  lastName_input,
  province_input,
  webSite_input,
} from './inputs';

const registerClinicForm = {
  inputs: [
    country_input,
    province_input,
    clinicName_input,
    address_input,
    clinicDescription_input,
    webSite_input,
    { ...firstName_input, dataCy: 'clinicsForm_firstName_textField' },
    { ...lastName_input, dataCy: 'clinicsForm_lastName_textField' },
    { ...email_input, dataCy: 'clinicsForm_email_textField' },
    {
      ...identification_type_id_input,
      dataCy: 'clinicsForm_identificationType_textField',
    },
    {
      ...identification_input,
      dataCy: 'clinicsForm_identificationNumber_textField',
    },
    aditionalData_input,
  ],
  actions: [alreadyHaveAccount_action, { ...submit_action, variant: 'clinic' }],
  actionWrapperClassName:
    'container d-flex align-items-center justify-content-between',
};

export default registerClinicForm;
