import MiniCalendar from 'src/components/Calendar/MiniCalendar';
import { DayShifts } from 'src/components/DayShifts/DayShifts';
import NewButtonsMenu from 'src/components/NewButtonsMenu';
import { CustomScheduler } from 'src/components/Scheduler/CustomScheduler';
import buttons from './buttons';
import { useAppDispatch } from 'src/app/hooks';
import { shifts_state } from 'src/utils/home';
import { Shift } from 'src/components/DayShifts/Shift';
import styles from 'src/pages/Schedule/Schedule.module.scss';

const DesktopDashboardSchedule = () => {
  const dispatch = useAppDispatch();
  const menuButtons = buttons(dispatch);

  return (
    <>
      <div className={`w-100 ${styles.homeScreen_container}`}>
        <div className={`d-flex p-5 ${styles.layout_container}`}>
          <div className={`${styles.layout_left_container}`}>
            <MiniCalendar />
            <DayShifts
              text={{
                bigText: '10',
                title: 'Turnos',
                subTitle: 'en el día',
              }}
            >
              {shifts_state.map((shift) => (
                <Shift key={shift.type} {...shift} />
              ))}
            </DayShifts>
          </div>
          <div className={`${styles.layout_center_container}`}>
            <CustomScheduler />
          </div>
          <div
            className={`d-flex flex-column justify-content-start ${styles.layout_right_container}`}
          >
            <NewButtonsMenu buttons={menuButtons} animate={false} />
          </div>
        </div>
      </div>
    </>
  );
};

export default DesktopDashboardSchedule;
