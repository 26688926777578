import { useEffect } from 'react';
import { motion } from 'framer-motion';
import { clsx } from 'clsx';
import IconRounded from 'src/components/IconRounded';
import Button from 'src/components/Button';
import {
  Draft,
  closeDraft,
  maxDraft,
  minDraft,
  removeDraft,
  saveDraft,
  updateSavedDraft,
} from 'src/app/slices/dockSlice';
import useWindowSize from 'src/hooks/useWindowSize';
import { useAppDispatch } from 'src/app/hooks';
import locales_es from 'src/locales/es.json';
import { Form, Formik, useFormikContext } from 'formik';
import { TextInput } from '../FormInput';
import styles from './NewAppointmentForm.module.scss';

interface Props {
  draft: Draft;
}
interface FormValues {
  fullName: string;
  email: string;
  phone: string;
}

const title = {
  PRESCRIPTION: locales_es.newPrescription,
  APPOINTMENT: locales_es.newTurn,
  PATIENT: locales_es.newPatient,
};

const initialValues = {
  fullName: '',
  email: '',
  phone: '',
};

const FormObserver = ({ draft }: Props) => {
  const { values } = useFormikContext<FormValues>();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (values.fullName) {
      if (!draft.saved) {
        dispatch(saveDraft({ id: draft.data.id }));
      } else {
        const timer = setTimeout(() => {
          dispatch(
            updateSavedDraft({ id: draft.data.id, text: values.fullName })
          );
        }, 500);
        return () => clearTimeout(timer);
      }
    }
  }, [values]);

  return null;
};

export const NewAppointmentForm = ({ draft }: Props) => {
  const dispatch = useAppDispatch();
  const { isMobile, width: WIDTH } = useWindowSize();
  const toggleFormSize = () => {
    if (draft.windowStatus === 'MIN') {
      dispatch(maxDraft({ id: draft.data.id }));
    } else {
      dispatch(minDraft({ id: draft.data.id }));
    }
  };

  return (
    <Formik
      initialValues={{ ...initialValues, fullName: draft.data?.text || '' }}
      onSubmit={(values) => {
        console.log('values', values);
      }}
    >
      {() => (
        <Form noValidate autoComplete='off'>
          {WIDTH ? (
            <motion.div
              className={styles.container}
              style={{
                backgroundColor: draft.data.bg,
                minWidth: isMobile ? 320 : 260,
                maxWidth: isMobile ? WIDTH : WIDTH / 4 - 50,
              }}
              animate={{
                transition: { ease: 'easeIn' },
              }}
            >
              <div
                className={clsx(
                  draft.windowStatus === 'MIN' && styles.header_minimized,
                  draft.windowStatus === 'MAX' && styles.header_maximized
                )}
                style={{
                  borderBottomLeftRadius: draft.windowStatus === 'MIN' ? 24 : 0,
                  borderBottomRightRadius:
                    draft.windowStatus === 'MIN' ? 24 : 0,
                }}
              >
                {draft.windowStatus === 'MIN' && (
                  <div style={{ justifySelf: 'left' }}>
                    <p className={styles.title}>{title[draft.type]}</p>
                  </div>
                )}
                <div className={styles.header_actions}>
                  <IconRounded
                    icon='minimize'
                    size={40}
                    iconColor='#FFFFFF'
                    stroke
                    onClick={toggleFormSize}
                  />
                  <IconRounded
                    icon='crossCircle'
                    size={40}
                    iconColor='#FFFFFF'
                    onClick={() => {
                      if (draft.saved) {
                        dispatch(closeDraft({ id: draft.data.id }));
                      } else {
                        dispatch(removeDraft({ id: draft.data.id }));
                      }
                    }}
                  />
                </div>
              </div>
              <motion.div
                animate={{
                  opacity: draft.windowStatus === 'MIN' ? 0 : 1,
                  height: draft.windowStatus === 'MIN' ? 0 : 'fit-content',
                  display: draft.windowStatus === 'MIN' ? 'none' : 'block',
                }}
                //
              >
                <div className={styles.content_container}>
                  <div className={styles.title_container}>
                    <div>
                      <p className={styles.title}>{title[draft.type]}</p>
                    </div>
                    <div className={styles.title_actions}>
                      <IconRounded
                        icon='email'
                        size={40}
                        iconColor='#FFFFFF'
                        stroke
                      />
                      |
                      <IconRounded
                        icon='printer'
                        size={40}
                        iconColor='#FFFFFF'
                        stroke
                      />
                    </div>
                  </div>
                  <div className={styles.form_container}>
                    <div className={styles.form_title}>
                      <p className={styles.title}>
                        {'Datos del paciente'.toUpperCase()}
                      </p>
                    </div>
                    <TextInput
                      name='fullName'
                      placeholder='Nombre y Apellido'
                      classes='mb-3 w-100'
                      type='text'
                      variant={'textInput_transparent'}
                    />
                    <TextInput
                      name='email'
                      placeholder='Email'
                      classes='mb-3 w-100'
                      type='email'
                      variant={'textInput_transparent'}
                    />
                    <TextInput
                      name='phone'
                      placeholder='Teléfono'
                      classes='mb-3 w-100'
                      type='text'
                      variant={'textInput_transparent'}
                    />
                  </div>

                  <div className={styles.line} />

                  <div className={styles.form_container}>
                    <div className={styles.form_title}>
                      <p className={styles.title}>
                        {'Turno asignado'.toUpperCase()}
                      </p>
                    </div>
                    <div className={styles.shift_container}>
                      <div className={styles.shift_date_container}>
                        <IconRounded
                          icon='calendar'
                          size={40}
                          iconColor='#FFFFFF'
                          stroke
                        />
                        <p className={styles.shift_date_text}>
                          Miércoles, 25 Octubre 2023
                        </p>
                      </div>
                      <div className={styles.shift_date_actions}>
                        <IconRounded
                          icon='clock'
                          size={40}
                          iconColor='#FFFFFF'
                          stroke
                        />
                        <div>
                          <p className={styles.shift_date_text}>12:15 hs.</p>
                        </div>
                      </div>
                    </div>
                    <div className={styles.shift_place_container}>
                      <IconRounded
                        icon='mapMarker'
                        size={40}
                        iconColor='#FFFFFF'
                        stroke
                      />
                      <div>
                        <p className={styles.shift_date_text}>
                          Hospital Italiano San Justo
                        </p>
                        <p className={styles.shift_date_text}>
                          Blanco Encalada 1234, 3ro A
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className={styles.line} />

                  <div className={styles.form_container}>
                    <div className={styles.form_title}>
                      <p className={styles.title}>
                        {'Profesional'.toUpperCase()}
                      </p>
                    </div>
                    <div className={styles.shift_place_container}>
                      <IconRounded
                        icon='medicalCase'
                        size={40}
                        iconColor='#FFFFFF'
                        stroke
                      />
                      <div>
                        <p className={styles.shift_date_text}>
                          Dra. Juana Pérez
                        </p>
                        <p className={styles.shift_date_text}>Cardiología</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.action_container}>
                  <Button variant='outline-white'>Cancelar</Button>
                  <Button variant='outline-white' type='submit'>
                    Guardar
                  </Button>
                </div>
              </motion.div>
            </motion.div>
          ) : null}
          <FormObserver draft={draft} />
        </Form>
      )}
    </Formik>
  );
};
