export const PARSE_TEL_INPUT_ONLY_COUNTRIES = [
  'ar',
  'br',
  'cu',
  'ec',
  'es',
  'it',
  'mx',
  'uy',
  'cl',
  'py',
  'il',
  'co',
  've',
  'pe',
  'is',
];

export const PARSE_TEL_INPUT_COUNTRIES_CODES = [
  {
    countryCode: 'ar',
    dialCode: '+54',
  },
  {
    countryCode: 'br',
    dialCode: '+55',
  },
  {
    countryCode: 'cu',
    dialCode: '+53',
  },
  {
    countryCode: 'ec',
    dialCode: '+593',
  },
  {
    countryCode: 'es',
    dialCode: '+34',
  },
  {
    countryCode: 'it',
    dialCode: '+39',
  },
  {
    countryCode: 'mx',
    dialCode: '+52',
  },
  {
    countryCode: 'uy',
    dialCode: '+598',
  },
  {
    countryCode: 'cl',
    dialCode: '+56',
  },
  {
    countryCode: 'py',
    dialCode: '+592',
  },
  {
    countryCode: 'co',
    dialCode: '+57',
  },
  {
    countryCode: 've',
    dialCode: '+58',
  },
  {
    countryCode: 'pe',
    dialCode: '+51',
  },
];

export const EXPIRED = 'EXPIRED';
export const DEFAULT_TIME_ZONE = 'America/Argentina/Buenos_Aires';
export const PAYMENT_METHOD_BANK_TRANSFER = 'bank_transfer';
export const PAYMENT_METHOD_MERCADO_PAGO = 'mercado_pago';
export const PAYMENT_METHOD_MANUAL = 'manual';
