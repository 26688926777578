import { useAppSelector } from 'src/app/hooks';
import {
  EditMedicProfileForm,
  EditPatientProfileForm,
} from 'src/components/EditProfileForm';
import SectionTitle from 'src/components/SectionTitle';
import locales_es from 'src/locales/es.json';
import { USER_TYPE_MEDIC } from 'src/utils/constants';

const EditProfile = () => {
  const { data } = useAppSelector((state) => state.user);
  return (
    <div>
      <SectionTitle
        sectionText={{
          textTitle: `${locales_es.myProfile} -> ${locales_es.editProfile}`,
          textSubtitle: '',
        }}
        showAvatar={false}
        endLine={false}
      />
      {data?.user_type === USER_TYPE_MEDIC ? (
        <EditMedicProfileForm />
      ) : (
        <EditPatientProfileForm />
      )}
    </div>
  );
};

export default EditProfile;
