import useWindowSize from 'src/hooks/useWindowSize';
import User from '../User';
import styles from './SectionTitle.module.scss';
// OFF
interface TextData {
  textTitle: string;
  textSubtitle: string;
}

interface Props {
  sectionText: TextData;
  userText?: TextData;
  showAvatar?: boolean;
  endLine?: boolean;
}

const SectionTitle = ({
  sectionText,
  userText,
  showAvatar = true,
  endLine = true,
}: Props) => {
  const { isMobile, width } = useWindowSize();
  if (isMobile === undefined || width === undefined) return <></>;
  return (
    <>
      <div className={`${styles.container}`}>
        {!isMobile && (
          <div className={`${styles.content_container}`}>
            <p className={`mb-0 ${styles.day}`}>{sectionText.textTitle}</p>
            <h4 className={`mb-0 ${styles.resume}`}>
              {sectionText.textSubtitle}
            </h4>
          </div>
        )}
        <User
          avatarSize={isMobile ? width / 4 : width / 16}
          showAvatar={showAvatar}
          classes={`${
            isMobile
              ? 'justify-content-center w-100'
              : 'justify-content-end w-100'
          }`}
        >
          <div className={styles.info_container}>
            {isMobile && (
              <div className={`${styles.content_container}`}>
                <p className={`mb-0 ${styles.day}`}>{sectionText.textTitle}</p>
                <h4 className={`mb-0 ${styles.resume}`}>
                  {sectionText.textSubtitle}
                </h4>
              </div>
            )}
            {userText && (
              <div className={`${styles.content_container}`}>
                <p className={`mb-0 ${styles.day} ${styles.text_title}`}>
                  {userText.textTitle}
                </p>
                <p className={`mb-0 ${styles.resume}`}>
                  {userText.textSubtitle}
                </p>
              </div>
            )}
          </div>
        </User>
      </div>
      {endLine && (
        <div className={styles.end_line_container}>
          <div className={styles.end_line} />
        </div>
      )}
    </>
  );
};

export default SectionTitle;
