import { ReactComponent as Menu } from '../../icons/fi-rs-list.svg';
import { ReactComponent as MenuBurger } from '../../icons/fi-rs-menu-burger.svg';
import { ReactComponent as Calendar } from '../../icons/fi-rs-calendar.svg';
import { ReactComponent as Settings } from '../../icons/fi-rs-settings.svg';
import { ReactComponent as ArrowLeft } from '../../icons/fi-rs-arrow-circle-left.svg';
import { ReactComponent as ArrowRight } from '../../icons/fi-rs-arrow-circle-right.svg';
import { ReactComponent as Search } from '../../icons/fi-rs-search.svg';
import { ReactComponent as User } from '../../icons/fi-rs-user.svg';
import { ReactComponent as AngleRight } from '../../icons/fi-ss-angle-right.svg';
import { ReactComponent as AngleLeft } from '../../icons/fi-rs-angle-small-left.svg';
import { ReactComponent as Info } from '../../icons/fi-rs-interrogation.svg';
import { ReactComponent as Wink } from '../../icons/fi-rs-grin-wink.svg';
import { ReactComponent as Envelope } from '../../icons/fi-rs-envelope.svg';
import { ReactComponent as Information } from '../../icons/fi-rs-info.svg';
import { ReactComponent as Check } from '../../icons/fi-rs-check.svg';
import { ReactComponent as Eye } from '../../icons/fi-rs-eye.svg';
import { ReactComponent as Picture } from '../../icons/fi-rs-picture.svg';
// import { ReactComponent as Logo } from '../../icons/logo.svg';
// import { ReactComponent as LogoDesktop } from '../../icons/logo_desktop.svg';
// import { ReactComponent as LogoMobile } from '../../icons/logo_mobile.svg';
// import { ReactComponent as LogoLoader } from '../../icons/logo_loader.svg';
import { ReactComponent as DownArrow } from '../../icons/down_arrow.svg';
import { ReactComponent as Crop } from '../../icons/crop.svg';
import { ReactComponent as Plus } from '../../icons/fi-rs-plus-small.svg';
import { ReactComponent as AddressBook } from '../../icons/fi-rs-address-book.svg';
import { ReactComponent as Document } from '../../icons/fi-rs-document.svg';
import { ReactComponent as MapMarker } from '../../icons/fi-rs-marker.svg';
import { ReactComponent as CrossCircle } from '../../icons/fi-rs-cross-circle.svg';
import { ReactComponent as Stars } from '../../icons/fi-rs-stars.svg';
import { ReactComponent as Happy } from '../../icons/fi-rs-happy.svg';
import { ReactComponent as Bolt } from '../../icons/fi-rs-bolt.svg';
import { ReactComponent as Trash } from '../../icons/fi-rs-trash.svg';
import { ReactComponent as Cross } from '../../icons/fi-rs-cross.svg';
import { ReactComponent as SadFace } from '../../icons/sad_face.svg';
import { ReactComponent as CheckCircle } from '../../icons/check-circle.svg';
import { ReactComponent as Bell } from '../../icons/bell.svg';
import { ReactComponent as AlertTriangle } from '../../icons/alert-triangle.svg';
import { ReactComponent as Logout } from '../../icons/log-out.svg';
import { ReactComponent as Edit } from '../../icons/edit.svg';
import { ReactComponent as Hospital } from '../../icons/hospital.svg';
import { ReactComponent as Drag } from '../../icons/drag_icon.svg';
import { ReactComponent as Phone } from '../../icons/phone.svg';
import { ReactComponent as Email } from '../../icons/email.svg';
import { ReactComponent as Clock } from '../../icons/clock.svg';
import { ReactComponent as Printer } from '../../icons/printer.svg';
import { ReactComponent as Minimize } from '../../icons/minus-circle.svg';
import { ReactComponent as MedicalCase } from '../../icons/medical_case.svg';
import { ReactComponent as LoginBg } from '../../icons/bg_icon.svg';

import { IconProps } from 'src/utils/models';

export const icons: IconProps = {
  menu: <Menu />,
  menuBurger: <MenuBurger />,
  settings: <Settings />,
  calendar: <Calendar />,
  arrowLeft: <ArrowLeft />,
  arrowRight: <ArrowRight />,
  search: <Search />,
  user: <User />,
  angleRight: <AngleRight />,
  angleLeft: <AngleLeft />,
  info: <Info />,
  success: <Wink />,
  information: <Information />,
  envelope: <Envelope />,
  check: <Check />,
  eye: <Eye />,
  picture: <Picture />,
  // logo: <Logo />,
  // logoDesktop: <LogoDesktop />,
  // logoMobile: <LogoMobile />,
  // logoLoader: <LogoLoader />,
  downArrow: <DownArrow />,
  crop: <Crop />,
  plus: <Plus />,
  addressBook: <AddressBook />,
  document: <Document />,
  mapMarker: <MapMarker />,
  crossCircle: <CrossCircle />,
  stars: <Stars />,
  happy: <Happy />,
  bolt: <Bolt />,
  trash: <Trash />,
  cross: <Cross />,
  sadFace: <SadFace />,
  checkCircle: <CheckCircle />,
  bell: <Bell />,
  alertTriangle: <AlertTriangle />,
  logout: <Logout />,
  edit: <Edit />,
  hospital: <Hospital />,
  drag: <Drag />,
  phone: <Phone />,
  email: <Email />,
  clock: <Clock />,
  printer: <Printer />,
  minimize: <Minimize />,
  medicalCase: <MedicalCase />,
  loginBg: <LoginBg />,
};
