import { CSSProperties } from 'react';
import { ErrorMessage, useField } from 'formik';
import PhoneInput from 'react-phone-input-2';
import { PARSE_TEL_INPUT_ONLY_COUNTRIES } from 'src/services/constants';
import { InputValidation } from './InputValidation/InputValidation';
import styles from './CustomPhoneInput.module.scss';
import 'react-phone-input-2/lib/plain.css';

interface Props {
  name: string;
  onChange: (event: any) => void;
  placeholder: string;
  style?: CSSProperties;
  isRequired?: boolean;
  label?: string;
  children?: any;
}

export const CustomPhoneInput = ({
  name,
  onChange,
  placeholder,
  style,
  isRequired,
  label = 'Celular',
  children,
}: Props) => {
  const [field, meta] = useField(name);
  return (
    <div style={style}>
      <label className={styles.label}>{label}</label>
      <div
        className='d-flex align-items-center'
        style={{ position: 'relative' }}
      >
        <PhoneInput
          country='ar'
          preferredCountries={PARSE_TEL_INPUT_ONLY_COUNTRIES}
          inputProps={{ name: field.name }}
          containerClass={styles.container}
          inputClass={styles.input}
          dropdownClass={styles.dropdown}
          buttonClass={styles.button}
          onChange={(value, country, e, formattedValue) => {
            onChange(`+${value}`);
          }}
          value={field.value}
          onBlur={field.onBlur}
          placeholder={placeholder}
          specialLabel=''
          inputStyle={{ width: '100%' }}
          countryCodeEditable={false}
          enableLongNumbers
        ></PhoneInput>
        {isRequired && meta.touched && <InputValidation error={meta.error} />}
      </div>
      {children}

      <div
        className='p2'
        style={{
          minHeight: 12,
          lineHeight: 1.2,
        }}
      >
        <ErrorMessage
          name={name}
          component='p'
          className={styles.errorMessage}
          data-cy='textInput-errorMessage'
        />
      </div>
    </div>
  );
};
