import React from 'react';
import styles from './Card.module.scss';
import { CardProps } from 'src/utils/models';
import { icons } from '../IconButton/IconDictionary';

const Card: React.FC<CardProps> = ({ children, variant, classes = '' }) => {
  return (
    <div className={`${styles.card} ${styles[variant]} text-wrap ${classes}`}>
      {variant === 'info' && (
        <div className={styles.infoCardDecoration}>{icons[variant]}</div>
      )}
      {variant === 'success' && (
        <>
          <div className={styles.icon}>{icons[variant]}</div>

          <div className={`${styles.textSuccess} text-wrap`}>{children}</div>
        </>
      )}
      {variant !== 'success' && children}
    </div>
  );
};

export default Card;
