import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import locales_es from 'src/locales/es.json';

const YEAR = new Date().getFullYear();

interface LoadingState {
  loading: boolean;
  opacity: number;
  text: string;
  animate?: boolean;
}

const initialState: LoadingState = {
  loading: true,
  opacity: 1,
  text: `${locales_es.allRightsReserved}${YEAR}`,
  animate: true,
};

interface StartLoadingAction {
  opacity: number;
  text: string;
  animate?: boolean;
}

export const loadingSlice = createSlice({
  name: 'loading',
  initialState,
  reducers: {
    startLoading: (state, action: PayloadAction<StartLoadingAction>) => {
      state.loading = true;
      state.text = action.payload.text;
      state.opacity = action.payload.opacity;
      state.animate = action.payload.animate || false;
    },
    endLoading: (state) => {
      state.loading = false;
      state.text = initialState.text;
      state.opacity = initialState.opacity;
      state.animate = false;
    },
  },
});

export const { startLoading, endLoading } = loadingSlice.actions;
