import { useState } from 'react';
import Cropper from 'react-easy-crop';
import getCroppedImg from 'src/utils/cropImage';
import Button from '../Button';
import IconButton from '../IconButton';
import styles from './ImageCropper.module.scss';

interface Props {
  imageURL: string;
  closeImageCropper: () => void;
  editImage: (file: any, url: any) => void;
}

const ImageCropper = ({ imageURL, closeImageCropper, editImage }: Props) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<any>(null);

  const cropComplete = (croppedArea: any, croppedAreaPixels: any) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const cropImage = async () => {
    try {
      const { myFile, url }: any = await getCroppedImg(
        imageURL,
        croppedAreaPixels,
        rotation
      );
      editImage(myFile, url);
      closeImageCropper();
    } catch (error) {}
  };

  return (
    <div className='d-flex flex-column align-items-center justify-content-center'>
      <div
        style={{
          height: 200,
          width: 'auto',
          minWidth: 350,
          position: 'relative',
          background: '#333',
        }}
      >
        <Cropper
          image={imageURL || require('../../assets/no_image.png')}
          crop={crop}
          zoom={zoom}
          minZoom={1}
          maxZoom={3}
          rotation={rotation}
          aspect={1}
          onCropChange={setCrop}
          onZoomChange={setZoom}
          onRotationChange={setRotation}
          onCropComplete={cropComplete}
          objectFit='contain'
        />
      </div>
      <div className='d-flex flex-column align-items-center justify-content-center'>
        <div>
          <p className='mb-0 mt-3'>Zoom: {Math.round(zoom * 100)}%</p>
          <input
            type='range'
            name='zoom'
            min={1}
            max={3}
            value={zoom}
            step='0.01'
            onChange={(e) => setZoom(Number(e.target.value))}
            className={`${styles.input_range}`}
          />
        </div>
        <div>
          <p className='mb-0 mt-3'>Rotation: {rotation}</p>
          <input
            type='range'
            name='rotation'
            min={0}
            max={360}
            value={rotation}
            onChange={(e) => setRotation(Number(e.target.value))}
            className={`${styles.input_range}`}
          />
        </div>
      </div>
      <div className='w-75 d-flex align-items-center justify-content-between'>
        <IconButton
          variant='primary'
          handleClick={cropImage}
          icon={'crop'}
          buttonSize={60}
          iconSize={30}
        />
        <Button variant='outline' handleClick={closeImageCropper}>
          Cancelar
        </Button>
      </div>
    </div>
  );
};

export default ImageCropper;
