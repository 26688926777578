import * as Yup from 'yup';
import locales_es from 'src/locales/es.json';
import { FormActionButton, SwitchInput, TextInput } from 'src/utils/models';

export const REQUIRED = 'Campo requerido';
export const INVALID_EMAIL = 'Correo electrónico inválido';
export const EMAILS_MUST_MATCH = 'Los correos deben coincidir';
export const NUMBER = 'El campo debe ser un número';

const email_input: TextInput = {
  type: 'text',
  name: 'email',
  label: 'Email',
  variant: '',
  validation: Yup.string().email(INVALID_EMAIL).required(REQUIRED),
  wrapperClassName: '',
  dataCy: 'loginForm_email_textField',
  initialValue: '',
};

const password_input: TextInput = {
  type: 'password',
  name: 'password',
  label: 'Contraseña',
  variant: 'primary',
  validation: Yup.string().trim().required(REQUIRED),
  wrapperClassName: '',
  dataCy: 'loginForm_password_textField',
  initialValue: '',
};

const rememberMe_input: SwitchInput = {
  type: 'switch',
  name: 'rememberMe',
  label: 'Recuérdame',
  title: false,
  validation: Yup.boolean(),
  dataCy: 'LoginForm_submit_button',
  wrapperClassName: 'd-flex align-items-center justify-content-center',
};

const submit_action: FormActionButton = {
  variant: 'primary',
  type: 'submit',
  classes: 'mx-0 mw-50 fw-light',
  style: { letterSpacing: '2px' },
  text: locales_es.enter,
  dataCy: 'LoginForm_submit_button',
};

const loginForm = {
  inputs: [email_input, password_input, rememberMe_input],
  actions: [submit_action],
  actionWrapperClassName:
    'container d-flex align-items-center justify-content-center',
};

export default loginForm;
