import { useState } from 'react';
import { useAppSelector } from 'src/app/hooks';
import ModalAnimationResult from 'src/components/ModalAnimationResult';
import { STATUS_FAILED, STATUS_SUCCESS } from 'src/pages/MyAppointments/consts';
import MyAppointments from 'src/pages/MyAppointments';
import ScheduleScreen from 'src/pages/Schedule';
import { USER_TYPE_PATIENT } from 'src/utils/constants';

const DashboardContainer = () => {
  const user = useAppSelector((state) => state.user?.data);
  const urlParams = new URLSearchParams(window.location.search);
  const [result, setResult] = useState(urlParams.get('result'));
  return (
    <>
      {user?.user_type === USER_TYPE_PATIENT ? (
        <MyAppointments />
      ) : (
        <ScheduleScreen />
      )}
      {result && (result === STATUS_SUCCESS || result === STATUS_FAILED) ? (
        <ModalAnimationResult
          acceptAction={() => setResult(null)}
          result={result}
        />
      ) : null}
    </>
  );
};

export default DashboardContainer;
