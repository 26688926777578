import clsx from 'clsx';
import { openModalType } from 'src/contexts/ModalContext';
import {
  cancelAppointment,
  getTimetables,
  getClinicById,
} from 'src/services/apiService';
import locales_es from 'src/locales/es.json';

export const APPOINTMENT_PRESENTIAL_TYPE = 1;
export const APPOINTMENT_VIRTUAL_TYPE = 2;
export const APPOINTMENT_MIXED_TYPE = 3;

export const HREF_PAGE_VIDEOCALL = '/videocall';
export const HREF_PAGE_ONLINE = '/online';

export const PAYMENT_STATUS_PENDING = 'pending';
export const PAYMENT_METHOD_AMOUNT_TYPE_PERCENT = 'percent';
export const PAYMENT_METHOD_AMOUNT_TYPE_FIXED = 'fixed';
export const MERCADO_PAGO_BASIC = 'mercadopago_basic';
export const STRIPE = 'stripe';
export const STATUS_SUCCESS = 'success';
export const STATUS_FAILED = 'failed';

export function handleCancelAppointmentClick(
  appointment: any,
  openModal: openModalType,
  refetch: () => void
) {
  cancelAppointment(appointment.id)
    .then((res: any) => {
      openModal({
        title: locales_es.success,
        subTitle: res.message ?? locales_es.myAppointments.cancel.success,
      });
      refetch();
    })
    .catch(() => {
      openModal({
        title: locales_es.upss,
        subTitle: locales_es.experimentingDificulties,
      });
      refetch();
    });
}

export function showCancelModal(
  appointment: any,
  openModal: openModalType,
  refetch: () => void,
  styles: any
) {
  openModal({
    title: locales_es.myAppointments.cancel.title,
    subTitle: `${locales_es.myAppointments.cancel.subtitle}. ${locales_es.myAppointments.cancel.advice}`,
    actions: [
      {
        handleClick: () =>
          handleCancelAppointmentClick(appointment, openModal, refetch),
        classes: clsx(styles.actions, 'm-auto'),
        variant: 'primary',
        text: locales_es.myAppointments.actions.cancel,
      },
    ],
  });
}

export function showAppointmentInfo(appointment: any, openModal: any) {
  if (appointment && appointment.timetable_id) {
    const objData = {
      medic_id: appointment.medic_id,
    };
    getTimetables(objData)
      .then((res) => {
        const regex = /\n/gm;
        if (res && res.data) {
          const info = res.data.data.filter(
            (d: any) => Number(d.id) === Number(appointment.timetable_id)
          )[0];

          const message = info?.footer_email_text
            ? info.footer_email_text.replace(regex, '<br>')
            : locales_es.noAdditionalInfoToShow;
          openModal({
            title: locales_es.myAppointments.actions.info,
            subTitle: message,
          });
        }
      })
      .catch((err) => {
        openModal({ message: locales_es.experimentingDificulties });
      });
  }
}

export function getClinicAddress(clinicId: string, openModal: openModalType) {
  getClinicById(clinicId).then((res) => {
    if (res && res.data && res.data.data.address) {
      window.open(`https://google.com/maps/place/${res.data.data.address}`);
    } else {
      openModal({
        title: locales_es.seeLocation,
        subTitle: locales_es.noAddress,
      });
    }
  });
}
