import { DateNavigator } from '@devexpress/dx-react-scheduler-material-ui';
import styles from './CustomComponents.module.scss';

export const TimeNavigator = (props: any) => {
  return (
    <DateNavigator.Root {...props} className={`${styles.date_nav_root}`}>
      {props.children}
    </DateNavigator.Root>
  );
};
