import { motion } from 'framer-motion';
import DashboardClinicsComponent from 'src/components/DashboardClinics';
import styles from './DashboardClinicsScreen.module.scss';
import SectionTitle from 'src/components/SectionTitle';

const DashboardClinics = () => {
  return (
    <motion.div
      animate={{ opacity: 1, transition: { duration: 1 } }}
      initial={{ opacity: 0 }}
      className={styles.dashboardClinicsScreen_container}
    >
      <SectionTitle
        sectionText={{
          textTitle: 'Mis clínicas',
          textSubtitle: '',
        }}
        userText={{
          textSubtitle: '',
          textTitle: '',
        }}
        showAvatar={false}
      />
      <DashboardClinicsComponent />
    </motion.div>
  );
};

export default DashboardClinics;
