import { useState } from 'react';
import { Blurhash } from 'react-blurhash';
import { motion } from 'framer-motion';
import styles from './ImageBlurhash.module.scss';

interface Props {
  hashUrl: string;
  imageUrl: string;
  height?: number | string;
  width?: number | string;
  delay?: number;
  duration?: number;
}
const ImageBlurhash = ({
  hashUrl,
  imageUrl,
  height = 200,
  width = 300,
  delay = 0.3,
  duration = 0.2,
}: Props) => {
  const [imageLoading, setImageLoading] = useState(true);

  const onLoad = () => {
    setImageLoading(false);
  };
  return (
    <div className={styles.blurhash_wrapper} style={{ height, width }}>
      <Blurhash
        hash={hashUrl}
        width='100%'
        height='100%'
        resolutionX={32}
        resolutionY={32}
      />
      <motion.img
        initial={{ opacity: 0 }}
        animate={{
          opacity: imageLoading ? 0 : 1,
        }}
        transition={{ opacity: { delay, duration } }}
        loading='lazy'
        onLoad={onLoad}
        className={styles.image}
        src={imageUrl}
        width='100%'
        height='100%'
      />
    </div>
  );
};

export default ImageBlurhash;
