import axios from 'axios';

import {
  apiURL_login,
  apiURL_register,
  apiURL_users,
  apiURL_user_me,
  apiURL_password_request,
  apiURL_user_password,
  apiURL_medics,
  apiURL_config,
  apiURL_identification_types,
  apiURL_genders,
  apiURL_consulting_rooms,
  apiURL_timetables,
  apiURL_appointments,
  apiURL_appointments_next,
  apiURL_appointments_nexts,
  apiURL_appointments_me,
  apiURL_patients_me,
  apiURL_nonworking_days,
  apiURL_nonworking_days_collisions,
  apiURL_profile_image,
  apiURL_clinics,
  apiURL_posts,
  apiURL_stats_totals,
  apiURL_posts_merged,
  apiURL_specialties,
  apiURL_medics_unassign,
  apiURL_patients,
  apiURL_appointments_block,
  apiURL_timezones,
  apiURL_appointments_types,
  apiURL_medics_me,
  apiURL_medic_patient,
  apiURL_messages,
  apiURL_appointments_videocall_token,
  apiURL_medic_patient_hash,
  apiURL_medics_appointment_types,
  apiURL_messages_image,
  apiURL_medics_interrupted_agenda,
  apiURL_user_cellphone,
  apiURL_cellphone_request_validation,
  apiURL_cellphone_validation,
  apiURL_appointments_status,
  apiURL_payment_methods,
  apiURL_payment_config,
  apiURL_mercadopago_info,
  apiURL_mercadopago_auth_link,
  apiURL_payment_amount_types,
  apiURL_payment_methods_user,
  apiURL_payment_method_bank_transfer,
  apiURL_payments_status,
  apiURL_appointment_payments_status,
  apiURL_clinics_domain,
  apiURL_clinics_user,
  apiURL_clinics_me,
  apiURL_medics_slugname,
  apiURL_timetables_prices,
  apiURL_medical_info,
  apiURL_medical_record_types,
  apiURL_medical_records,
  apiURL,
  apiURL_patients_search,
} from './api-urls';
import { getAuthToken } from 'src/utils/auth';

export const apiClient = axios.create({
  baseURL: apiURL + '/v1',
});

apiClient.interceptors.request.use(
  (config) => {
    const token = getAuthToken();
    if (token) {
      config.headers.authorizationjwt = 'Bearer ' + token;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// eslint-disable-next-line
// import unregister from './apiService.config'; // No estamos usando el register(), pero importarlo hace que funcione el interceptor

// import AuthService from './authService';
// import axios from 'axios';

const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

// export const callAuthServiceLogout = () => {
//   const auth = new AuthService();
//   auth.logout(true);
// };

export const parseError = (err) => {
  let msg = '';

  msg = err && err.data && err.data.message ? err.data.message : err.message;

  if (err && err.errors && err.errors.length) {
    err.errors.map((e) => {
      if (e !== msg) {
        msg += '. ' + e;
      }
    });
  }

  return msg;
};

export const setParams = (urlStr, params) => {
  const url = new URL(urlStr);
  // params = {lat:35.696233, long:139.570431};
  if (params) {
    Object.keys(params).forEach((key) =>
      url.searchParams.append(key, params[key])
    );
  }
  return url;
};

export const getConfig = () => apiClient.get(apiURL_config);

export const getCellphoneValidation = () =>
  apiClient.get(apiURL_cellphone_request_validation);

export const postCellphoneValidation = (data) =>
  apiClient.post(apiURL_cellphone_validation, data);

export const getPaymentMethods = (userId) => {
  const url = apiURL_payment_methods + (userId ? '/user/' + userId : '');
  return apiClient.get(url);
};

export const getPaymentsAmountTypes = () =>
  apiClient.get(apiURL_payment_amount_types);

export const putPaymentConfig = (data) =>
  apiClient.put(apiURL_payment_config, data);

export const putPaymentMethods = (data) =>
  apiClient.put(apiURL_payment_methods, data);

export const getPaymentMethodsByUser = (userId) =>
  apiClient.get(apiURL_payment_methods_user + '/' + userId);

export const getBankTransfer = (userId) => {
  const url =
    apiURL_payment_method_bank_transfer + (userId ? '/' + userId : '');
  return apiClient.get(url);
};

export const putBankTransferData = (data) =>
  apiClient.put(apiURL_payment_method_bank_transfer, data);

export const getMercadoPagoInfo = () => apiClient.get(apiURL_mercadopago_info);

export const getMercadoPagoAuthorizationLink = (domain) =>
  apiClient.get(apiURL_mercadopago_auth_link + '?domain=' + domain);

export const deleteMercadoPagoAuthorization = () =>
  apiClient.delete(apiURL_mercadopago_info);

export const getClinics = () => apiClient.get(apiURL_clinics);

export const getMyClinics = () => apiClient.get(apiURL_clinics_me);

export const getClinicsByUser = (id) =>
  apiClient.get(apiURL_clinics_user + '/' + id);

export const getClinic = (domain) =>
  apiClient.get(apiURL_clinics_domain + '?domain=' + domain);

export const getClinicById = (id) => apiClient.get(apiURL_clinics + '/' + id);

export const postClinic = (data) => apiClient.post(apiURL_clinics, data);

export const putClinic = (id, data) =>
  apiClient.put(apiURL_clinics + '/' + id, data);

export const deleteClinic = (id) => {
  const url = new URL(apiURL_clinics);
  return apiClient.delete(url + '/' + id);
};

export const getTotals = (params) => {
  let url = new URL(apiURL_stats_totals);
  if (params) {
    url = setParams(url, params);
  }
  return apiClient.get(url);
};

export const getMergedAnnouncements = (params) => {
  let url = new URL(apiURL_posts_merged);
  if (params) {
    url = setParams(url, params);
  }
  return apiClient.get(url);
};

export const postAnnouncement = (data) => apiClient.post(apiURL_posts, data);

export const deleteAnnouncement = (id) => {
  const url = new URL(apiURL_posts);
  return apiClient.delete(url + '/' + id);
};

export const login = ({ email, password, clinicId }) =>
  apiClient.post(apiURL_login, {
    email,
    password,
    clinic_id: clinicId,
  });

export const register = (data) => apiClient.post(apiURL_register, data);

export const updateCellphone = (data) =>
  apiClient.put(apiURL_user_cellphone, data);

export const updateUser = (data) => apiClient.put(apiURL_user_me, data);

export const passwordRequest = (email) =>
  apiClient.post(apiURL_password_request, {
    email,
  });

export const updatePassword = (
  old_password,
  new_password,
  new_password_confirmation
) =>
  apiClient.put(apiURL_user_password, {
    old_password,
    new_password,
    new_password_confirmation,
  });

export const getUserMe = () => apiClient.get(apiURL_user_me);

export const getUserById = (id) => apiClient.get(apiURL_users + '/' + id);

export const getNonWorkingDays = (params) => {
  let url = new URL(apiURL_nonworking_days);
  if (params) {
    url = setParams(url, params);
  }
  return apiClient.get(url);
};

export const postNonWorkingDays = (params) => {
  let url = new URL(apiURL_nonworking_days);
  if (params) {
    url = this.setParams(url, params);
  }
  return apiClient.post(url);
};

export const deleteNonWorkingDays = (id) => {
  const url = new URL(apiURL_nonworking_days);
  return apiClient.delete(url + '/' + id);
};

export const getNonWorkingDaysCollisions = (params) => {
  let url = new URL(apiURL_nonworking_days_collisions);
  if (params) {
    url = this.setParams(url, params);
  }
  return apiClient.get(url);
};

export const getMedics = (params) => {
  let url = new URL(apiURL_medics);
  if (params) {
    url = this.setParams(url, params);
  }
  return apiClient.get(url);
};

export const getMyMedics = () => apiClient.get(apiURL_medics_me);

export const getMedicPatientBounding = (params) => {
  let url = new URL(apiURL_medic_patient);
  if (params) {
    url = this.setParams(url, params);
  }
  return apiClient.get(url);
};

export const getMedicPatientBoundingByHash = (hash) =>
  apiClient.get(apiURL_medic_patient_hash + '/' + hash);

export const getMedicById = (medicId) =>
  apiClient.get(apiURL_medics + '/' + medicId);

export const getMedicBySlugname = (slugname) =>
  apiClient.get(apiURL_medics_slugname + '/' + slugname);

export const getVideocallData = (hash) =>
  apiURL_appointments_videocall_token + '/' + hash;

export const getMessages = (medicPatientId) =>
  apiClient.get(apiURL_messages + '/' + medicPatientId);

export const postMessage = (msg, medic_patient_id, clinic_id) =>
  apiClient.post(apiURL_messages, {
    msg,
    medic_patient_id,
    clinic_id,
  });

export const postMedic = (data) => apiClient.post(apiURL_medics, data);

export const putInterruptedAgenda = (userId, data) =>
  apiClient.put(apiURL_medics_interrupted_agenda + '/' + userId, data);

export const postPatient = (data) => apiClient.post(apiURL_patients, data);

export const getMedicalRecordTypes = () =>
  apiClient.get(apiURL_medical_record_types);

export const getMedicalRecordsByPatient = (id, page) => {
  const url = apiURL_medical_records + `/patient/${id}?page=${page}`;
  return apiClient.get(url);
};

export const postMedicalRecord = (data) =>
  apiClient.post(apiURL_medical_records, data);

export const deleteMedicalRecord = (id) =>
  apiClient.delete(apiURL_medical_records + '/' + id);

export const getMedicalInfo = (id) =>
  apiClient.get(apiURL_medical_info + '/' + id);

export const putMedicalInfo = (data) =>
  apiClient.put(apiURL_medical_info, data);

export const getIdentificationTypes = () =>
  apiClient.get(apiURL_identification_types);

export const getGenders = () => apiClient.get(apiURL_genders);

export const getTimezones = () => apiClient.get(apiURL_timezones);

export const getSpecialties = () => apiClient.get(apiURL_specialties);

export const getConsultingRooms = (params) => {
  let url = new URL(apiURL_consulting_rooms);
  if (params) {
    url = this.setParams(url, params);
  }
  return apiClient.get(url);
};

export const getAppointmentsTypes = (params) => {
  let url = new URL(apiURL_appointments_types);
  if (params) {
    url = this.setParams(url, params);
  }
  return apiClient.get(url);
};

export const getMedicAppointmentsTypes = (medicId) =>
  apiClient.get(apiURL_medics_appointment_types + '/' + medicId);

export const getTimetables = (params) => {
  return apiClient.get(apiURL_timetables, {
    method: 'GET',
    headers,
    params,
  });
};

export const postTimetables = (data) => apiClient.post(apiURL_timetables, data);

export const putTimetable = (data) => apiClient.put(apiURL_timetables, data);

export const deleteTimetables = (id) =>
  apiClient.delete(apiURL_timetables + '/' + id);

export const getTimetablePrices = (id) =>
  apiClient.get(apiURL_timetables + '/' + id + '/prices');

export const postTimetablePrice = (data) =>
  apiClient.post(apiURL_timetables_prices, data);
export const putTimetablePrice = (id, data) =>
  apiClient.put(apiURL_timetables_prices + '/' + id, data);

export const deleteTimetablePrice = (id) =>
  apiClient.delete(apiURL_timetables_prices + '/' + id);

export const unassignMedic = (params) => {
  let url = new URL(apiURL_medics_unassign);
  if (params) {
    url = this.setParams(url, params);
  }
  return apiClient.delete(url);
};

export const getNextAppointment = (params, isPublic) => {
  let url = new URL(apiURL_appointments_next);
  url += isPublic ? '/public' : '/private';
  if (params) {
    url = this.setParams(url, params);
  }
  return apiClient.get(url);
};

export const getNextAppointments = (params, isPublic) => {
  let url = new URL(apiURL_appointments_nexts);
  url += isPublic ? '/public' : '/private';
  if (params) {
    url = this.setParams(url, params);
  }
  return apiClient.get(url);
};

export const getPaymentsStatus = () => apiClient.get(apiURL_payments_status);

export const putPaymentsStatus = (id, data) =>
  apiClient.put(apiURL_appointment_payments_status + '/' + id, data);

export const getAppointmentsStatus = () =>
  apiClient.get(apiURL_appointments_status);

export const getAppointments = (params, isPublic) => {
  let url = new URL(apiURL_appointments);
  url += isPublic ? '/public' : '/private';
  if (params) {
    url = this.setParams(url, params);
  }
  return apiClient.get(url);
};

export const getAppointment = (id) =>
  apiClient.get(apiURL_appointments + '/' + id);

export const getMyAppointments = (params) => {
  return apiClient.get(apiURL_appointments_me, { params });
};

export const getPatient = (userId) =>
  apiClient.get(`${apiURL_patients}/id/${userId}`);

export const getMyPatients = (currentPage, per_page) =>
  apiClient.get(
    `${apiURL_patients_me}?page=${currentPage}&per_page=${per_page}`
  );

export const searchMyPatients = (query, page, per_page) =>
  apiClient.get(
    `${apiURL_patients_search}?q=${query}&page=${page}&per_page=${per_page}`
  );

export const postAppointment = (data) =>
  apiClient.post(apiURL_appointments, data);

export const putAppointment = (id, data) =>
  apiClient.put(apiURL_appointments + '/' + id, data);

export const putAppointmentStatus = (id, data) =>
  apiClient.put(apiURL_appointments_status + '/' + id, data);

export const postBlockAppointment = (data) =>
  apiClient.post(apiURL_appointments_block, data);

export const cancelAppointment = (id) =>
  apiClient.delete(apiURL_appointments + '/' + id);

// export const uploadProfileImage = async (file) => {
//   const auth = new AuthService();

//   const headers = {
//     AuthorizationJWT: `Bearer ${auth.getAccessToken()}`,
//   };

//   // Create an object of formData
//   const formData = new FormData();

//   // Update the formData object
//   formData.append('profile_image', file, file.name);

//   // Details of the uploaded file
//   console.log(file);

//   // Request made to the backend api
//   // Send formData object
//   return axios.post(apiURL_profile_image, formData, {
//     headers: headers,
//   });
// };

// export const sendImageMessage = async (file, medicPatientId) => {
//   const auth = new AuthService();

//   const headers = {
//     AuthorizationJWT: `Bearer ${auth.getAccessToken()}`,
//   };

//   // Create an object of formData
//   const formData = new FormData();

//   // Update the formData object
//   formData.append('image', file, file.name);

//   // Update the formData object
//   formData.append('medic_patient_id', medicPatientId);

//   // Details of the uploaded file
//   console.log(file);

//   // Request made to the backend api
//   // Send formData object
//   return axios.post(apiURL_messages_image, formData, {
//     headers: headers,
//   });
// };

//unregister();
