import React from 'react';
import Avatar from 'src/components/Avatar';

interface Props {
  children: JSX.Element | JSX.Element[];
  avatarSize?: number;
  showAvatar?: boolean;
  classes?: string;
  onAvatarClick?: () => void;
}

const User = ({
  children,
  avatarSize = 40,
  showAvatar = true,
  classes = '',
  onAvatarClick,
}: Props) => {
  return (
    <div className={`d-flex align-items-center ${classes}`}>
      {showAvatar && (
        <Avatar
          imageUrl='/images/avatar.svg'
          size={avatarSize}
          variant='grey'
          onAvatarClick={onAvatarClick}
        />
      )}

      <div className='d-flex align-items-center justify-content-center'>
        {children}
      </div>
    </div>
  );
};

export default User;
