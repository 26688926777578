import { FC } from 'react';
import { icons } from 'src/components/IconButton/IconDictionary';
import styles from '../FormInput.module.scss';
import { clsx } from 'clsx';

interface Props {
  error: string | undefined;
}

export const InputValidation: FC<Props> = ({ error }) => {
  return (
    <div
      className={`${styles.icon_container}`}
      style={{ position: 'absolute' }}
    >
      <i
        className={clsx(
          styles.iconError,
          !error ? styles.svg_check : styles.svg_cross
        )}
        data-cy='input_validation_icon'
      >
        {!error ? icons.check : icons.cross}
      </i>
    </div>
  );
};
