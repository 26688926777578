import { useState } from 'react';
import { AvatarProps } from 'src/utils/models';
import { icons } from '../IconButton/IconDictionary';
import styles from './Avatar.module.scss';
import { clsx } from 'clsx';

const Avatar = ({
  size = 40,
  imageUrl,
  variant = 'primary',
  onAvatarClick,
}: AvatarProps) => {
  const [loading, setLoading] = useState(true);
  const [imageOk, setImageOk] = useState(false);

  const handleImageError = () => {
    setImageOk(false);
    setLoading(false);
  };
  const handleImageLoad = (e: any) => {
    setImageOk(true);
    setLoading(false);
  };
  return (
    <div
      className={clsx(
        styles.avatar,
        styles[variant],
        loading && styles.loading
      )}
      style={{
        width: size,
        height: size,
        cursor: onAvatarClick ? 'pointer' : '',
      }}
      onClick={onAvatarClick}
    >
      <img
        src={imageUrl}
        alt='Avatar'
        className={`${imageOk ? styles.image : styles.no_image}`}
        onLoad={handleImageLoad}
        onError={handleImageError}
      />

      <i
        className={`${!imageOk && !loading ? styles.image : styles.no_image}`}
        style={{ width: size * 0.6, height: size * 0.6 }}
      >
        {icons.user}
      </i>
    </div>
  );
};

export default Avatar;
