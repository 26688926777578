import { useQuery, useInfiniteQuery } from '@tanstack/react-query';
import {
  getBankTransfer,
  getGenders,
  getIdentificationTypes,
  getMyPatients,
  getMyAppointments,
  getMyClinics,
  getPaymentMethods,
  getPaymentMethodsByUser,
  getPaymentsAmountTypes,
  getSpecialties,
  getUserMe,
  searchMyPatients,
  getPatient,
  getMedicalInfo,
  getMedicalRecordsByPatient,
  getMyMedics,
} from 'src/services/apiService';
import { getRemoteParsedTimezones } from 'src/services/timezoneService';
import { DEFAULT_TIME_ZONE } from 'src/services/constants';
import { userTypesType } from 'src/components/RegisterNewForm';
import { queries } from './constants';
import { USER_TYPE_MEDIC } from 'src/utils/constants';

interface PrevId {
  prevId?: number | null;
}
interface PrevGender {
  prevGender?: number | null;
}
interface PrevTimezone {
  prevTimezone?: string | null;
}

interface PrevSpecialties {
  userType: userTypesType;
  prevSpecialty: number | undefined;
}

interface UserMeData {
  enabled?: boolean;
}

interface Patients {
  currentPage: number;
  per_page: number;
}

interface Patient {
  id: string;
}

interface SearchPatients {
  query: string;
  page: number;
  per_page: number;
  enabled: boolean;
}

interface AppointmentsParams {
  start?: string;
  end?: string;
}

const STALE_TIME = 300000;

// Identification Types
export const identificationTypesQuery = ({ prevId }: PrevId) => ({
  queryKey: [queries.identificationTypes],
  queryFn: async () => await getIdentificationTypes(),
  onSuccess: (res: any) => {
    if (prevId) {
      const selectedId = res.data.data.find((id: any) => id.id === prevId);
      const fileteredIds = res.data.data.filter((id: any) => id.id !== prevId);
      return [selectedId, ...fileteredIds];
    }
    return res;
  },
  staleTime: STALE_TIME,
});

// Genders
export const gendersQuery = ({ prevGender }: PrevGender) => ({
  queryKey: [queries.genders],
  queryFn: async () => await getGenders(),
  onSuccess: (res: any) => {
    if (prevGender) {
      const selectedGender = res.data.data.find(
        (id: any) => id.id === prevGender
      );
      const fileteredGenders = res.data.data.filter(
        (id: any) => id.id !== prevGender
      );
      return [selectedGender, ...fileteredGenders];
    }
    return res;
  },
  staleTime: STALE_TIME,
});

export interface Timezone {
  id: string;
  name: string;
}

// Timezones
export const timeZoneQuery = ({ prevTimezone }: PrevTimezone) => ({
  queryKey: [queries.timezone],
  queryFn: getRemoteParsedTimezones,
  select: (data: Timezone[]) => {
    const defaultTimezone = data.find(
      (country) => country.id === DEFAULT_TIME_ZONE
    );
    const filteredTimezones = data.filter(
      (country) => country.id !== defaultTimezone?.id
    );
    return [defaultTimezone, ...filteredTimezones];
  },
  onSuccess: (res: any) => {
    if (prevTimezone) {
      const selectedTimeZone = res.find((tz: any) => tz.id === prevTimezone);
      const fileteredTimeZone = res.filter((tz: any) => tz.id !== prevTimezone);
      return [selectedTimeZone, ...fileteredTimeZone];
    }

    return res;
  },
  staleTime: STALE_TIME,
});

// Specialties
export const specialtiesQuery = ({
  userType,
  prevSpecialty,
}: PrevSpecialties) => ({
  queryKey: [queries.specialties],
  queryFn: async () => {
    if (userType === USER_TYPE_MEDIC) return await getSpecialties();
    return null;
  },
  onSuccess: (res: any) => {
    if (prevSpecialty) {
      const selectedSpecialty = res.data.data.find(
        (sp: any) => sp.id === prevSpecialty
      );
      const fileteredSpecialties = res.data.data.filter(
        (sp: any) => sp.id !== prevSpecialty
      );
      return [selectedSpecialty, ...fileteredSpecialties];
    }
    return res;
  },
  staleTime: STALE_TIME,
});

// user payments methods
const userPaymentMethodsQuery = (userId: number) => ({
  queryKey: [queries.userPaymentMethods],
  queryFn: async () => await getPaymentMethodsByUser(userId),
});

// payment methods
const paymentMethodsQuery = () => ({
  queryKey: [queries.paymentMethods],
  queryFn: async () => await getPaymentMethods(),
});

// bank transfer data
const bankTransferQuery = () => ({
  queryKey: [queries.bankTransferData],
  queryFn: async () => await getBankTransfer(),
});

// payments amount types (percentage/fixed)
const amountTypesQuery = () => ({
  queryKey: [queries.amountTypes],
  queryFn: async () => await getPaymentsAmountTypes(),
});

//user data
const userMeDataQuery = ({ enabled = true }) => ({
  queryKey: [queries.userMeData],
  queryFn: async () => await getUserMe(),
  enabled,
});

//patients
const patientsQuery = ({ currentPage, per_page }: Patients) => ({
  queryKey: [queries.patients, currentPage, per_page],
  queryFn: async () => {
    return await getMyPatients(currentPage, per_page);
  },
});

//patientData
const patientQuery = ({ id }: Patient) => ({
  queryKey: [queries.patient, id],
  queryFn: async () => {
    return await getPatient(id);
  },
});

//patients infinite query
const patientsInfiniteQuery = ({ currentPage, per_page }: Patients) => ({
  queryKey: [queries.patientsInfinite],
  queryFn: async () => {
    return await getMyPatients(currentPage, per_page);
  },
  getNextPageParam: (lastPage: any) => {
    console.log('LAST PAGE', console.log(lastPage));
    return lastPage.page + 1;
  },
});

//Medical Info
const medicalInfoQuery = ({ id }: Patient) => ({
  queryKey: [queries.medicalInfo, id],
  queryFn: async () => {
    return await getMedicalInfo(id);
  },
});

//Medical Record
const medicalRecordQuery = ({ id }: Patient) => ({
  queryKey: [queries.medicalRecord, id],
  queryFn: async () => {
    return await getMedicalRecordsByPatient(id);
  },
});

//My Clinics
const myClinicsQuery = () => ({
  queryKey: [queries.myClinics],
  queryFn: async () => await getMyClinics(),
  staleTime: STALE_TIME,
});

const myMedicsQuery = () => ({
  queryKey: [queries.myMedics],
  queryFn: async () => {
    return await getMyMedics();
  },
});

// Serach Patients Query
const searchPatientsQuery = ({
  query,
  page,
  per_page,
  enabled,
}: SearchPatients) => ({
  queryKey: [queries.searchPatients, query, page, per_page],
  queryFn: async () => await searchMyPatients(query, page, per_page),
  enabled,
});
//My Appointments
const myAppointmentsQuery = (params: AppointmentsParams) => ({
  queryKey: [queries.myAppointments],
  queryFn: async () => await getMyAppointments(params),
  staleTime: STALE_TIME,
});

const myFutureAppointmentsQuery = (params: AppointmentsParams) => ({
  queryKey: [queries.myFutureAppointments],
  queryFn: async () => await getMyAppointments(params),
  staleTime: STALE_TIME,
});

const myPastAppointmentsQuery = (params: AppointmentsParams) => ({
  queryKey: [queries.myPastAppointments],
  queryFn: async () => await getMyAppointments(params),
  staleTime: STALE_TIME,
});

export const useIdentificationTypes = ({ prevId }: PrevId) =>
  useQuery(identificationTypesQuery({ prevId }));
export const useGenders = ({ prevGender }: PrevGender) =>
  useQuery(gendersQuery({ prevGender }));
export const useTimeZone = ({ prevTimezone }: PrevTimezone) =>
  useQuery(timeZoneQuery({ prevTimezone }));
export const useSpecialties = ({ userType, prevSpecialty }: PrevSpecialties) =>
  useQuery(specialtiesQuery({ userType, prevSpecialty }));
export const useUserPaymentMethods = (userId: number) =>
  useQuery(userPaymentMethodsQuery(userId));
export const usePaymentMethods = () => useQuery(paymentMethodsQuery());
export const useBankTransfer = () => useQuery(bankTransferQuery());
export const useAmountTypes = () => useQuery(amountTypesQuery());
export const useUserMeData = ({ enabled }: UserMeData) =>
  useQuery(userMeDataQuery({ enabled }));
export const usePatientsData = ({ currentPage, per_page }: Patients) =>
  useQuery(patientsQuery({ currentPage, per_page }));
export const usePatientData = ({ id }: Patient) =>
  useQuery(patientQuery({ id }));
export const usePatientsInfiniteData = ({ currentPage, per_page }: Patients) =>
  useInfiniteQuery(patientsInfiniteQuery({ currentPage, per_page }));
export const useMyClinics = () => useQuery(myClinicsQuery());
export const useSearchPatients = ({
  query,
  page = 1,
  per_page,
  enabled,
}: SearchPatients) =>
  useQuery(searchPatientsQuery({ query, page, per_page, enabled }));
export const useMyAppointments = ({ start, end }: AppointmentsParams) =>
  useQuery(myAppointmentsQuery({ start, end }));
export const useMyFutureAppointments = ({ start }: AppointmentsParams) =>
  useQuery(myFutureAppointmentsQuery({ start }));
export const useMyPastAppointments = ({ end }: AppointmentsParams) =>
  useQuery(myPastAppointmentsQuery({ end }));
export const useMedicalInfo = ({ id }: Patient) =>
  useQuery(medicalInfoQuery({ id }));
export const useMedicalRecord = ({ id }: Patient) =>
  useQuery(medicalRecordQuery({ id }));
export const useMyMedics = () => useQuery(myMedicsQuery());
