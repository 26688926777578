import { back_action, submit_action } from './actions';
import { email_input } from './inputs';

const forgotPasswordForm = {
  inputs: [{ ...email_input, wrapperClassName: '' }],
  actions: [submit_action, back_action],
  actionWrapperClassName:
    'container d-flex flex-column align-items-center justify-content-center',
};

export default forgotPasswordForm;
