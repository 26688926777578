import styles from 'src/pages/EditClinic/EditClinicScreen.module.scss';
import { useNavigate } from 'react-router-dom';
import BusinessLocationForm from '../BusinessLocationForm';

let initialValues = {
  businessName: '',
  countryCode: '+54',
  phone: '',
  addressStreet: '',
  addressNumber: '',
  address: { description: '', lat: '', lng: '' },
  floor: '',
  createAnother: false,
};
const testClinic = {
  id: 1,
  name: 'Clinic San Andrés',
  lat: '6767843',
  long: '343443',
  address: 'Pero Navarro',
  prefix_telephone: '+54',
  telephone: '89797789',
  logo_image: '',
  floor: '4C',
  users: '',
};

const EditClinic = () => {
  const navigation = useNavigate();
  const clinic = testClinic || null;

  initialValues = {
    ...initialValues,
    address: {
      description: clinic?.address || '',
      lat: clinic?.lat || '',
      lng: clinic?.long || '',
    },
    businessName: clinic?.name || '',
    countryCode: clinic?.prefix_telephone || '',
    phone: clinic?.telephone || '',
    floor: clinic?.floor || '',
  };
  return (
    <div className={styles.container}>
      <BusinessLocationForm navigation={navigation} values={initialValues} />
    </div>
  );
};

export default EditClinic;
