import { useState, FC, CSSProperties } from 'react';
import { clsx } from 'clsx';
import { icons } from '../IconButton/IconDictionary';
import styles from './Search.module.scss';

interface SearchProps {
  disabled?: boolean;
  placeholder: string;
  small?: boolean;
  mobile?: boolean;
  searchBarStyles?: CSSProperties;
  onChange?: (e: any) => void;
}

const Search: FC<SearchProps> = ({
  disabled,
  placeholder,
  small,
  mobile = false,
  searchBarStyles,
  onChange,
}) => {
  const [searchValue, setSearchValue] = useState('');

  const handleSearch = () => {
    if (disabled) return;
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  return (
    <div
      className={clsx(
        styles.container,
        disabled && styles.disabled,
        small ? styles.small : styles.medium,
        mobile && styles.mobile__container
      )}
      style={searchBarStyles}
    >
      <input
        type='text'
        placeholder={disabled ? '' : placeholder}
        onKeyDown={handleKeyDown}
        onChange={(event) => {
          setSearchValue(event.target.value);
          if (onChange) {
            onChange(event.target.value);
          }
        }}
        disabled={disabled}
        className={clsx(
          styles.text__input,
          small && styles.text__input__small,
          mobile && styles.mobile__text__input
        )}
      />

      <div className={`${styles.button_container}`}>
        <button
          className={clsx(styles.btn, disabled && styles.btn__disabled)}
          onClick={handleSearch}
          disabled={disabled}
        >
          <i
            className={clsx(
              styles.btn__icon,
              disabled ? styles.btn__icon__disabled : styles.btn__icon__enabled,
              small && styles.btn__icon__small
            )}
          >
            {icons.search}
          </i>
        </button>
      </div>
    </div>
  );
};

export default Search;
