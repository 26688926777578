import Heading from 'src/components/Heading';
import LinkTo from 'src/components/LinkTo';
import routes from 'src/router/routes';
import locales_es from 'src/locales/es.json';
import LoginForm from 'src/components/LoginForm';

const Login = () => {
  return (
    <>
      <Heading
        title={locales_es.welcome}
        subtitle={locales_es.accessAccount}
        classes='container d-flex justify-content-center p-3'
      />

      <LoginForm />

      <div className='container text-center' data-cy='login-screen-container'>
        <div className='p-2'>
          <LinkTo
            classes='text-primary'
            to={routes.auth_forgotPassword}
            text={locales_es.forgotPassword}
            styles={{ fontSize: 13, fontWeight: 600 }}
          />
          <div className='d-inline-block'>
            <p className='m-0 d-inline-block' style={{ fontSize: 13 }}>
              {locales_es.dontHaveAccount}
            </p>

            <LinkTo
              classes='text-primary ms-1'
              to={routes.auth_register}
              text={locales_es.createAccount}
              styles={{
                fontSize: 12,
                fontWeight: 600,
                textDecoration: 'underline',
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
