import Button from 'src/components/Button';
import IconRounded from 'src/components/IconRounded';

interface Props {
  id: string;
  onExecute: () => void;
}

export const CommandButton = ({ id, onExecute }: Props) => {
  return (
    <>
      {id === 'cancelButton' && (
        <IconRounded
          icon='crossCircle'
          size={50}
          iconColor='#3852a7'
          onClick={onExecute}
        />
      )}
      {id === 'deleteButton' && (
        <IconRounded
          icon='trash'
          size={50}
          iconColor='#3852a7'
          onClick={onExecute}
        />
      )}
      {id === 'saveButton' && (
        <Button variant='primary' handleClick={onExecute} type='button'>
          Guardar
        </Button>
      )}
    </>
  );
};
