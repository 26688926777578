import { useEffect, useState } from 'react';
import Modal from '../Modal';
import { cancelAppointment, getAppointment } from 'src/services/apiService';
import { ClipLoader } from 'react-spinners';
import routes from 'src/router/routes';
import { useModalContext } from 'src/contexts/ModalContext';
import locales_es from 'src/locales/es.json';
import { parseEventDate, parseEventTime } from 'src/utils/date';

export interface Props {
  closeModal: () => void;
  handleCancelAppointment: () => void;
  appointmentId: string;
}

export const CancelAppointmentModal = ({
  closeModal,
  appointmentId,
  handleCancelAppointment,
}: Props) => {
  const { openModal } = useModalContext();

  const [appointmentData, setAppointmentData] = useState<any>(null);
  useEffect(() => {
    getAppointment(appointmentId)
      .then((res) => {
        setAppointmentData(res.data.data);
      })
      .catch(() => {
        setAppointmentData(undefined);
      });
  }, []);

  function cancel() {
    cancelAppointment(appointmentId)
      .then((res: any) => {
        openModal({
          title: locales_es.cancelAppointmentModal.actions.cancel,
          subTitle: res.message,
        });
        closeModal();
        handleCancelAppointment();
      })
      .catch((err) => {
        closeModal();
        handleCancelAppointment();
        openModal({
          title: locales_es.upss,
          subTitle: locales_es.experimentingDificulties,
        });
      });
  }
  return (
    <Modal
      closeModal={closeModal}
      cancellable
      closeButton
      openModal
      title={locales_es.cancelAppointmentModal.title}
      actions={[
        {
          handleClick: cancel,
          variant: 'primary',
          text: locales_es.cancelAppointmentModal.actions.cancel,
        },
        {
          handleClick: closeModal,
          variant: 'outline',
          text: locales_es.cancelAppointmentModal.actions.keep,
        },
      ]}
    >
      <>
        <span className='mt-3'>{locales_es.cancelAppointmentModal.info}</span>
        {appointmentData ? (
          <>
            <h4 className='mt-2 mb-0'>
              <span
                data-hj-allow
                dangerouslySetInnerHTML={{
                  __html: parseEventDate(
                    appointmentData.start,
                    false
                  ) as string,
                }}
              />
              &nbsp;
              <span
                data-hj-allow
                dangerouslySetInnerHTML={{
                  __html: parseEventTime(appointmentData.start, '') as string,
                }}
              />
            </h4>
            <span className='mt-3 mb-1'>
              {locales_es.cancelAppointmentModal.medic}:
            </span>
            <h5 className='mb-3'>
              <a
                className='text-decoration-underline'
                href={`${routes.medic}/${appointmentData.medic.id}`}
              >
                {appointmentData.medic.prefix_name} {appointmentData.medic.name}{' '}
                {appointmentData.medic.lastname}
              </a>
            </h5>
          </>
        ) : appointmentData === null ? (
          <ClipLoader />
        ) : (
          <div>{locales_es.cancelAppointmentModal.not_found}</div>
        )}
      </>
    </Modal>
  );
};

export default CancelAppointmentModal;
