const clear = '/clear';

// AUTH
const auth = '/auth';
const auth_forgotPassword = auth + '/forgotPassword';
const auth_updatePassword = auth + '/updatePassword/:token';
const auth_register = auth + '/register';
const auth_register_new = auth + '/register/new';
const auth_login = auth + '/login';

//HOME
const home = '/';
const home_schedule = home + 'agenda';
const home_patients = home + 'pacientes';
const home_prescriptions = home + 'prescripciones';

// CLINICS
const clinics = '/clinicas';
const clinics_new = clinics + '/new';
const clinics_clinicId = clinics + '/:clinicId';
const clinics_clinicId_edit = clinics_clinicId + '/edit';

// DRAFTS
const drafts = '/borradores';

// LOGOUT
const logout = 'logout';

// PROFILE
const profile = '/perfil';
const profile_edit = profile + '/edit';
const profile_configs = profile + '/configs';

// PATIENTS
const home_patients_add = home_patients + '/agregar';
const home_patients_id = home_patients + '/:patientId';
const home_patients_id_medicalRecord = home_patients_id + '/historiaClinica';
const home_patients_id_messages = home_patients_id + '/mensajes';

// MEDIC
const medic = '/medic';

// PATIENT
const my_medics = '/mis-especialistas';

const routes = {
  clear,
  auth,
  auth_login,
  auth_forgotPassword,
  auth_updatePassword,
  auth_register,
  auth_register_new,
  home,
  home_schedule,
  home_patients,
  home_prescriptions,
  clinics,
  clinics_clinicId,
  clinics_clinicId_edit,
  clinics_new,
  drafts,
  logout,
  profile,
  profile_edit,
  profile_configs,
  home_patients_add,
  home_patients_id,
  home_patients_id_medicalRecord,
  home_patients_id_messages,
  my_medics,
  medic,
};

export default routes;
