import useWindowSize from 'src/hooks/useWindowSize';
import IconButton from '../IconButton';
import { icons } from '../IconButton/IconDictionary';
import styles from './Map.module.scss';

interface Props {
  title: string;
  subTitle: string;
  closeMap: () => void;
}

const SMALL_ICON = 15;
const BIG_ICON = 30;

export const MapInfoBox = ({ title, subTitle, closeMap }: Props) => {
  const { isDesktop } = useWindowSize();
  return (
    <div className={`${styles.info_box_container} d-flex align-items-center`}>
      <div
        className={`d-flex align-items-center justify-content-start ${
          isDesktop ? styles.info_box_desktop : styles.info_box_mobile
        }`}
      >
        <div
          className={`${styles.icon_container} d-flex align-items-center justify-content-center`}
        >
          <i className={`${styles.icon}`}>{icons.mapMarker}</i>
        </div>
        <div className={`${styles.text_container}`}>
          <p className={`${styles.text_title}`}>{title}</p>
          <p className={`${styles.text_subTitle}`}>{subTitle}</p>
        </div>
      </div>
      {isDesktop && (
        <div className='d-flex align-items-center justify-content-center'>
          <IconButton
            buttonSize={isDesktop ? BIG_ICON : SMALL_ICON}
            icon='crossCircle'
            variant='transparent'
            fill='#3d59a3'
            iconSize={isDesktop ? BIG_ICON : SMALL_ICON}
            handleClick={closeMap}
          />
        </div>
      )}
    </div>
  );
};
