import { redirect } from 'react-router-dom';
import routes from 'src/router/routes';
import { EXPIRED } from 'src/services/constants';

export const setAuthToken = (token: string) => {
  localStorage.setItem('token', token);
  checkAuthAccounts(token);
  const expiration = new Date();
  expiration.setHours(expiration.getHours() + 1);
  localStorage.setItem('expiration', expiration.toISOString());
};

export const getTokenDuration = () => {
  const storedExpirationDate = localStorage.getItem('expiration') || '';
  const expirationDate = new Date(storedExpirationDate);
  const now = new Date();
  return expirationDate.getTime() - now.getTime();
};

export const getAuthToken = () => {
  const token = localStorage.getItem('token');
  if (!token) return null;
  const tokenDuration = getTokenDuration();
  if (tokenDuration < 0) return EXPIRED;
  return token;
};

export const checkAuthLoader = () => {
  const token = getAuthToken();

  if (!token) return redirect(routes.logout);

  return token;
};

const checkAuthAccounts = (token: string) => {
  const accounts = JSON.parse(localStorage.getItem('accounts') || '[]');
  if (accounts.length === 0) {
    localStorage.setItem('accounts', JSON.stringify([token]));
    return;
  }
  if (!accounts.includes(token)) {
    accounts.push(token);
  }
};

export const getAuthAccount = (token: string) => {
  const accounts = JSON.parse(localStorage.getItem('accounts') || '[]');
  if (!accounts.includes(token)) return redirect(routes.logout);
  return accounts.indexOf(token);
};

export const logout = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('expiration');
  localStorage.removeItem('accounts');
};
