import { ConfirmationDialog } from '@devexpress/dx-react-scheduler-material-ui';
import styles from '../CustomComponents.module.scss';

export const FormConfirmationDialog = (props: any) => {
  return (
    <ConfirmationDialog.Overlay
      {...props}
      className={styles.confirmation_dialog}
    />
  );
};
