import dayjs from 'dayjs';
import 'dayjs/locale/es';
import localeData from 'dayjs/plugin/localeData';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import locales_es from '../locales/es.json';
import moment from 'moment';

dayjs.extend(localeData);
dayjs.extend(localizedFormat);
dayjs.locale('es');

export const getMonth = (month = dayjs().month()) => {
  month = Math.floor(month);
  const year = dayjs().year();
  // which day of the week id the first day of the month
  const firstDayOfTheMonth = dayjs(new Date(year, month, 1)).day();

  let currentMonthCount = 0 - firstDayOfTheMonth;
  const daysMatrix = new Array(6).fill([]).map(() => {
    return new Array(7).fill(null).map(() => {
      currentMonthCount++;
      return dayjs(new Date(year, month, currentMonthCount));
    });
  });

  return daysMatrix;
};

export const getWeekDays = (locale: any) => {
  dayjs.locale(locale);
  const weekDays = dayjs.weekdaysShort();
  return weekDays;
};

export const checkAdult = (date: string) => {
  if (!date || date.includes('_')) return false;
  const [day, month, year] = date.split('/');

  const age = 18;

  const setDate = new Date(Number(year) + age, Number(month) - 1, Number(day));
  const currentDate = new Date();

  if (currentDate >= setDate) {
    //  above 18
    return true;
  } else {
    return false;
  }
};

export const DEFAULT_TIME_ZONE = 'America/Argentina/Buenos_Aires';

function convertTZ(date: any, tzString: string) {
  /*const parsedDate = (typeof date === "string" ? new Date(date) : date);
  parsedDate.toLocaleString("en-US", {timeZone: tzString});
  return parsedDate;*/
  const parsedDate = typeof date === 'string' ? new Date(date) : date;

  // https://stackoverflow.com/questions/15141762/how-to-initialize-a-javascript-date-to-a-particular-time-zone
  // suppose the date is 12:00 UTC
  const invdate = new Date(
    parsedDate.toLocaleString('en-US', {
      timeZone: tzString,
    })
  );

  // then invdate will be 07:00 in Toronto
  // and the diff is 5 hours
  const diff = parsedDate.getTime() - invdate.getTime();

  // so 12:00 in Toronto is 17:00 UTC
  return new Date(parsedDate.getTime() - diff); // needs to substract
}

export function parseEventDate(
  eventDate: any,
  shortMode?: boolean,
  only?: string
) {
  if (!eventDate) {
    return;
  }

  // const date = new Date(eventDate);
  // const date = this._convertTZ(new Date(eventDate), DEFAULT_TIME_ZONE);
  const date = convertTZ(eventDate, DEFAULT_TIME_ZONE);

  let htmlResult = '';

  let monthMatch = {};

  if (shortMode) {
    monthMatch = {
      Ene: 0,
      Feb: 1,
      Mar: 2,
      Abr: 3,
      May: 4,
      Jun: 5,
      Jul: 6,
      Ago: 7,
      Sep: 8,
      Oct: 9,
      Nov: 10,
      Dic: 11,
    };
  } else {
    monthMatch = {
      Enero: 0,
      Febrero: 1,
      Marzo: 2,
      Abril: 3,
      Mayo: 4,
      Junio: 5,
      Julio: 6,
      Agosto: 7,
      Septiembre: 8,
      Octubre: 9,
      Noviembre: 10,
      Diciembre: 11,
    };
  }

  let month = date.getMonth();

  Object.keys(monthMatch).map((key) => {
    if (month === (monthMatch as any)[key]) {
      month = key as unknown as number;
    }
    return key;
  });

  const day = date.getDate();
  const year = date.getFullYear();

  var days = [
    'Domingo',
    'Lunes',
    'Martes',
    'Miércoles',
    'Jueves',
    'Viernes',
    'Sábado',
  ];
  var dayName = days[date.getDay()];

  // oNLY
  if (only) {
    switch (only) {
      case 'day':
        return day;
      case 'month':
        return month;
      case 'year':
        return year;
      case 'simple-string':
        return day + '/' + month + '/' + year;
      case 'full-string':
        return dayName + ' ' + day + ' de ' + month + ' de ' + year;
      default:
        return '';
    }
  }
  // Only

  if (typeof month === 'string' && shortMode) {
    htmlResult += ' ' + month;
  }

  htmlResult += ' ';
  htmlResult += dayName;

  htmlResult += ' ';
  //htmlResult += getTwentyFourFormatAsString(date.getDate()); // Este metodo, y mismo el forEach para definir el mes, deberia estar en un helper
  htmlResult += day; // Este metodo, y mismo el forEach para definir el mes, deberia estar en un helper

  if (typeof month === 'string' && !shortMode) {
    htmlResult += ' de ' + month;
  }

  htmlResult += ' ';
  htmlResult += year;

  return htmlResult;
}

function getTwentyFourFormatAsString(int: number) {
  return String(int < 10 ? '0' + int : int);
}

function parseTime(
  timeToParse: string,
  isAmPm: boolean,
  hasToAddStrings: boolean
) {
  if (!timeToParse) {
    return {};
  }

  // Ejemplo de un string de tiempo: "09:00:00"
  const hours = timeToParse.substr(0, 2); // 09
  const minutes = timeToParse.substr(3, 2); // 00
  if (isAmPm) {
    const ampm = hasToAddStrings ? (+hours > 11 ? ' PM' : ' AM') : '';
    return {
      hours: +hours % 12 || 12, // converts to correct hours am/pm,
      minutes: minutes + ampm,
    };
  } else {
    const hs = hasToAddStrings ? 'hs.' : '';
    return {
      hours: hours,
      minutes: minutes + hs,
    };
  }
}

export function parseEventTime(eventDate: any, only: string) {
  // const date = new Date(eventDate);
  // const date = this._convertTZ(new Date(eventDate), DEFAULT_TIME_ZONE);
  const date = convertTZ(eventDate, DEFAULT_TIME_ZONE);

  let htmlResult = '';

  const hours = getTwentyFourFormatAsString(date.getHours());
  const minutes = getTwentyFourFormatAsString(date.getMinutes());

  const parsedTime = parseTime(hours + ':' + minutes, false, true);

  if (only === 'full-string') {
    return parsedTime.hours + ':' + parsedTime.minutes;
  }

  htmlResult += ' ' + parsedTime.hours;
  htmlResult += ':' + parsedTime.minutes;

  return htmlResult;
}

export const renderAge = (dateString: string, onlyAge: boolean) => {
  const today = moment();
  const birthDate = moment(dateString, 'YYYY-MM-DDThh:mm:ssZ');

  const years = today.diff(birthDate, 'year');
  birthDate.add(years, 'years');

  const months = today.diff(birthDate, 'months');
  birthDate.add(months, 'months');

  const days = today.diff(birthDate, 'days');

  return onlyAge ? (
    <>
      {years ? years : ''} {years <= 0 && months > 0 ? months : ''}{' '}
      {years <= 0 && months > 0 && months === 1
        ? locales_es.month
        : years <= 0 && months > 1
        ? locales_es.months
        : ''}{' '}
      {years <= 0 && days > 0 ? days : ''}{' '}
      {years <= 0 && days > 0 && days === 1
        ? locales_es.day
        : years <= 0 && days > 1
        ? locales_es.days
        : ''}
    </>
  ) : years || months ? (
    <>
      {years ? years : ''}{' '}
      {years > 1 ? locales_es.years : years > 0 ? locales_es.year : ''}{' '}
      {months > 0 ? months : ''}{' '}
      {months > 0 && months === 1
        ? locales_es.month
        : months > 1
        ? locales_es.months
        : ''}{' '}
      {days > 0 ? days : ''}{' '}
      {days > 0 && days === 1
        ? locales_es.day
        : days > 1
        ? locales_es.days
        : ''}
    </>
  ) : null;
};

export function parseAPIStringToDate(str?: string) {
  if (!str) {
    return;
  }
  const parsedDate = new Date();
  const strCopy = str.slice(); // method to "clone"
  const strParts = strCopy.split('-');
  parsedDate.setUTCFullYear(Number(strParts[0]));
  parsedDate.setUTCMonth(Number(strParts[1]) - 1);
  parsedDate.setUTCDate(Number(strParts[2]));
  parsedDate.setUTCHours(12);
  parsedDate.setUTCMinutes(0);
  parsedDate.setUTCSeconds(0);
  return parsedDate;
}

export function parseStringDateToAPIStringDate(str?: string) {
  if (!str) {
    return;
  }
  const strCopy = str.slice(); // method to "clone"
  const strParts = strCopy.split('/');
  const parsedDate =
    strParts[2] + '-' + strParts[1] + '-' + strParts[0] + 'T12:00:00.000Z';
  return parsedDate;
}

export function parseDateToConventionalAPIString(date: any) {
  if (!date) {
    return;
  }
  const parsedDate =
    getTwentyFourFormatAsString(date.getDate()) +
    '/' +
    getTwentyFourFormatAsString(Number(date.getMonth() + 1)) +
    '/' +
    date.getFullYear();
  return parsedDate;
}

export function getTimeRemaining(endDate:any, asNumber?:boolean) {

  const t = Date.parse(endDate) - Date.parse(new Date() as unknown as string);
  const seconds = Math.floor((t / 1000) % 60);
  const minutes = Math.floor((t / 1000 / 60) % 60);
  const hours = Math.floor((t / (1000 * 60 * 60)) % 24);
  const days = Math.floor(t / (1000 * 60 * 60 * 24));
  return {
    total: t,
    days: asNumber ? days : String(days),
    hours: asNumber ? hours : getTwentyFourFormatAsString(hours),
    minutes: asNumber ? minutes : getTwentyFourFormatAsString(minutes),
    seconds: asNumber ? seconds : getTwentyFourFormatAsString(seconds),
  };
}