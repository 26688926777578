import React, { useEffect, useRef, useState } from 'react';
import styles from 'src/components/FormInput/FormInput.module.scss';

export const FormSelect = (props: any) => {
  return (
    <Select
      name={props.resource.fieldName}
      handleBlur={() => {}}
      isForm={false}
      variant='outline'
      data={props.resource.instances}
      label={props.label}
      onChange={props.onResourceChange}
      style={{ position: 'relative' }}
    />
  );
};

const RADIUS = 24;

type variant = 'primary' | 'secondary' | 'grey' | 'outline' | 'disabled';

interface Props {
  label: string;
  name: string;
  data: any;
  variant: variant;
  onChange?: any;
  placeholder?: string;
  classes?: string;
  style?: React.CSSProperties;
  disabled?: boolean;
  handleBlur: () => void;
  [x: string]: any;
  testId?: string;
  isForm?: boolean;
}

export const Select = ({
  label,
  classes = '',
  style = {},
  onChange = () => {},
  disabled = false,
  placeholder = 'Selecciona una opción',
  data = [],
  variant,
  testId,
  handleBlur,
  isForm = true,
  ...props
}: Props) => {
  const [showList, setShowList] = useState(false);
  const [selected, setSelected] = useState<any>(
    data.find((el: any) => el.id === 'spontaneus')
  );
  const selectRef = useRef<any>(null);

  const handleShowList = () => {
    if (disabled || variant === 'disabled') return;

    setShowList(!showList);
  };

  useEffect(() => {
    onChange({ [selected.fieldName]: selected.id });
  }, []);

  useEffect(() => {
    if (disabled) {
      setSelected('');
    }
  }, [disabled]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setShowList(false);
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [setShowList]);

  return (
    <div className={classes} style={style}>
      <label htmlFor={props.name} className={styles.label}>
        {label}
      </label>

      <div
        className={`${showList && styles.extendedTagInput_Suggestions}  ${
          styles.simpleField
        }  ${styles.select}  ${
          styles[variant]
        } d-flex justify-content-between `}
        onClick={handleShowList}
        onFocus={() => {}}
        onBlur={handleBlur}
        style={{
          cursor: 'pointer',
          borderBottomLeftRadius: showList ? 0 : RADIUS,
          borderBottomRightRadius: showList ? 0 : RADIUS,
          borderBottomWidth: showList && variant === 'outline' ? 0 : 1,
        }}
        ref={selectRef}
        data-testid={testId}
      >
        <div className='w-100'>
          <p style={{ margin: 0 }}>
            {selected ? (
              <SelectedItem color={selected.color} text={selected.text} />
            ) : (
              placeholder
            )}
          </p>
          {showList && (
            <ul className={`${styles.selectList}`}>
              {data.map((item: any, i: number) => (
                <li
                  key={item.id}
                  onClick={() => {
                    onChange({ [item.fieldName]: item.id });
                    setSelected(item);
                  }}
                  data-testid={`${item.name}-${i}`}
                >
                  <SelectedItem color={item.color} text={item.text} />
                </li>
              ))}
            </ul>
          )}
        </div>

        <div className={`${styles.icon_container}`}>V</div>
      </div>
    </div>
  );
};

interface SelectedItemProps {
  color: string;
  text: string;
}

const SelectedItem = ({ color, text }: SelectedItemProps) => {
  return (
    <div className='d-flex align-items-center justify-content-start'>
      <div
        style={{
          backgroundColor: color,
          width: 18,
          height: 18,
          marginLeft: 5,
          marginRight: 5,
          borderRadius: 100,
        }}
      />
      {text}
    </div>
  );
};
