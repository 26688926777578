import { useEffect, useState } from 'react';
import Modal from '../Modal';
import {
  getBankTransfer,
  getMedicById,
  getPaymentMethods,
} from 'src/services/apiService';
import { ClipLoader } from 'react-spinners';
import { useModalContext } from 'src/contexts/ModalContext';
import locales_es from 'src/locales/es.json';
import Button from '../Button';
import {
  PAYMENT_METHOD_BANK_TRANSFER,
  PAYMENT_METHOD_MANUAL,
  PAYMENT_METHOD_MERCADO_PAGO,
} from 'src/services/constants';
import {
  PAYMENT_METHOD_AMOUNT_TYPE_FIXED,
  PAYMENT_METHOD_AMOUNT_TYPE_PERCENT,
} from 'src/pages/MyAppointments/consts';

export interface Props {
  medicId: any;
  closeModal: () => void;
  returnToMainModal: (medicId: string) => void;
}

export const PaymentOptionsModal = ({
  medicId,
  closeModal,
  returnToMainModal,
}: Props) => {
  const { openModal } = useModalContext();

  const [paymentMethodsData, setPaymentMethodsData] = useState<any>(null);
  const bankTransfer = paymentMethodsData?.methods?.find(
    (method: any) => method.payment_method.tag === PAYMENT_METHOD_BANK_TRANSFER
  );
  const mercadopago = paymentMethodsData?.methods?.find(
    (method: any) => method.payment_method.tag === PAYMENT_METHOD_MERCADO_PAGO
  );
  const manual = paymentMethodsData?.methods?.find(
    (method: any) => method.payment_method.tag === PAYMENT_METHOD_MANUAL
  );

  useEffect(() => {
    Promise.all([
      getPaymentMethods(medicId.medic_id),
      getMedicById(medicId.medic_id),
    ])
      .then(([paymentData, medicData]) => {
        setPaymentMethodsData({
          methods: paymentData.data.data,
          medic: medicData.data.data,
        });
      })
      .catch(() => {
        openModal({
          title: locales_es.upss,
          subTitle: locales_es.experimentingDificulties,
        });
      });
  }, []);

  const onBankTransferClick = () => {
    getBankTransfer(paymentMethodsData.medic.id)
      .then((res) => {
        openModal({
          title: locales_es.bankTransferDataModal.title,
          children: (
            <>
              <div className='text-center'>
                <span>
                  <i className='fi fi-rr-bank' style={{ fontSize: 18 }}></i>
                </span>
                <div>
                  <strong>{locales_es.bankTransferDataModal.subtitle}</strong>.
                  <br />
                  <br />
                  <div>{res.data.data.data}</div>
                  <div className='m-2'>
                    {paymentMethodsData.medic.before_payment_type ===
                    PAYMENT_METHOD_AMOUNT_TYPE_FIXED
                      ? `${locales_es.bankTransferDataModal.shouldTransferOne} $${medicId.before_payment_amount}}, ${locales_es.bankTransferDataModal.shouldTransferTwo}.`
                      : ''}
                    {paymentMethodsData.medic.before_payment_type ===
                    PAYMENT_METHOD_AMOUNT_TYPE_PERCENT
                      ? `${locales_es.bankTransferDataModal.shouldTransferOne} $${medicId.before_payment_amount}, que es el ${paymentMethodsData.medic.before_payment_amount}% ${locales_es.bankTransferDataModal.shouldTransferThree}.`
                      : ''}
                  </div>
                </div>
              </div>
            </>
          ),
          actions: [
            {
              text: locales_es.cancel,
              variant: 'outline',
              handleClick: () => returnToMainModal(medicId),
            },
            {
              text: locales_es.continue,
              variant: 'primary',
              handleClick: () => {},
            },
          ],
        });
        closeModal();
      })
      .catch(() => {
        openModal({
          title: locales_es.upss,
          subTitle: locales_es.experimentingDificulties,
        });
      });
  };

  const onMercadoPagoClick = () => {
    getBankTransfer(paymentMethodsData.medic.id)
      .then((res) => {
        openModal({
          title: locales_es.mercadoPagoDataModal.title,
          children: (
            <>
              <div className='text-center'>
                <span>
                  <i className='fi fi-rr-bank' style={{ fontSize: 18 }}></i>
                </span>
                <div>
                  <strong>{locales_es.mercadoPagoDataModal.subtitle}</strong>.
                  <br />
                  <br />
                  <div className='m-2'>
                    {paymentMethodsData.medic.before_payment_type ===
                    PAYMENT_METHOD_AMOUNT_TYPE_FIXED
                      ? `${locales_es.mercadoPagoDataModal.shouldTransferOne} $${medicId.before_payment_amount}, ${locales_es.mercadoPagoDataModal.shouldTransferTwo}.`
                      : ''}
                    {paymentMethodsData.medic.before_payment_type ===
                    PAYMENT_METHOD_AMOUNT_TYPE_PERCENT
                      ? `${locales_es.mercadoPagoDataModal.shouldTransferOne} $${medicId.before_payment_amount}, que es el ${paymentMethodsData.medic.before_payment_amount}% ${locales_es.mercadoPagoDataModal.shouldTransferThree}.`
                      : ''}
                  </div>
                </div>
              </div>
            </>
          ),
          actions: [
            {
              text: locales_es.cancel,
              variant: 'outline',
              handleClick: () => returnToMainModal(medicId),
            },
            {
              text: locales_es.continue,
              variant: 'primary',
              handleClick: () => {
                window.location.href = medicId.payment_url;
              },
            },
          ],
        });
        closeModal();
      })
      .catch(() => {
        openModal({
          title: locales_es.upss,
          subTitle: locales_es.experimentingDificulties,
        });
      });
  };

  return (
    <Modal
      closeModal={closeModal}
      cancellable
      closeButton
      openModal
      title={locales_es.appointmentScheduled.title}
    >
      <>
        {paymentMethodsData ? (
          <>
            <div>
              <div className='text-center mt-3 mb-3'>
                <span className='d-flex justify-content-center gap-2'>
                  <i
                    className='fi fi-rr-triangle-warning'
                    style={{ fontSize: 18 }}
                  />
                  <span>
                    <strong>{locales_es.important}: </strong>
                    {locales_es.appointmentScheduled.text1}
                  </span>
                </span>
                <div>
                  <br />
                  <strong>{locales_es.appointmentScheduled.text2}</strong>.
                  <br />
                  <br />
                  {locales_es.appointmentScheduled.text3}:
                </div>
              </div>
              <div className='d-flex justify-content-center flex-wrap'>
                {bankTransfer && (
                  <Button
                    handleClick={onBankTransferClick}
                    variant='primary'
                    classes={'gap-2'}
                  >
                    <>
                      <i
                        className='fi fi-rr-bank mt-1'
                        style={{ fontSize: 18 }}
                      />
                      <span>
                        {locales_es.appointmentScheduled.options.bankTransfer}
                      </span>
                    </>
                  </Button>
                )}
                {mercadopago && (
                  <Button
                    handleClick={onMercadoPagoClick}
                    variant='patient'
                    classes={'gap-2'}
                  >
                    <>
                      <i
                        className='fi fi-rr-credit-card mt-1'
                        style={{ fontSize: 18 }}
                      />
                      <span>
                        {locales_es.appointmentScheduled.options.card}
                      </span>
                    </>
                  </Button>
                )}
                {manual && (
                  <Button
                    handleClick={closeModal}
                    variant='medic'
                    classes={'gap-2'}
                  >
                    <>
                      <i
                        className='fi fi-rr-check mt-1'
                        style={{ fontSize: 18 }}
                      />
                      <span>
                        {locales_es.appointmentScheduled.options.manual}
                      </span>
                    </>
                  </Button>
                )}
              </div>
            </div>
          </>
        ) : paymentMethodsData === null ? (
          <ClipLoader />
        ) : (
          <div>{locales_es.appointmentScheduled.not_found}</div>
        )}
      </>
    </Modal>
  );
};

export default PaymentOptionsModal;
