import { AppointmentTooltip } from '@devexpress/dx-react-scheduler-material-ui';

import styles from './CustomComponents.module.scss';

export const AppointmentTooltipContent = ({
  appointmentData,
  appointmentResources,
  ...restProps
}: any) => {
  return (
    <AppointmentTooltip.Content
      appointmentData={appointmentData}
      appointmentResources={appointmentResources}
      {...restProps}
      className={styles.main_font}
    >
      {appointmentData?.notes && (
        <div className={styles.appointment_tooltip_notes_container}>
          <h6>Notas:</h6>
          <div>{appointmentData?.notes}</div>
        </div>
      )}
    </AppointmentTooltip.Content>
  );
};
