import { WeekView } from '@devexpress/dx-react-scheduler-material-ui';

export const TimeCell = (props: any) => {
  return (
    <WeekView.TimeTableCell
      {...props}
      className='bg-white'
    ></WeekView.TimeTableCell>
  );
};
