import { HeaderProps } from '.';
import { useAppSelector } from 'src/app/hooks';
import { USER_TYPE_PATIENT } from 'src/utils/constants';
import { MedicDesktopHomeHeader } from './MedicDesktopHomeHeader';
import { PatientDesktopHomeHeader } from './PatientDesktopHomeHeader';

const DesktopHomeHeaderWrapper = (props: HeaderProps) => {
  const user = useAppSelector((state) => state.user?.data);
  return user?.user_type === USER_TYPE_PATIENT ? (
    <PatientDesktopHomeHeader {...props} />
  ) : (
    <MedicDesktopHomeHeader {...props} />
  );
};

export default DesktopHomeHeaderWrapper;
