import { createBrowserRouter } from 'react-router-dom';
import routes from './routes';

// utils
import { checkAuthLoader } from 'src/utils/auth';

// Containers
import DashboardContainer from 'src/containers/dashboard';
import RootAuthContainer from 'src/containers/root';
import RootHome from 'src/containers/home';

// Medic Pages
import ScheduleScreen from 'src/pages/Schedule';
import DraftsScreen from 'src/pages/DraftsScreen';

// Auth Pages
import Logout from 'src/pages/Logout';
import RegisterNew from '../pages/RegisterNew';
import Register from 'src/pages/Register';
import Login from 'src/pages/Login';
import ForgotPassword from 'src/pages/ForgotPassword';
import Patients from 'src/pages/Patients';

// Profile pages
import EditProfile from 'src/pages/EditProfile';
import Configs from 'src/pages/Config';

// Patient pages
import AddPatient from 'src/pages/AddPatient';
import PatientProfile from 'src/pages/PatientProfile';

// Clinic pages
import DashboardClinics from 'src/pages/DashboardClinics';
import NewClinic from 'src/pages/NewClinic';
import ClinicUsers from 'src/pages/ClinicUsers';
import EditClinic from 'src/pages/EditClinic';
import MyMedics from 'src/pages/MyMedics';

const router = createBrowserRouter([
  {
    path: routes.auth,
    element: <RootAuthContainer />,
    children: [
      { path: routes.auth_login, element: <Login /> },
      { path: routes.auth_register, element: <Register /> },
      { path: routes.auth_register_new, element: <RegisterNew /> },
      { path: routes.auth_forgotPassword, element: <ForgotPassword /> },
    ],
  },
  {
    path: routes.home,
    element: <RootHome />,
    loader: checkAuthLoader,
    children: [
      { path: routes.home, element: <DashboardContainer /> },
      { path: routes.my_medics, element: <MyMedics /> },
      { path: routes.home_schedule, element: <ScheduleScreen /> },
      { path: routes.home_prescriptions, element: <ScheduleScreen /> },
      { path: routes.home_patients, element: <Patients /> },
      {
        path: routes.home_patients_id_medicalRecord,
        element: <PatientProfile />,
      },
      {
        path: routes.home_patients_id_messages,
        element: <PatientProfile />,
      },
      { path: routes.clinics, element: <DashboardClinics /> },
      { path: routes.clinics_new, element: <NewClinic /> },
      { path: routes.clinics_clinicId, element: <ClinicUsers /> },
      { path: routes.clinics_clinicId_edit, element: <EditClinic /> },
      { path: routes.drafts, element: <DraftsScreen /> },
      { path: routes.profile_edit, element: <EditProfile /> },
      { path: routes.profile_configs, element: <Configs /> },
      { path: routes.home_patients_add, element: <AddPatient /> },
    ],
  },
  { path: routes.logout, element: <Logout /> },
]);

export default router;
