import styles from './DayShifts.module.scss';

export interface Props {
  color: string;
  type: string;
  amount: string;
}

export const Shift = (shift: Props) => {
  return (
    <div
      key={shift.type}
      className={`d-flex align-items-center justify-content-between p-1 ${styles.shifts_state_item_container}`}
    >
      <div
        className={`${styles.shifts_state_colorBox}`}
        style={{ backgroundColor: shift.color }}
      />
      <div className={`${styles.shifts_state_type}`}>
        <p>{shift.type}</p>
      </div>
      <div
        className={`d-flex align-items-center justify-content-center ${styles.shifts_state_amount}`}
      >
        <p>{shift.amount}</p>
      </div>
    </div>
  );
};
