import { icon } from 'src/utils/models';

export interface Icon {
  icon: icon;
  size: number;
  color: string;
}

export const NO_ICON: Icon = {
  icon: 'success',
  size: 0,
  color: '#1cac81',
};

export const API_ERROR_ICON: Icon = {
  icon: 'bolt',
  size: 80,
  color: '#1cac81',
};

export const FORM_ERROR_ICON: Icon = {
  icon: 'bolt',
  size: 80,
  color: '#1cac81',
};

export const FORM_HAPPY_ICON: Icon = {
  icon: 'happy',
  size: 80,
  color: '#1cac81',
};
