import { motion } from 'framer-motion';
import { clsx } from 'clsx';
import Button from '../Button';
import { icons } from '../IconButton/IconDictionary';
import useWindowSize from 'src/hooks/useWindowSize';
import styles from './ButtonsMenu.module.scss';
import { icon, ButtonProps } from 'src/utils/models';

const BUTTON_STYLE = {
  borderWidth: '5px',
  borderStyle: 'solid',
  paddingTop: '20px',
  paddingBottom: '20px',
};

interface Animation {
  initial: {
    opacity: number;
  };
  animate: {
    opacity: number;
  };
}

export interface MobileButtonMenu {
  variant: ButtonProps['variant'];
  backgroundColor: string;
  borderColor: string;
  shadow: boolean;
  text: string;
  icon: icon;
  onClick: { (): void };
}

interface Props {
  buttons: MobileButtonMenu[];
  animation?: Animation;
  animate: boolean;
  closeMenu?: () => void;
}

const NewButtonsMenu = ({
  buttons,
  animate = false,
  animation,
  closeMenu,
}: Props) => {
  const { isMobile } = useWindowSize();
  return (
    <motion.div
      className={clsx(
        styles.mobile_container,
        !isMobile && styles.desktop_container
      )}
      initial={animate && animation?.initial}
      animate={animate && animation?.animate}
      exit={{ opacity: 0 }}
    >
      {buttons.map((btn) => (
        <motion.div
          key={btn.text}
          whileHover={!isMobile ? { translateX: '-110px' } : {}}
        >
          <Button
            variant={btn.variant}
            style={{
              ...BUTTON_STYLE,
              backgroundColor: btn.backgroundColor,
              borderColor: btn.borderColor,
              margin: isMobile ? 10 : 0,
              padding: isMobile ? '8px 24px' : '8px',
            }}
            shadow={btn.shadow}
            handleClick={() => {
              btn.onClick();
              closeMenu && closeMenu();
            }}
          >
            <div
              className={`${styles.btn_content} ${
                isMobile
                  ? styles.mobile_btn_content
                  : styles.desktop_btn_content
              }`}
            >
              <p className={`${styles.mobile_btn_text_content}`}>{btn.text}</p>
              <i
                className={clsx(
                  styles.button_icon_container,
                  !isMobile && styles.desktop_button_icon
                )}
              >
                {icons[btn.icon]}
              </i>
            </div>
          </Button>
        </motion.div>
      ))}
    </motion.div>
  );
};

export default NewButtonsMenu;
