import { useState, useRef } from 'react';
import useWindowSize from 'src/hooks/useWindowSize';
import User from 'src/components/User';
import IconButton from 'src/components/IconButton';
import { clsx } from 'clsx';
import UserMenu from 'src/components/UserMenu';
import useOnClickOutside from 'src/hooks/useOnClickOutside';
import styles from './UserInfo.module.scss';
import { Link } from 'react-router-dom';
import routes from 'src/router/routes';

interface Props {
  avatarSize: number;
  name: string;
  clinic?: string;
  toggleLogoutModal: () => void;
}

const MOBILE_ICON_SIZE = 40;
const DESKTOP_ICON_SIZE = 30;

export const UserInfo = ({
  avatarSize,
  name,
  clinic,
  toggleLogoutModal,
}: Props) => {
  const [openUserMenu, setOpenUserMenu] = useState(false);

  const ref = useRef<HTMLDivElement>(null);

  const { isMobile } = useWindowSize();

  const toggleMenu = () => {
    setOpenUserMenu(!openUserMenu);
  };
  const closeMenu = () => {
    setOpenUserMenu(false);
  };

  useOnClickOutside(ref, closeMenu);
  return (
    <>
      <div className={`${styles.user_container}`} ref={ref}>
        <div className='d-flex justify-content-between'>
          {isMobile && (
            <IconButton
              buttonSize={isMobile ? MOBILE_ICON_SIZE : DESKTOP_ICON_SIZE}
              iconSize={isMobile ? MOBILE_ICON_SIZE : DESKTOP_ICON_SIZE}
              icon='logout'
              variant='transparent'
              handleClick={toggleMenu}
            />
          )}

          <Link to={routes.profile_configs}>
            <IconButton
              buttonSize={isMobile ? MOBILE_ICON_SIZE : DESKTOP_ICON_SIZE}
              iconSize={isMobile ? MOBILE_ICON_SIZE : DESKTOP_ICON_SIZE}
              icon='settings'
              variant={isMobile ? 'white' : 'primary'}
            />
          </Link>
        </div>
        <UserMenu
          open={openUserMenu}
          closeMenu={closeMenu}
          toggleLogoutModal={toggleLogoutModal}
        />

        <User
          avatarSize={avatarSize}
          classes={
            isMobile ? 'flex-column' : 'flex-row align-items-center me-3'
          }
          onAvatarClick={toggleMenu}
        >
          <div className={clsx(isMobile && 'mt-3')}>
            <p className={`ms-2 mb-0 ${styles.name}`}>{name}</p>
            {isMobile && clinic && (
              <p className={`ms-2 mb-0 ${styles.place}`}>{clinic}</p>
            )}
          </div>
        </User>
      </div>
    </>
  );
};
