import React from 'react';
import Avatar from 'src/components/Avatar';
import { Medic } from './interfaces';
import styles from './MedicCard.module.scss';
import Card from '../Card';
import routes from 'src/router/routes';
import Button from '../Button';
import locales_es from 'src/locales/es.json';
import { Link } from 'react-router-dom';
import { HREF_PAGE_ONLINE } from 'src/pages/MyAppointments/consts';
import { useAppSelector } from 'src/app/hooks';

interface Props {
  medic: Medic;
}

export const MedicCard = ({ medic }: Props) => {
  const userData = useAppSelector((state) => state.user?.data);
  return (
    <Card variant='primary' classes={styles.container}>
      <div className='d-flex align-items-center border-bottom'>
        <Avatar size={60} imageUrl={medic.full_profile_image} variant='grey' />
        <div className='p-3'>
          <Link to={`${routes.medic}/${medic.id}`}>
            <b>
              <p className='m-0'>
                {medic.prefix_name} {medic.name} {medic.lastname}
              </p>
            </b>
          </Link>
          <p className='m-0 text-muted'>{medic.specialty_name}</p>
        </div>
      </div>
      <div className='pt-4 p-0 ps-2 pb-1 flex-grow-1'>
        <div className='col-12' data-hj-allow>
          {medic.description ? String(medic.description).substring(0, 255) : null}
          {medic.description?.length > 255 && (
            <>
              ...
              <Link className={styles.read_more} to={`/medic/${medic.id}`}>
                {locales_es.readMore}
              </Link>
            </>
          )}
        </div>
      </div>
      <div className='d-flex align-items-center mt-3 mb-1 gap-3'>
        <div className={styles.actions_container}>
          <Link to={`/medic/${medic.id}`}>
            <Button
              classes={styles.actions}
              variant='outline'
              handleClick={() => {}}
            >
              <span>{locales_es.seeProfile}</span>
            </Button>
          </Link>

          <Link to={`/medic/${medic.id}`}>
            <Button classes={styles.actions} variant='primary'>
              <span>{locales_es.createAppointment}</span>
            </Button>
          </Link>

          <Link to={`${HREF_PAGE_ONLINE}/${medic.id}/${userData?.id}`}>
            <Button
              classes={styles.actions}
              variant='medic'
              handleClick={() => {}}
            >
              <span>{locales_es.sendMessage}</span>
            </Button>
          </Link>
        </div>
      </div>
    </Card>
  );
};

export default MedicCard;
