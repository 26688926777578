import { submit_action } from 'src/utils/forms/actions';
import {
  date_of_birth_input,
  email_input,
  firstName_input,
  genders_input,
  identification_input,
  identification_type_id_input,
  lastName_input,
  phoneCode_input,
  timeZone_input,
} from 'src/utils/forms/inputs';
import locales_es from 'src/locales/es.json';

const editProfilePatientForm = {
  inputs: [
    { ...email_input, wrapperClassName: 'd-block', disabled: true },
    { ...firstName_input },
    { ...lastName_input },
    {
      ...phoneCode_input,
      wrapperClassName: 'd-inline-block col-md-12 ',
    },
    {
      ...identification_type_id_input,
      wrapperClassName: 'd-inline-block col-md-6 ',
    },
    {
      ...identification_input,
      wrapperClassName: 'd-inline-block col-md-6 ',
    },
    date_of_birth_input,
    genders_input,
    timeZone_input,
  ],
  actions: [{ ...submit_action, text: locales_es.update }],
  actionWrapperClassName:
    'container d-flex align-items-center justify-content-between',
};

export default editProfilePatientForm;
