import { IconButtonProps } from 'src/utils/models';
import { icons } from './IconDictionary';
import styles from './IconButton.module.scss';

const IconButton: React.FC<IconButtonProps> = ({
  variant = 'primary',
  buttonSize = 40,
  iconSize = 18,
  icon = 'menu',
  handleClick,
  classes = '',
  fill = '',
  disabled,
}: IconButtonProps) => {
  return (
    <button
      className={`${styles.btn} ${styles[variant]} ${classes}`}
      style={{ width: buttonSize, height: buttonSize }}
      onClick={handleClick}
      disabled={disabled}
    >
      <i style={{ width: iconSize, height: iconSize, fill }}>{icons[icon]}</i>
    </button>
  );
};

export default IconButton;
