import { createAsyncThunk } from '@reduxjs/toolkit';
import { endLoading, startLoading } from './loadingSlice';
import locales_es from 'src/locales/es.json';
import { getUserMe } from 'src/services/apiService';

export const getUser = createAsyncThunk(
  'user/getUser',
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(
      startLoading({
        opacity: 0.7,
        text: `${locales_es.loading}...`,
      })
    );
    try {
      const { data } = await getUserMe();
      return data;
    } catch (error: any) {
      return rejectWithValue({
        message: error.response.data.Message,
        name: error.message,
      });
    } finally {
      dispatch(endLoading());
    }
  }
);
