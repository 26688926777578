import styles from '../CustomComponents.module.scss';

export const TextInput = ({
  onValueChange,
  placeholder,
  readOnly,
  type,
  value,
}: any) => {
  return (
    <>
      {type === 'titleTextEditor' ? (
        <input
          value={value || ''}
          onChange={(e) => onValueChange(e.target.value)}
          className={styles.simpleField}
          placeholder={placeholder}
          disabled={readOnly}
        />
      ) : (
        <textarea
          value={value || ''}
          onChange={(e) => onValueChange(e.target.value)}
          className={styles.textarea}
          placeholder={placeholder}
          disabled={readOnly}
        />
      )}
    </>
  );
};
