import { useState } from 'react';
import { Formik, Form, FormikHelpers } from 'formik';
import { NavigateFunction } from 'react-router-dom';
import Button from 'src/components/Button';
import { TextInput } from 'src/components/FormInput';
import { Select } from 'src/components/FormInput/Select';
import IconRounded from 'src/components/IconRounded';
import ImagePreview from 'src/components/ImagePreview';
import useImage from 'src/hooks/useImage';
import Modal from 'src/components/Modal';
import ImageCropper from 'src/components/ImageCropper';
import IconButton from 'src/components/IconButton';
import SearchPlaces from 'src/components/SearchPlaces';
import Map from 'src/components/Map';
import styles from './BusinessLocationForm.module.scss';
import { StaticMap } from 'src/components/Map/StaticMap';
import { useAppDispatch } from 'src/app/hooks';
import { useModalContext } from 'src/contexts/ModalContext';
import { API_ERROR_MESSAGE } from 'src/utils/constants';
import locales_es from 'src/locales/es.json';
import { API_ERROR_ICON, FORM_HAPPY_ICON } from 'src/icons/constants';
import { endLoading, startLoading } from 'src/app/slices/loadingSlice';
import { getUser } from 'src/app/slices/thunksUser';
import { postClinic } from 'src/services/apiService';

interface FormValues {
  businessName: string;
  countryCode: string;
  phone: string;
  addressStreet: string;
  addressNumber: string;
  address: { description: string; lat: string; lng: string };
  floor: string;
  createAnother: boolean;
}

const initialValues = {
  businessName: '',
  countryCode: '+54',
  phone: '',
  addressStreet: '',
  addressNumber: '',
  address: { description: '', lat: '', lng: '' },
  floor: '',
  createAnother: false,
};

const IMAGE_SIZE = 100;

interface Props {
  navigation: NavigateFunction;
  values?: FormValues | null;
}

const BusinessLocationForm = ({ navigation, values = null }: Props) => {
  const [toggleMap, setToggleMap] = useState(false);
  const [maxMap, setMaxMap] = useState(false);
  const [toggleEditMapBtn, setToggleEditMapBtn] = useState(false);
  const [coords, setCoords] = useState({ lat: '', lng: '' });
  const dispatch = useAppDispatch();
  const {
    handleImageChange,
    imageURL,
    inputHiddenRef,
    selectPhoto,
    openCropper,
    closeImageCropper,
    openImageCropper,
    editImage,
    image,
  } = useImage();

  const { openModal } = useModalContext();

  return (
    <>
      <Formik
        initialValues={values ? values : initialValues}
        onSubmit={async (
          values,
          formikHelpers: FormikHelpers<typeof initialValues>
        ) => {
          dispatch(
            startLoading({
              opacity: 0.7,
              text: `${locales_es.loading}...`,
            })
          );
          try {
            const userId = JSON.parse(localStorage.getItem('userProfile')!);
            const formData = new FormData();

            formData.append('name', values.businessName);
            formData.append('lat', `${values.address.lat}`);
            formData.append('long', `${values.address.lng}`);
            formData.append('address', values.address.description);
            formData.append('prefixTelephone', values.countryCode);
            formData.append('telephone', values.phone);
            formData.append('floor', values.floor);
            formData.append('userId', userId.id);

            if (image) {
              formData.append('logoImage', image);
            }

            await postClinic(formData);
            if (values.createAnother) {
              openModal({
                title: locales_es.success,
                subTitle: locales_es.successCreatingClinic,
                icon: FORM_HAPPY_ICON,
              });
              formikHelpers.resetForm();
              setToggleMap(false);
              setMaxMap(false);
              setCoords({ lat: '', lng: '' });
              editImage(null, null);
              return;
            }
            openModal({
              title: locales_es.success,
              subTitle: locales_es.successCreatingClinic,
              icon: FORM_HAPPY_ICON,
              onClose: () => {
                dispatch(getUser());
              },
            });
          } catch (error: any) {
            const errorMessage =
              error.response.data.Message || error.message || API_ERROR_MESSAGE;
            openModal({
              title: 'Error',
              subTitle: errorMessage,
              icon: API_ERROR_ICON,
            });
          } finally {
            dispatch(endLoading());
          }
        }}
        validationSchema={{}}
      >
        {({
          values,
          setFieldValue,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form
            noValidate
            autoComplete='off'
            className='container-fluid d-flex flex-column align-items-center justify-content-end p-3'
          >
            <div className='container'>
              <div className='container-fluid d-flex align-items-center justify-content-between'>
                <input
                  ref={inputHiddenRef}
                  type='file'
                  name='photo'
                  accept='image/*'
                  onChange={handleImageChange}
                  style={{ display: 'none' }}
                />
                <div className='w-50 d-flex align-items-end justify-content-start'>
                  {imageURL ? (
                    <div
                      className='d-flex align-items-end justify-content-center mb-3'
                      style={{ height: IMAGE_SIZE }}
                    >
                      <ImagePreview
                        src={imageURL}
                        alt={'preview'}
                        imageStyles={{ objectFit: 'cover' }}
                        onClick={selectPhoto}
                        roundness='40%'
                        size={IMAGE_SIZE}
                      />
                      <IconButton
                        variant='primary'
                        handleClick={openImageCropper}
                        icon={'crop'}
                        buttonSize={40}
                        iconSize={20}
                      />
                    </div>
                  ) : (
                    <IconRounded
                      icon='picture'
                      classes='bg-secondary'
                      roundness='40%'
                      onClick={selectPhoto}
                    />
                  )}
                </div>

                <div>
                  <p className='m-0 ms-2' style={{ fontSize: 14 }}>
                    {locales_es.imageLogo}
                  </p>
                  <p className='ms-2' style={{ fontSize: 14 }}>
                    {locales_es.optional}
                  </p>
                </div>
              </div>
              <TextInput
                name='businessName'
                placeholder='Nombre del lugar'
                classes='mt-2'
              />

              <p className='p-0 mb-0'>{locales_es.phone}</p>
              <div className=' d-flex align-items-center justify-content-between p-0'>
                <Select
                  label=''
                  name='countryCode'
                  classes='w-50 me-1'
                  style={{ minWidth: 100 }}
                  list={[{ id: '+54', name: '+54' }]}
                  onChange={(value: string | number) => {
                    const newValue = value.toString();
                    setFieldValue('countryCode', newValue);
                  }}
                  handleBlur={() => handleBlur('countryCode')}
                  placeholder='Código'
                  variant='grey'
                />

                <TextInput name='phone' placeholder='Teléfono' classes='w-75' />
              </div>
              <div className='d-flex align-items-center'>
                <TextInput
                  name='addressStreet'
                  placeholder='Calle/Avenida'
                  classes='w-100  me-1'
                />

                <TextInput
                  name='addressNumber'
                  placeholder='Número'
                  style={{ maxWidth: 150 }}
                />
              </div>
              <TextInput name='floor' placeholder='Piso/Dpto' classes='mt-2' />
              <SearchPlaces
                placeholder={locales_es.googlePlacesAddress}
                iconLeft='search'
                setSelected={({ description, lat, lng }: any) => {
                  setFieldValue('address', {
                    description,
                    lat,
                    lng,
                  });
                  setCoords({ lat, lng });
                  setToggleMap(true);
                }}
                variant='purple'
                addressStreet={values.addressStreet}
                addressNumber={values.addressNumber}
              />
              <div style={{ height: '3px' }} />
              {toggleMap && (
                <div className='d-flex align-items-center justify-content-center mt-2 mb-2'>
                  <div style={{ position: 'relative', bottom: 40 }}>
                    <StaticMap
                      center={{
                        lat: Number(values.address.lat),
                        lng: Number(values.address.lng),
                      }}
                      onClick={() => {
                        setMaxMap(true);
                      }}
                      address={values.address.description}
                    />
                  </div>

                  {maxMap && (
                    <div className={`${styles.map_fullScreen_container} `}>
                      <div className={`${styles.map_wrapper}`}>
                        <Map
                          center={{
                            lat: Number(values.address.lat),
                            lng: Number(values.address.lng),
                          }}
                          handleMarkerDrag={({ lat, lng }: any) => {
                            setFieldValue('address', {
                              ...values.address,
                              lat,
                              lng,
                            });
                          }}
                          maxMap={maxMap}
                          closeMap={() => {
                            setFieldValue('address', {
                              ...values.address,
                              ...coords,
                            });
                            setMaxMap(false);
                          }}
                          confirmLocation={() => {
                            setCoords({
                              lat: values.address.lat,
                              lng: values.address.lng,
                            });
                            setMaxMap(false);
                          }}
                        />
                      </div>
                    </div>
                  )}

                  {toggleEditMapBtn && (
                    <div className={`${styles.edit_map_container}`}>
                      <Button
                        variant='secondary'
                        handleClick={() => {
                          setToggleEditMapBtn(false);
                          setMaxMap(true);
                        }}
                      >
                        <p className='mb-0'>{locales_es.edit}</p>
                      </Button>
                    </div>
                  )}
                </div>
              )}

              <div className='d-flex flex-column justify-content-center'>
                {values ? (
                  <Button
                    variant='primary'
                    classes='mx-0 mw-50 fw-light '
                    style={{ letterSpacing: '2px' }}
                    disabled={isSubmitting}
                    handleClick={() => {
                      // setFieldValue('createAnother', false);
                      // handleSubmit();
                      openModal({
                        title: locales_es.success,
                        subTitle: locales_es.successSavingClinic,
                        icon: FORM_HAPPY_ICON,
                        onClose: () => {
                          navigation(-1);
                        },
                      });
                    }}
                  >
                    {locales_es.save}
                  </Button>
                ) : (
                  <>
                    <Button
                      variant='primary'
                      classes='mx-0 mw-50 fw-light '
                      style={{ letterSpacing: '2px' }}
                      disabled={isSubmitting}
                      handleClick={() => {
                        setFieldValue('createAnother', false);
                        handleSubmit();
                      }}
                    >
                      {locales_es.saveAndContinue}
                    </Button>
                    <Button
                      variant='secondary'
                      classes='mx-0 mw-50 fw-light '
                      style={{ letterSpacing: '2px' }}
                      disabled={isSubmitting}
                      handleClick={() => {
                        setFieldValue('createAnother', true);
                        handleSubmit();
                      }}
                    >
                      {locales_es.saveAndCreateAnother}
                    </Button>
                    <Button
                      variant='outline'
                      classes='mx-0 mw-50 fw-light'
                      style={{ letterSpacing: '2px' }}
                      disabled={isSubmitting}
                      handleClick={() => {
                        dispatch(getUser());
                      }}
                    >
                      {locales_es.skipStep}
                    </Button>
                  </>
                )}
              </div>
              <Modal
                openModal={openCropper}
                cancellable
                closeButton
                closeModal={closeImageCropper}
              >
                <ImageCropper
                  imageURL={imageURL as any}
                  closeImageCropper={closeImageCropper}
                  editImage={editImage}
                />
              </Modal>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default BusinessLocationForm;
