import { motion, AnimatePresence } from 'framer-motion';
import styles from './UserMenu.module.scss';
import { Link } from 'react-router-dom';
import routes from 'src/router/routes';

interface Props {
  open: boolean;
  closeMenu: () => void;
  toggleLogoutModal: () => void;
}

export interface MenuIem {
  id: number;
  title: string;
  link: string;
}

const menuItems: MenuIem[] = [
  {
    id: 1,
    title: 'Mi Perfil',
    link: routes.profile_edit,
  },
  {
    id: 2,
    title: 'Borradores',
    link: routes.drafts,
  },
  {
    id: 3,
    title: 'Salir de Medicloud',
    link: routes.logout,
  },
];

const UserMenu = ({ open, closeMenu, toggleLogoutModal }: Props) => {
  return (
    <AnimatePresence>
      {open && (
        <motion.div
          className={`${styles.container}`}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <ul className={`${styles.menu_list}`}>
            {menuItems.map((item: MenuIem) => {
              return (
                <li
                  key={item.id}
                  className={`${styles.menu_item}`}
                  onClick={() => {
                    if (item.link === 'logout') {
                      toggleLogoutModal();
                    }
                    closeMenu();
                  }}
                >
                  {item.link === routes.logout ? (
                    item.title
                  ) : (
                    <Link to={item.link} className={`${styles.menu_item_link}`}>
                      {item.title}
                    </Link>
                  )}
                </li>
              );
            })}
          </ul>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default UserMenu;
