import { useState } from 'react';
import { Tooltip } from 'react-tooltip';
import IconRounded from '../IconRounded';
import { Link } from 'react-router-dom';
import styles from './ListItem.module.scss';
import { PRIMARY_BG_COLOR, PRIMARY_COLOR } from 'src/utils/constants';

import { icon } from 'src/utils/models';

interface ActionProps {
  itemId: string;
}

export interface Action {
  icon: icon;
  link: string;
  classes?: string;
  tooltip: string;
}

export type actionsArrayType = ({ itemId }: ActionProps) => Action[];

interface Props extends Action {
  id: string;
}

const ICON_COLOR = {
  icon: PRIMARY_COLOR,
  bg: 'transparent',
};
const ICON_COLOR_HOVER = {
  icon: PRIMARY_BG_COLOR,
  bg: PRIMARY_COLOR,
};

export const ListAction = ({ icon, link, classes, tooltip, id }: Props) => {
  const [color, setColor] = useState(ICON_COLOR);
  return (
    <div
      className={styles.action}
      onMouseEnter={() => setColor(ICON_COLOR_HOVER)}
      onMouseLeave={() => setColor(ICON_COLOR)}
      style={{ backgroundColor: color.bg }}
    >
      <Link
        data-tooltip-id='my-tooltip-inline'
        data-tooltip-content={tooltip}
        to={link}
      >
        <IconRounded
          size={30}
          icon={icon}
          iconColor={color.icon}
          classes={classes}
          cursor='pointer'
        />
      </Link>

      <Tooltip
        id='my-tooltip-inline'
        style={{
          backgroundColor: ICON_COLOR.icon,
          color: '#ffffff',
          borderRadius: 14,
        }}
        place='bottom'
      />
    </div>
  );
};
