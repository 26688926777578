import React from 'react';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import DynamicForm from 'src/components/DynamicForm';
import {
  useGenders,
  useIdentificationTypes,
  useTimeZone,
} from 'src/queries/queries';
import locales_es from 'src/locales/es.json';
import { REQUIRED } from 'src/utils/forms/login';
import {
  date_of_birth_input,
  emailRepeat_input,
  email_input,
  firstName_input,
  genders_input,
  identification_input,
  identification_type_id_input,
  lastName_input,
  phoneCode_input,
  timeZone_input,
} from 'src/utils/forms/inputs';
import { back_action, submit_action } from 'src/utils/forms/actions';
import routes from 'src/router/routes';
import { useAddPatientMutation } from 'src/queries/mutations';
import { useModalContext } from 'src/contexts/ModalContext';
import { API_ERROR_ICON } from 'src/icons/constants';

const AddPatientForm = () => {
  const { openModal } = useModalContext();
  const navigate = useNavigate();
  const identificationTypes = useIdentificationTypes({
    prevId: null,
  });
  const genders = useGenders({ prevGender: null });
  const timeZone = useTimeZone({ prevTimezone: null });

  const mutation = useAddPatientMutation();
  return (
    <div className='container'>
      <DynamicForm
        inputs={[
          firstName_input,
          lastName_input,
          {
            ...email_input,
          },
          {
            ...emailRepeat_input,
          },
          {
            ...phoneCode_input,
            name: 'cellphone',
            validation: Yup.string().trim().min(7, REQUIRED).required(REQUIRED),
            wrapperClassName: 'd-inline-block col-md-4',
          },
          {
            ...identification_type_id_input,
            options: identificationTypes.data?.data.data,
            wrapperClassName: 'd-inline-block col-md-4',
          },
          {
            ...identification_input,
            wrapperClassName: 'd-inline-block col-md-4',
          },
          { ...date_of_birth_input, label: locales_es.birthDate },
          { ...genders_input, options: genders.data?.data.data },
          {
            ...timeZone_input,
            options: timeZone.data,
            wrapperClassName: 'd-inline-block',
          },
        ]}
        actions={[
          { ...back_action, to: routes.home_patients },
          { ...submit_action, text: locales_es.send },
        ]}
        actionWrapperClassName='d-flex align-items-center justify-content-center  flex-md-row gap-5 mx-auto'
        onSubmit={(values) => {
          mutation.mutate(values, {
            onError: (error: any) => {
              console.log('MURATET ERROR', error);
              openModal({
                title: 'Error',
                subTitle:
                  error.response.data.message ||
                  'Hubo un error creando el paciente',
                cancellable: true,
                icon: API_ERROR_ICON,
              });
            },
            onSuccess: (res) => {
              openModal({
                title: 'Éxitos',
                subTitle: res.data.message || 'Paciente añadido con éxito',
                cancellable: true,
                icon: API_ERROR_ICON,
                onClose: () => navigate(routes.home_patients),
              });
            },
          });
        }}
      />
    </div>
  );
};

export default AddPatientForm;
