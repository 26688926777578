import { Outlet } from 'react-router-dom';
import ScreenDivider from 'src/components/ScreenDivider';

const RootAuth = () => (
  <ScreenDivider>
    <Outlet />
  </ScreenDivider>
);

export default RootAuth;
