import React, { useState } from 'react';
import Calendar from 'react-calendar';
import { Dayjs } from 'dayjs';
import styles from './Calendar.module.scss';
import IconButton from '../IconButton';
import './MiniCalendar.css';
import useWindowSize from 'src/hooks/useWindowSize';

const MiniCalendar = () => {
  const [selectedDay] = useState<Dayjs | null>(null);
  const [month, setMonth] = useState(false);

  const { isDesktop } = useWindowSize();

  return (
    <div
      className={`container-sm p-3 d-flex flex-column justify-content-around ${styles.calendar_container}`}
      style={{ maxWidth: 350 }}
    >
      <div
        className={
          'd-flex align-items-center justify-content-between m-auto justify-content-between w-100 p-2'
        }
      >
        <div className={`${styles.diary_header_topText}`}>
          <p className={`${styles.diary_header_topText_day}`}>
            {selectedDay?.format('DD')} {selectedDay?.format('MMMM')}
          </p>
          <p className={`${styles.diary_header_topText_title}`}>
            Agenda del día
          </p>
        </div>
        <IconButton
          icon='calendar'
          variant='primary'
          iconSize={18}
          buttonSize={40}
          handleClick={() => {
            setMonth(!month);
          }}
        />
      </div>
      <div className='p-2'>
        <Calendar
          showNavigation={false}
          minDetail='month'
          maxDetail='month'
          defaultView='month'
          next2Label={null}
          prev2Label={null}
          showNeighboringMonth={isDesktop}
        />
      </div>
    </div>
  );
};

export default MiniCalendar;
