import React from 'react';
import Lottie from 'react-lottie';
import successAnimation from 'src/assets/lotties/success-check.json';
import failedAnimation from 'src/assets/lotties/failed-cross.json';
import Modal from '../Modal';
import locales_es from 'src/locales/es.json';
import Button from '../Button';
import { STATUS_SUCCESS } from 'src/pages/MyAppointments/consts';

interface IProps {
  acceptAction?: () => void;
  result?: string;
  titleSuccess?: string;
  titleFailed?: string;
}
export default function ModalAnimationResult({
  acceptAction,
  result,
  titleSuccess,
  titleFailed,
}: IProps) {
  const animationSize = 150;

  const animationOptions = {
    loop: false,
    autoplay: true,
    animationData:
      result === STATUS_SUCCESS ? successAnimation : failedAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <Modal openModal cancellable closeButton closeModal={acceptAction}>
      <div className='row'>
        <div className='col text-center'>
          <Lottie
            options={animationOptions}
            height={animationSize}
            width={animationSize}
          />
        </div>
      </div>
      <div className='row justify-content-center'>
        <div className='text-center'>
          {result === STATUS_SUCCESS ? (
            <h4 className='m-3 mt-0'>
              {titleSuccess || '¡Ha salido todo bien!'}
            </h4>
          ) : (
            <h4 className='m-3 mt-0'>
              {titleFailed || '¡Hemos tenido un problema!'}
            </h4>
          )}
        </div>
      </div>
      <div className='row justify-content-center'>
        <Button variant='primary' handleClick={acceptAction}>
          {locales_es.accept}
        </Button>
      </div>
    </Modal>
  );
}
