import { FaUserDoctor, FaUserInjured, FaHouseMedical } from 'react-icons/fa6';
import routes from 'src/router/routes';
import IconCard from 'src/components/IconCard';
import locales_es from 'src/locales/es.json';

export const registerTypes = [
  {
    type: 'medic',
    title: locales_es.medics,
    text: locales_es.registerMedicText,
    link: `${routes.auth_register_new}?type=medic`,
    footer: 'Registrarme como médico',
    bg: '#9816f4',
    footerBg: '#880be1',
    icon: <FaUserDoctor size={'10%'} />,
  },
  {
    type: 'patient',
    title: locales_es.patients,
    text: locales_es.registerPatientText,
    link: `${routes.auth_register_new}?type=patient`,
    footer: 'Registrarme como paciente',
    bg: '#5578eb',
    footerBg: '#3a62e8',
    icon: <FaUserInjured size={'10%'} />,
  },
  {
    type: 'clinic',
    title: locales_es.medicalCenters,
    text: locales_es.registerClinicText,
    link: 'https://forms.gle/UJ15igr8fcCVgZX19',
    footer: 'Registrarme como clínica',
    bg: '#1cac81',
    footerBg: '#18926d',
    icon: <FaHouseMedical size={'10%'} />,
  },
];

const Register = () => {
  return (
    <>
      {registerTypes.map((registerType) => (
        <IconCard data={{ ...registerType }} key={registerType.text} />
      ))}
    </>
  );
};

export default Register;
