import { useState } from 'react';
import { useNavigate } from 'react-router';
import DynamicForm from 'src/components/DynamicForm';
import styles from './EditProfileScreen.module.scss';
import routes from 'src/router/routes';
import { useModalContext } from 'src/contexts/ModalContext';
import {
  useGenders,
  useIdentificationTypes,
  useTimeZone,
  useUserMeData,
} from 'src/queries/queries';
import { useUpdateUserMutation } from 'src/queries/mutations';
import { ClipLoader } from 'react-spinners';
import editProfilePatientForm from 'src/utils/forms/editProfilePatient';
import { useEffect } from 'react';
import {
  parseAPIStringToDate,
  parseDateToConventionalAPIString,
  parseStringDateToAPIStringDate,
} from 'src/utils/date';
import TelephoneValidation from 'src/components/TelephoneValidation';

const EditPatientProfileForm = () => {
  const { data: userData, refetch } = useUserMeData({ enabled: true });
  const user = userData?.data?.data?.user;
  const [inputs, setInputs] = useState<any[] | null>(null);
  const navigate = useNavigate();
  const { openModal } = useModalContext();

  const identificationTypes = useIdentificationTypes({
    prevId: user?.identification_type_id,
  });
  const genders = useGenders({ prevGender: user?.gender_id });
  const timeZone = useTimeZone({ prevTimezone: user?.time_zone });

  const mutation = useUpdateUserMutation();

  useEffect(() => {
    const user = userData?.data?.data?.user;
    if (
      identificationTypes.isSuccess &&
      genders.isSuccess &&
      timeZone.isSuccess &&
      user
    ) {
      setInputs(
        editProfilePatientForm.inputs.map((input) => {
          if (input.name === 'email') {
            input.initialValue = user.email || '';
          }
          if (input.name === 'name') {
            input.initialValue = user.name || '';
          }
          if (input.name === 'lastname') {
            input.initialValue = user.lastname || '';
          }
          if (input.name === 'identification') {
            input.initialValue = user.identification || '';
          }
          if (
            input.type === 'select' &&
            input.name === 'identification_type_id'
          ) {
            input.options = identificationTypes.data?.data.data;
            input.initialValue = user.identification_type_id;
            input.initialValueName =
              identificationTypes.data?.data.data?.find(
                (type: any) => type.id === user.identification_type_id
              )?.name ?? '';
          }
          if (input.type === 'select' && input.name === 'gender_id') {
            input.options = genders.data?.data.data;
            input.initialValue = user.gender_id;
            input.initialValueName =
              genders.data?.data.data?.find(
                (gender: any) => gender.id === user.gender_id
              )?.name ?? '';
          }
          if (input.type === 'select' && input.name === 'time_zone') {
            input.options = timeZone.data;
            input.initialValue = user.time_zone;
            input.initialValueName = user.time_zone ?? '';
          }

          if (input.name === 'cellphone') {
            input.initialValue = user.cellphone;
            input.children = (
              <TelephoneValidation
                cellphone={user.cellphone}
                isValid={!!user.is_cellphone_validated}
                refetch={refetch}
              />
            );
          }
          if (input.type === 'date') {
            input.initialValue = parseAPIStringToDate(user.date_of_birth);
          }
          return input;
        })
      );
    }
  }, [
    genders.data?.data.data,
    genders.isSuccess,
    identificationTypes.data?.data.data,
    identificationTypes.isSuccess,
    timeZone.data,
    timeZone.isSuccess,
    userData?.data.data,
    refetch,
  ]);

  if (
    identificationTypes.isLoading ||
    genders.isLoading ||
    timeZone.isLoading ||
    !inputs
  )
    return <ClipLoader />;

  return (
    <div className={styles.formContainer}>
      <DynamicForm
        inputs={inputs}
        actions={editProfilePatientForm.actions}
        onSubmit={(values) => {
          const formattedValues = {
            ...values,
            date: parseStringDateToAPIStringDate(
              parseDateToConventionalAPIString(values.date_of_birth)
            ),
          };
          mutation.mutate(formattedValues, {
            onError: () => {
              openModal({
                title: 'Error',
                subTitle: 'Ha habido un error',
                onClose: () => navigate(routes.home),
              });
              refetch();
            },
            onSuccess: () => {
              refetch();
              openModal({
                title: 'Exito',
                subTitle: 'Sus datos se han actualizado',
                onClose: () => navigate(routes.home),
              });
            },
          });
        }}
        actionWrapperClassName='d-flex align-items-center justify-content-center'
      />
    </div>
  );
};

export default EditPatientProfileForm;
