import { NavLink } from 'react-router-dom';
import { clsx } from 'clsx';
import styles from './Navbar.module.scss';

interface Props {
  classes?: string;
  mobile?: boolean;
  style?: React.CSSProperties;
  closeMenu?: () => void;
  items: { to: string; icon: string; label: string }[];
}
const Navbar = ({
  classes = '',
  mobile = false,
  style = {},
  closeMenu = () => {},
  items,
}: Props) => {
  return (
    <nav className={`${styles.nav} container-fluid d-flex `} style={style}>
      <ul
        className={`container ${classes} ${
          mobile ? styles.mobile_menu : styles.menu
        }`}
      >
        {items.map((item, i) => (
          <li
            className={clsx(styles.menuItem, mobile && styles.mobile_menuItem)}
            key={item.label}
          >
            <NavLink
              to={item.to}
              className={({ isActive }) =>
                isActive
                  ? `${styles.active} ${styles[item.icon]}`
                  : styles[item.icon]
              }
              onClick={closeMenu}
            >
              {item.label}
            </NavLink>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Navbar;
