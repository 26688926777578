import { motion, Variants } from 'framer-motion';
import Navbar from '../Navbar';
import { UserInfo } from '../HomeHeader/UserInfo/UserInfo';
import styles from './MobileMenu.module.scss';
import { HeaderProps } from '../HomeHeader';
import { UserData } from 'src/app/slices/userSlice';
import {
  MEDIC_MENU_ITEMS,
  PATIENT_MENU_ITEMS,
  USER_TYPE_PATIENT,
} from 'src/utils/constants';

interface Props extends HeaderProps {
  closeMenu: () => void;
  userData?: UserData | null;
}

const MOBILE_AVATAR_SIZE = 100;

const MobileMenu = ({ closeMenu, toggleLogoutModal, userData }: Props) => {
  const variants: Variants = {
    visible: {
      x: 'calc(100vw - 100%)',
      transition: { type: 'tween', duration: 0.4 },
    },
    hidden: { x: '100%', transition: { type: 'tween', duration: 0.4 } },
  };
  return (
    <motion.div
      className={`d-flex ${styles.fullscreen_menu_container} `}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className='w-25 h-100' onClick={closeMenu} />

      <motion.div
        initial='hidden'
        animate='visible'
        variants={variants}
        exit='hidden'
        className={`d-flex flex-column w-75 h-100 ${styles.container}`}
      >
        <UserInfo
          avatarSize={MOBILE_AVATAR_SIZE}
          name={`${userData?.name ?? ''} ${userData?.lastname ?? ''}`}
          toggleLogoutModal={toggleLogoutModal}
        />
        <div className={`${styles.nav_container}`}>
          <Navbar
            mobile
            closeMenu={closeMenu}
            items={
              userData?.user_type === USER_TYPE_PATIENT
                ? PATIENT_MENU_ITEMS
                : MEDIC_MENU_ITEMS
            }
          />
        </div>
      </motion.div>
    </motion.div>
  );
};

export default MobileMenu;
