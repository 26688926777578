import { useState } from 'react';
import {
  ViewState,
  AppointmentModel,
  EditingState,
  IntegratedEditing,
  TodayButton,
} from '@devexpress/dx-react-scheduler';
import {
  Scheduler,
  DayView,
  WeekView,
  Appointments,
  AppointmentTooltip,
  AppointmentForm,
  ConfirmationDialog,
  ViewSwitcher,
  DateNavigator,
  Toolbar,
  DragDropProvider,
  Resources,
} from '@devexpress/dx-react-scheduler-material-ui';
import Button from '../Button';
import { Select } from '../FormInput/Select';
import IconButton from '../IconButton';
import {
  Appointment,
  CommandButton,
  DateEditor,
  DividerLabel,
  FormOverlay,
  FormSelect,
  TextInput,
  AppointmentTooltipContent,
  CustomToolbar,
  DayCell,
  DayLayout,
  TimeCell,
  TimeNavigator,
  WeekdayLayout,
  FormConfirmationDialog,
  ConfirmationButton,
} from './CustomComponents';
import styles from './CustomComponents/CustomComponents.module.scss';

interface ExtendenAppointment extends AppointmentModel {
  id: number;
}
const schedulerData: ExtendenAppointment[] = [
  {
    id: 0,
    startDate: '2023-01-24T09:45',
    endDate: '2023-01-24T11:00',
    title: 'Pedro Gonzalez',
  },
  {
    id: 1,
    startDate: '2023-01-24T12:00',
    endDate: '2023-01-24T13:30',
    title: 'Ramiro Bono',
  },
];

const TODAY = new Date();

export const CustomScheduler = () => {
  const [appointments, setAppointments] = useState(schedulerData);
  const [currentDate, setCurrentDate] = useState(TODAY);

  const commitChanges = ({ added, changed, deleted }: any) => {
    if (added) {
      const startingAddedId =
        appointments.length > 0
          ? appointments[appointments.length - 1].id + 1
          : 0;
      setAppointments([...appointments, { id: startingAddedId, ...added }]);
    }
    if (changed) {
      setAppointments(
        appointments.map((appointment: any) =>
          changed[appointment.id]
            ? { ...appointment, ...changed[appointment.id] }
            : appointment
        )
      );
    }
    if (deleted !== undefined) {
      setAppointments(
        appointments.filter((appointment) => appointment.id !== deleted)
      );
    }
    return appointments;
  };
  return (
    <div className='container w-100 '>
      <Scheduler data={appointments} locale='es-US'>
        <ViewState
          defaultCurrentDate={currentDate}
          currentDate={currentDate}
          defaultCurrentViewName='Week'
          onCurrentDateChange={(currentDate) => setCurrentDate(currentDate)}
        />
        <DayView
          startDayHour={9}
          endDayHour={14}
          displayName='Día'
          dayScaleCellComponent={DayCell}
          layoutComponent={DayLayout}
        />
        <WeekView
          startDayHour={9}
          endDayHour={14}
          timeTableCellComponent={TimeCell}
          layoutComponent={WeekdayLayout}
          dayScaleCellComponent={DayCell}
          displayName='Semana'
        />
        <Toolbar rootComponent={CustomToolbar} />
        <ViewSwitcher
          switcherComponent={(switcher) => (
            <Select
              handleBlur={() => {}}
              list={switcher.availableViews.map((view, i) => ({
                ...view,
                id: view.name,
                name: view.displayName,
              }))}
              variant='primary'
              name='switch'
              label=''
              isForm={false}
              classes='w-50'
              onChange={switcher.onChange}
              style={{ maxWidth: 200 }}
            />
          )}
        />
        <DateNavigator
          rootComponent={TimeNavigator}
          navigationButtonComponent={(nav) => (
            <IconButton
              icon={nav.type === 'back' ? 'arrowLeft' : 'arrowRight'}
              iconSize={40}
              variant='primary'
              buttonSize={40}
              handleClick={nav.onClick}
              classes={`${
                nav.type === 'back' ? styles.nav_back : styles.nav_forward
              }`}
            />
          )}
          openButtonComponent={(nav) => (
            <div className={`${styles.date_nav_text_container}`}>
              <p className={`${styles.date_nav_text}`}>{nav.text}</p>
            </div>
          )}
        />
        <TodayButton
          buttonComponent={() => (
            <Button
              variant='outline'
              handleClick={() => {
                setCurrentDate(TODAY);
              }}
            >
              HOY
            </Button>
          )}
        ></TodayButton>
        <EditingState onCommitChanges={commitChanges} />
        <IntegratedEditing />
        <ConfirmationDialog
          overlayComponent={FormConfirmationDialog}
          buttonComponent={ConfirmationButton}
        />
        <Appointments appointmentComponent={Appointment} />
        <AppointmentTooltip
          showOpenButton
          showDeleteButton
          showCloseButton
          contentComponent={AppointmentTooltipContent}
        />
        <AppointmentForm
          resourceEditorComponent={FormSelect}
          commandButtonComponent={CommandButton}
          textEditorComponent={TextInput}
          commandLayoutComponent={(props) => (
            <AppointmentForm.CommandLayout
              {...props}
              style={{ justifyContent: 'space-between' }}
            />
          )}
          labelComponent={DividerLabel}
          dateEditorComponent={DateEditor}
          overlayComponent={FormOverlay}
        />
        <Resources
          data={[
            {
              fieldName: 'medic_schedule',
              title: 'Turno',
              instances: [
                { id: 'attended', text: 'Atendido', color: '#7ebc7a' },
                { id: 'spontaneus', text: 'Espontáneo', color: '#c782dd' },
                { id: 'reserved', text: 'Reservado', color: '#8293dd' },
                { id: 'absent', text: 'Ausente', color: '#e8e359' },
                { id: 'canceled', text: 'Cancelado', color: '#e94949' },
                { id: 'double', text: 'Doble Turno', color: '#446242' },
              ],
            },
          ]}
        />
        <DragDropProvider />
      </Scheduler>
    </div>
  );
};
