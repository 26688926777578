import useWindowSize from 'src/hooks/useWindowSize';
import SectionTitle from 'src/components/SectionTitle';
import { MyPatientsMobile, MyPatientsDesktop } from 'src/components/MyPatients';

const Patients = () => {
  const { isMobile } = useWindowSize();

  return (
    <>
      <SectionTitle
        sectionText={{ textTitle: 'Mis pacientes', textSubtitle: '' }}
        userText={{ textSubtitle: '', textTitle: '' }}
        showAvatar={false}
        endLine={false}
      />
      {isMobile ? <MyPatientsMobile /> : <MyPatientsDesktop />}
    </>
  );
};

export default Patients;
