import useWindowSize from 'src/hooks/useWindowSize';
import Button from '../Button';
import styles from './Map.module.scss';
import { MapInfoBox } from './MapInfoBox';

interface Props {
  confirmLocation: () => void;
}

const MOBILE_BTN_STYLES = {
  backgroundColor: 'transparent',
  color: '#a3cef7',
  borderColor: '#a3cef7',
};

export const MapConfirmBox = ({ confirmLocation }: Props) => {
  const { isDesktop } = useWindowSize();
  return (
    <div
      className={`${
        isDesktop
          ? styles.map_confirm_container
          : styles.map_confirm_container_mobile
      }`}
    >
      {!isDesktop && (
        <MapInfoBox
          title='Confirme su dirección'
          subTitle='Mueva el PIN para ajustar la ubicación deseada'
          closeMap={() => {}}
        />
      )}
      <Button
        variant='outline'
        handleClick={confirmLocation}
        style={isDesktop ? {} : MOBILE_BTN_STYLES}
      >
        <p className='mb-0'>Confirmar lugar de atención</p>
      </Button>
    </div>
  );
};
