import { Toolbar } from '@devexpress/dx-react-scheduler-material-ui';
import styles from './CustomComponents.module.scss';

export const CustomToolbar = (props: any) => {
  return (
    <Toolbar.Root {...props} className={`${styles.custom_toolbar_root}`}>
      {props.children}
    </Toolbar.Root>
  );
};
