import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { loadingSlice } from './slices/loadingSlice';
import { userSlice } from './slices/userSlice';
import { dockSlice } from './slices/dockSlice';
import type { PreloadedState } from '@reduxjs/toolkit';

export const rootReducer = combineReducers({
  loading: loadingSlice.reducer,
  user: userSlice.reducer,
  dock: dockSlice.reducer,
});

export const setupStore = (preloadedState?: PreloadedState<RootState>) => {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
  });
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
