import DynamicForm from 'src/components/DynamicForm';
import { FullScreenLoader } from 'src/components/Loaders/FullScreenLoader';
import Modal from 'src/components/Modal';
import LinkTo from 'src/components/LinkTo';
import { Navigate, useNavigate } from 'react-router-dom';
import routes from 'src/router/routes';
import registerMedicForm from 'src/utils/forms/registerMedic';
import registerPatientForm from 'src/utils/forms/registerPatient';
import registerClinicForm from 'src/utils/forms/registerClinic';
import locales_es from 'src/locales/es.json';
import { useModalContext } from 'src/contexts/ModalContext';
import { setAuthToken } from 'src/utils/auth';
import { useAppDispatch } from 'src/app/hooks';
import { setUserData } from 'src/app/slices/userSlice';
import {
  useGenders,
  useIdentificationTypes,
  useSpecialties,
  useTimeZone,
} from 'src/queries/queries';
import { useRegisterMutation } from 'src/queries/mutations';
import { FormActionButton, FormActionLink } from 'src/utils/models';

export interface Props {
  userType: userTypesType;
}

export type userTypesType = 'medic' | 'patient' | 'clinic';

const userTypes = {
  medic: registerMedicForm,
  patient: registerPatientForm,
  clinic: registerClinicForm,
};

let parsedForm = registerPatientForm;

export const RegisterNewForm = ({ userType }: Props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { openModal } = useModalContext();

  const identificationTypes = useIdentificationTypes({ prevId: null });
  const genders = useGenders({ prevGender: null });
  const timeZone = useTimeZone({ prevTimezone: null });
  const specialties = useSpecialties({ userType, prevSpecialty: undefined });

  const onSubmit = useRegisterMutation(userType);

  if (!Object.keys(userTypes).includes(userType)) {
    return <Navigate to={routes.auth_register} />;
  }

  if (
    identificationTypes.isSuccess &&
    genders.isSuccess &&
    timeZone.isSuccess
  ) {
    parsedForm = {
      ...userTypes[userType as userTypesType],
      inputs: userTypes[userType as userTypesType].inputs.map((input) => {
        if (
          input.type === 'select' &&
          input.name === 'identification_type_id' &&
          timeZone.isSuccess
        ) {
          input.options = identificationTypes.data?.data.data;
        }
        if (input.type === 'select' && input.name === 'gender_id') {
          input.options = genders.data?.data.data;
        }
        if (input.type === 'select' && input.name === 'time_zone') {
          input.options = timeZone.data;
        }
        return input;
      }),
    };
  }

  if (userType === 'medic' && specialties.data) {
    parsedForm = {
      ...userTypes[userType as userTypesType],
      inputs: userTypes[userType as userTypesType].inputs.map((input) => {
        if (input.type === 'select' && input.name === 'specialty_id') {
          input.options = specialties.data?.data.data;
        }
        return input;
      }),
    };
  }

  return (
    <>
      {identificationTypes.isLoading ||
        specialties.isLoading ||
        genders.isLoading ||
        (timeZone.isLoading && (
          <FullScreenLoader
            loader={{ show: true, text: 'Loading...', opacity: 0.5 }}
          />
        ))}
      {identificationTypes.isError ||
        specialties.isError ||
        genders.isError ||
        (timeZone.isError && (
          <Modal openModal title={'Error'}>
            <LinkTo
              to={routes.auth_login}
              text={locales_es.accept}
              classes='mx-0 mw-50 fw-light'
            />
          </Modal>
        ))}
      <DynamicForm
        inputs={parsedForm.inputs}
        actions={parsedForm.actions as (FormActionButton | FormActionLink)[]}
        actionWrapperClassName={parsedForm.actionWrapperClassName}
        onSubmit={(values) =>
          onSubmit.mutate(values, {
            onSuccess: (data) => {
              const token = data.data.data.token;
              setAuthToken(token);
              const user = data.data.data.user;
              dispatch(setUserData({ user }));
              openModal({
                title: 'Felicidades',
                subTitle: 'Su cuenta ha sido creada con exito',
                onClose: () => navigate(routes.home),
              });
            },
            onError: (error: any) => {
              openModal({
                title: 'Error',
                subTitle: error.message || 'Ha habido un error',
                onClose: null,
              });
            },
          })
        }
      />
    </>
  );
};
