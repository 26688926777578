import { useEffect } from 'react';
import { useLoadScript } from '@react-google-maps/api';
import { icon } from 'src/utils/models';
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from 'use-places-autocomplete';
import styles from '../FormInput/FormInput.module.scss';
import { icons } from '../IconButton/IconDictionary';

const RADIUS = 24;

type variant =
  | 'primary'
  | 'secondary'
  | 'grey'
  | 'outline'
  | 'purple'
  | 'disabled';

interface Props {
  placeholder: string;
  iconLeft: icon;
  variant: variant;
  addressStreet: string;
  addressNumber: string;
  setSelected: ({
    description,
    lat,
    lng,
  }: {
    description: string;
    lat: number;
    lng: number;
  }) => void;
}

const PLACES_API_KEY: string = process.env.REACT_APP_PLACES_API_KEY || '';
const libraries: 'places'[] = ['places'];
const region = 'AR';

const SearchPlaces = ({
  placeholder,
  iconLeft,
  setSelected,
  variant,
  addressStreet,
  addressNumber,
}: Props) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: PLACES_API_KEY,
    libraries: libraries,
    region: region,
  });

  return (
    <>
      {isLoaded && (
        <PlacesAutocomplete
          placeholder={placeholder}
          iconLeft={iconLeft}
          setSelected={setSelected}
          variant={variant}
          addressStreet={addressStreet}
          addressNumber={addressNumber}
        />
      )}
    </>
  );
};

const PlacesAutocomplete = ({
  placeholder,
  iconLeft,
  setSelected,
  variant,
  addressStreet,
  addressNumber,
}: Props) => {
  const {
    setValue,
    suggestions: { status, data },
    clearSuggestions,
  } = usePlacesAutocomplete();

  useEffect(() => {
    if (addressStreet && addressNumber) {
      setValue(`${addressStreet} ${addressNumber}`);
    } else {
      setValue('', false);
      clearSuggestions();
    }
  }, [addressStreet, addressNumber]);

  const handleSelect = async (address: any) => {
    setValue(address.description, false);
    clearSuggestions();

    const results = await getGeocode({ address: address.description });
    const { lat, lng } = await getLatLng(results[0]);
    const description = address.description;
    setSelected({ description, lat, lng });
  };

  return (
    <>
      <div
        className={`${styles.extendedTagInput_Suggestions}  ${styles.simpleField} ${styles[variant]} d-flex justify-content-between `}
        style={{
          position: 'relative',
          borderBottomLeftRadius: status === 'OK' ? 0 : RADIUS,
          borderBottomRightRadius: status === 'OK' ? 0 : RADIUS,
          borderBottomWidth: status === 'OK' && variant === 'outline' ? 0 : 1,
          bottom: 40,
          visibility: 'hidden',
          height: 0,
        }}
      >
        <div style={{ visibility: 'visible' }}>
          {status === 'OK' && (
            <ul className={`${styles.selectList} ${styles.selectList_borders}`}>
              {data.map((item, i) => (
                <li
                  key={item.place_id}
                  onClick={() => handleSelect(item)}
                  data-testid={`${item.description}-${i}`}
                  className='d-flex align-items-center justify-content-start'
                >
                  <div className={`${styles.icon_container_gps} me-2`}>
                    <i className={`${styles.icon_left}`}>{icons.mapMarker}</i>
                  </div>
                  {item.description}
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </>
  );
};

export default SearchPlaces;
