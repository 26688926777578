export const queries = {
  identificationTypes: 'identificationTypes',
  genders: 'genders',
  timezone: 'timezone',
  specialties: 'specialties',
  userPaymentMethods: 'userPaymentMethods',
  paymentMethods: 'paymentMethods',
  bankTransferData: 'bankTransferData',
  amountTypes: 'amountTypes',
  userMeData: 'userMeData',
  patients: 'patients',
  patient: 'patient',
  patientsInfinite: 'patientsInfinite',
  myClinics: 'myClinics',
  searchPatients: 'searchPatients',
  myAppointments: 'myAppointments',
  myFutureAppointments: 'myFutureAppointments',
  myPastAppointments: 'myPastAppointments',
  medicalInfo: 'medicalInfo',
  medicalRecord: 'medicalRecord',
  myMedics: 'myMedics',
};
