import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import * as Yup from 'yup';
import Avatar from 'src/components/Avatar';
import Card from 'src/components/Card';
import SectionTitle from 'src/components/SectionTitle';
import { useMedicalInfo, usePatientData } from 'src/queries/queries';
import { FiMail, FiEdit, FiPlusSquare } from 'react-icons/fi';
import { IoLogoWhatsapp } from 'react-icons/io';
import { ClipLoader } from 'react-spinners';
import locales_es from '../../locales/es.json';
import styles from './PatientProfile.module.scss';
import clsx from 'clsx';
import Button from 'src/components/Button';
import Modal from 'src/components/Modal';
import DynamicForm from 'src/components/DynamicForm';
import { submit_action } from 'src/utils/forms/actions';
import {
  useDeleteMedicalrecordMutation,
  useEditPatientMedicalProfileMutation,
  useMedicalrecordMutation,
} from 'src/queries/mutations';
import { useModalContext } from 'src/contexts/ModalContext';
import { queryClient } from 'src';
import { queries } from 'src/queries/constants';
import MedicalRecord, { Record } from 'src/components/MedicalRecord';
import { NUMBER, REQUIRED } from 'src/utils/forms/login';
import { useInfiniteQuery } from '@tanstack/react-query';
import { getMedicalRecordsByPatient } from 'src/services/apiService';
import InfiniteScroll from 'react-infinite-scroll-component';
import { renderAge } from 'src/utils/date';
import Navbar from 'src/components/Navbar';
import routes from 'src/router/routes';

const PatientProfile = () => {
  const [editMedicalProfile, setEditMedicalProfile] = useState(false);
  const [addMedicalRecord, setAddMedicalRecord] = useState(false);
  const [medicalRecord, setMedicalRecord] = useState<any>([]);
  const { patientId } = useParams();
  const { data: patientData, isLoading: patientDataLoading } = usePatientData({
    id: patientId || '',
  });
  const patient = patientData?.data.data;

  const { data: medicalInfoData, isLoading: medicalInfoDataLoading } =
    useMedicalInfo({ id: patientId || '' });
  const medicalInfo = medicalInfoData?.data.data;

  const {
    data: medicalRecordData,
    isLoading: medicalRecordDataLoading,
    fetchNextPage: fetchNextRecords,
    hasNextPage: hasNextPageRecords,
  } = useInfiniteQuery(
    [queries.medicalRecord, patientId],
    async ({ pageParam = 1 }) => {
      if (pageParam === null) return;
      const res = await getMedicalRecordsByPatient(patientId, pageParam);
      return {
        data: res.data,
        nextItem: pageParam + 1,
        lastPage: res.data.last_page,
      };
    },
    {
      getNextPageParam: (lastPage: any) => {
        if (lastPage?.nextItem > lastPage.lastPage) return;
        return lastPage?.nextItem || null;
      },
    }
  );

  useEffect(() => {
    if (medicalRecordData?.pages.length) {
      setMedicalRecord(
        medicalRecordData?.pages.reduce((acc, value) => {
          return acc.concat(value?.data?.data);
        }, [])
      );
    } else {
      setMedicalRecord([]);
    }
  }, [medicalRecordData]);

  const updateMedicalProfile = useEditPatientMedicalProfileMutation();
  const createMedicalRecord = useMedicalrecordMutation();
  const deleteMedicalRecord = useDeleteMedicalrecordMutation();

  const { openModal } = useModalContext();

  const handleDeleteMedicalRecord = (recordId: number) => {
    openModal({
      title: 'Eliminar evolución de la historia clínica',
      cancellable: true,
      closeButton: false,
      actions: [
        {
          handleClick: () =>
            deleteMedicalRecord.mutate(
              { id: recordId },
              {
                onSuccess: async () => {
                  openModal({
                    title: 'Registro eliminado correctamente',
                    cancellable: true,
                    closeButton: false,
                    actions: [
                      {
                        handleClick: null,
                        text: locales_es.accept,
                        variant: 'patient',
                      },
                    ],
                  });
                  await queryClient.invalidateQueries([
                    queries.medicalRecord,
                    patientId,
                  ]);
                },
                onError: async () => {
                  openModal({
                    title:
                      'Hubo un error eliminando el registro. Por favor, intente nuevamente en unos minutos.',
                    cancellable: true,
                    closeButton: false,
                    actions: [
                      {
                        handleClick: null,
                        text: locales_es.accept,
                        variant: 'patient',
                      },
                    ],
                  });
                  await queryClient.invalidateQueries([
                    queries.medicalRecord,
                    patientId,
                  ]);
                },
              }
            ),
          text: locales_es.accept,
          variant: 'clinic',
        },
        {
          handleClick: null,
          text: locales_es.cancel,
          variant: 'patient',
        },
      ],
    });
  };

  return patientDataLoading ? (
    <ClipLoader />
  ) : (
    <div className={styles.overflowXHidden}>
      <SectionTitle
        sectionText={{
          textTitle: locales_es.patientProfile,
          textSubtitle: `${patient?.name || ''} ${patient?.lastname || ''}`,
        }}
        userText={{ textSubtitle: '', textTitle: '' }}
        showAvatar={false}
        endLine={false}
      />
      <>
        <Card variant='primary' classes='m-2'>
          <div className='d-flex flex-column flex-sm-row justify-content-between p-0 border-bottom'>
            <div className='d-flex align-items-center'>
              <Avatar
                size={60}
                imageUrl={patient?.full_profile_image}
                variant='grey'
              />

              <div className='d-flex align-items-center justify-content-start'>
                <div className='p-3'>
                  <p className='m-0'>
                    {patient.name || ''} {patient.lastname || ''}
                  </p>
                  <p className='m-0 text-muted'>
                    {locales_es.age}: {patient?.date_of_birth}
                  </p>
                  <p className='m-0 text-muted'>
                    {locales_es.age}: {renderAge(patient?.date_of_birth, false)}
                  </p>
                  <p className='m-0 text-muted'>
                    {locales_es.birthDate}:{' '}
                    {dayjs(patient?.date_of_birth).format('DD-MM-YYYY')}
                  </p>
                </div>
              </div>
            </div>
            <div className='d-flex flex-column align-items-start align-items-sm-end justify-content-center ps-2 ps-sm-0'>
              {patient.user?.email && (
                <div>
                  <FiMail /> {patient.user?.email}
                </div>
              )}

              {patient.user?.cellphone && (
                <div>
                  <IoLogoWhatsapp /> {patient.user?.cellphone}
                </div>
              )}
            </div>
          </div>

          <div>
            <Navbar
              items={[
                {
                  icon: 'address-book',
                  label: locales_es.medicalRecord,
                  to: `${routes.home_patients}/${patientId}/historiaClinica`,
                },
                {
                  icon: 'message',
                  label: locales_es.messages,
                  to: `${routes.home_patients}/${patientId}/mensajes`,
                },
              ]}
            />
          </div>
        </Card>
        <div className='row p-2'>
          <div className='col-12 col-md-4'>
            <Card variant='primary'>
              {medicalInfoDataLoading ? (
                <ClipLoader />
              ) : (
                <>
                  <div className='container fluid border-bottom d-flex align-items-center justify-content-between'>
                    <div className='h5'>{locales_es.medicalProfile}</div>
                    <Button
                      variant='medic'
                      style={{ padding: 0 }}
                      handleClick={() => setEditMedicalProfile(true)}
                    >
                      <FiEdit size={20} />
                    </Button>
                  </div>

                  <div className='container p-3'>
                    <div>
                      <p className={clsx('h6', styles.success)}>
                        <strong>{locales_es.prevDiseases}</strong>
                      </p>
                      <p className='text-muted'>
                        {medicalInfo.pre_existing_conditions ||
                          locales_es.noData}
                      </p>
                    </div>
                    <div>
                      <p className={clsx('h6', styles.danger)}>
                        <strong>{locales_es.chronicMedication}</strong>
                      </p>
                      <p className='text-muted'>
                        {medicalInfo.chronic_medication || locales_es.noData}
                      </p>
                    </div>
                    <div>
                      <p className={clsx('h6', styles.warning)}>
                        <strong>{locales_es.habits}</strong>
                      </p>
                      <p className='text-muted'>
                        {medicalInfo.habits || locales_es.noData}
                      </p>
                    </div>
                    <div>
                      <p className={clsx('h6', styles.info)}>
                        <strong>{locales_es.familyBackground}</strong>
                      </p>
                      <p className='text-muted'>
                        {medicalInfo.family_background || locales_es.noData}
                      </p>
                    </div>
                  </div>
                  <div className='d-flex align-items-center justify-content-end'>
                    <div>
                      <p className='h6 '>
                        <strong>{locales_es.lastUpdate}</strong>
                      </p>
                      <p className='success'>
                        {`${dayjs(medicalInfo.updated_at).format(
                          'D MMM YYYY HH:mm'
                        )}hs` || locales_es.noData}
                      </p>
                    </div>
                  </div>

                  <Modal
                    openModal={editMedicalProfile}
                    closeModal={() => setEditMedicalProfile(false)}
                    cancellable
                    closeButton
                    title='Editar perfil médico'
                  >
                    <DynamicForm
                      actionWrapperClassName='d-flex align-items-center justify-content-center'
                      inputs={[
                        {
                          type: 'textArea',
                          name: 'pre_existing_conditions',
                          label: 'Enfermedades preexistentes',
                          placeholder: locales_es.noData,
                          variant: '',
                          validation: Yup.string(),
                          wrapperClassName: 'd-block',
                          dataCy: 'configs_publicPhone_textField',
                          initialValue:
                            medicalInfo.pre_existing_conditions || '',
                        },
                        {
                          type: 'textArea',
                          name: 'chronic_medication',
                          label: 'Medicación crónica',
                          placeholder: locales_es.noData,
                          variant: '',
                          validation: Yup.string(),
                          wrapperClassName: 'd-block',
                          dataCy: 'configs_publicPhone_textField',
                          initialValue: medicalInfo.chronic_medication || '',
                        },
                        {
                          type: 'textArea',
                          name: 'habits',
                          label: 'Hábitos',
                          placeholder: locales_es.noData,
                          variant: '',
                          validation: Yup.string(),
                          wrapperClassName: 'd-block',
                          dataCy: 'configs_publicPhone_textField',
                          initialValue: medicalInfo.habits || '',
                        },
                        {
                          type: 'textArea',
                          name: 'family_background',
                          label: 'Antecedentes familiares',
                          placeholder: locales_es.noData,
                          variant: '',
                          validation: Yup.string(),
                          wrapperClassName: 'd-block',
                          dataCy: 'configs_publicPhone_textField',
                          initialValue: medicalInfo.family_background || '',
                        },
                      ]}
                      actions={[{ ...submit_action, text: locales_es.send }]}
                      onSubmit={(values) => {
                        updateMedicalProfile.mutate(
                          { ...values, patient_id: patientId },
                          {
                            onSuccess: async (res) => {
                              await queryClient.invalidateQueries([
                                queries.medicalInfo,
                                patientId,
                              ]);
                              setEditMedicalProfile(false);
                              openModal({
                                title: 'Éxitos',
                                subTitle: res.data.message,
                                closeButton: true,
                                cancellable: true,
                              });
                            },
                          }
                        );
                      }}
                    />
                  </Modal>
                </>
              )}
            </Card>
          </div>
          <div className='col-12 col-md-8 mt-3 mt-md-0'>
            <Card variant='primary'>
              <div className='container fluid border-bottom d-flex align-items-center justify-content-between'>
                <div className='h5'>Historia Clínica</div>
                <Button
                  variant='medic'
                  style={{ padding: 0 }}
                  handleClick={() => setAddMedicalRecord(true)}
                >
                  <FiPlusSquare size={20} />
                </Button>
              </div>
              {medicalRecordDataLoading ? (
                <ClipLoader />
              ) : medicalRecord.length ? (
                <InfiniteScroll
                  loader={<ClipLoader />}
                  dataLength={medicalRecord.length}
                  hasMore={hasNextPageRecords || false}
                  next={() => fetchNextRecords()}
                  className={clsx(styles.infiniteScroll)}
                >
                  {medicalRecord.map((record: Record) => (
                    <MedicalRecord
                      record={record}
                      handleDeleteMedicalRecord={handleDeleteMedicalRecord}
                      key={record.created_at.toString()}
                    />
                  ))}
                  <p className='h6 text-center'>{locales_es.noMoreRecords}</p>
                </InfiniteScroll>
              ) : (
                <div className='container p-3'>
                  <strong>{locales_es.noData}</strong>
                </div>
              )}
              <Modal
                openModal={addMedicalRecord}
                closeModal={() => setAddMedicalRecord(false)}
                cancellable
                closeButton
                title='Evolucionar Paciente'
              >
                <DynamicForm
                  actionWrapperClassName='d-flex align-items-center justify-content-center'
                  inputs={[
                    {
                      type: 'textArea',
                      name: 'title',
                      label: 'Título *',
                      placeholder: 'Título descriptivo del hito clínico',
                      variant: '',
                      validation: Yup.string().trim().required(REQUIRED),
                      wrapperClassName: 'd-block',
                      dataCy: 'configs_publicPhone_textField',
                      initialValue: '',
                    },
                    {
                      type: 'textArea',
                      name: 'text',
                      label: 'Descripción *',
                      placeholder: 'Descripción relevante de esta evolución',
                      variant: '',
                      validation: Yup.string().trim().required(REQUIRED),
                      wrapperClassName: 'd-block',
                      dataCy: 'configs_publicPhone_textField',
                      initialValue: '',
                    },
                    {
                      type: 'textArea',
                      name: 'internal_notes',
                      label: 'Notas privadas',
                      placeholder:
                        'Anotaciones privadas sólo visibles por el médico. No se comparten con el paciente.',
                      variant: '',
                      validation: Yup.string(),
                      wrapperClassName: 'd-block',
                      dataCy: 'configs_publicPhone_textField',
                      initialValue: '',
                    },
                    {
                      type: 'date',
                      name: '_date',
                      label: 'Fecha',
                      placeholder: locales_es.noData,
                      variant: '',
                      validation: Yup.date().max(new Date()),
                      wrapperClassName: 'd-inline-block col-md-4',
                      dataCy: 'configs_publicPhone_textField',
                      initialValue: new Date(),
                    },
                    {
                      type: 'text',
                      name: 'weight',
                      label: 'Peso en kgs.',
                      placeholder: 'Peso del paciente',
                      variant: '',
                      validation: Yup.number().typeError(NUMBER),
                      wrapperClassName: 'd-inline-block col-md-4',
                      dataCy: 'configs_publicPhone_textField',
                      initialValue: '',
                    },
                    {
                      type: 'text',
                      name: 'height',
                      label: 'Altura en cms.',
                      placeholder: 'Altura del paciente',
                      variant: '',
                      validation: Yup.number().typeError(NUMBER),
                      wrapperClassName: 'd-inline-block col-md-4',
                      dataCy: 'configs_publicPhone_textField',
                      initialValue: '',
                    },
                  ]}
                  actions={[{ ...submit_action, text: locales_es.send }]}
                  onSubmit={(values) => {
                    createMedicalRecord.mutate(
                      {
                        ...values,
                        _date: values._date.getTime(),
                        date: dayjs(values._date.getTime()).format(
                          'YYYY-MM-DD'
                        ),
                        medical_record_type_id: 1,
                        patient_id: patientId,
                      },
                      {
                        onSuccess: async (res) => {
                          await queryClient.invalidateQueries([
                            queries.medicalRecord,
                            patientId,
                          ]);
                          setAddMedicalRecord(false);
                          openModal({
                            title: 'Éxitos',
                            subTitle: res.data.message,
                            closeButton: true,
                            cancellable: true,
                          });
                        },
                      }
                    );
                  }}
                />
              </Modal>
            </Card>
          </div>
        </div>
      </>
    </div>
  );
};

export default PatientProfile;
