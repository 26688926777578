import { useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { ClipLoader } from 'react-spinners';
import { useQueryClient } from '@tanstack/react-query';
import { Switch } from 'src/components/FormInput';
import {
  bank_wire_info_input,
  fixed_amount_input,
  online_payment_input,
  percentage_input,
} from 'src/utils/forms/inputs';
import { TextaArea } from 'src/components/FormInput/TextArea';
import { REQUIRED } from 'src/utils/forms/login';
import Button from 'src/components/Button';
import locales_es from 'src/locales/es.json';
import AmountTypeConfig from 'src/components/AmountTypeConfig';
import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import {
  useBankTransfer,
  usePaymentMethods,
  useUserMeData,
  useUserPaymentMethods,
} from 'src/queries/queries';
import { PAYMENT_METHOD_BANK_TRANSFER } from 'src/services/constants';
import {
  usePaymentBankTransferDataMutation,
  usePaymentConfigMutation,
  usePaymentMethodsMutation,
} from 'src/queries/mutations';
import { setUserData } from 'src/app/slices/userSlice';
import { useModalContext } from 'src/contexts/ModalContext';
import { queries } from 'src/queries/constants';

type paymentType = 'fixed' | 'percent';

const ConfigsForm = () => {
  const user = useAppSelector((state) => state.user.data!);
  const [paymentType, setPaymentType] = useState<paymentType>(
    user.before_payment_type
  );
  const queryClient = useQueryClient();
  const userMe = useUserMeData({ enabled: false });
  const { openModal } = useModalContext();
  const dispatch = useAppDispatch();

  const { data: paymentMethods, isLoading: paymentsLoading } =
    usePaymentMethods();

  const { data: userPaymentMethods, isLoading: userPaymentsLoading } =
    useUserPaymentMethods(user.id);

  const { data: bankTransferData, isLoading: bankTransferLoading } =
    useBankTransfer();

  const paymentConfigMutation = usePaymentConfigMutation();

  const bankTransferDataMutation = usePaymentBankTransferDataMutation();

  const mutatePaymentMethods = usePaymentMethodsMutation();

  const selectPercentage = () => {
    setPaymentType('percent');
  };
  const selectFixed = () => {
    setPaymentType('fixed');
  };

  if (userPaymentsLoading || paymentsLoading || bankTransferLoading)
    return <ClipLoader />;

  return (
    <div className='container'>
      Configuraciones
      <Formik
        initialValues={{
          onlinePayment: user.enable_before_payment,
          before_payment_amount: user.before_payment_amount,
          bankWireInfo: bankTransferData?.data.data.data,
          payments_methods: userPaymentMethods?.data.data.map(
            (method: any) => method.payment_method_id
          ),
        }}
        validationSchema={Yup.object({
          onlinePayment: Yup.boolean(),
          bankWireInfo: Yup.string().when('bankWire', {
            is: (bankWire: boolean) => bankWire === true,
            then: Yup.string().required(REQUIRED),
          }),
        })}
        onSubmit={async (values) => {
          try {
            const promise1 = paymentConfigMutation.mutateAsync({
              before_payment_amount: values.before_payment_amount,
              before_payment_type: paymentType,
              enable_before_payment: values.onlinePayment,
            });
            const promise2 = bankTransferDataMutation.mutateAsync({
              data: values.bankWireInfo,
            });
            const promise3 = mutatePaymentMethods.mutateAsync({
              payment_methods: values.payments_methods,
            });

            await Promise.all([promise1, promise2, promise3]);
            queryClient.invalidateQueries([queries.userMeData]);
            userMe.refetch();
            if (userMe.isSuccess) {
              const user = userMe.data.data.data.user;
              dispatch(setUserData({ user }));
              openModal({
                title: 'Exito',
                subTitle: 'Configuraciones actualizadas.',
              });
            }
          } catch (error) {}
        }}
      >
        {({ setFieldValue, values }) => (
          <Form autoComplete='off'>
            <div className='row'>
              <div className='align-self-left border-bottom'>
                <Switch
                  name={online_payment_input.name}
                  label={online_payment_input.label}
                  title={online_payment_input.title}
                  data-cy={online_payment_input.dataCy}
                />
              </div>
              <div id='checkbox-group'>
                <div role='group' aria-labelledby='checkbox-group'>
                  {paymentMethods?.data.data.map((method: any) => (
                    <div
                      key={method.tag}
                      className='align-self-left border-bottom ps-5 border-bottom'
                    >
                      <Switch
                        name={'payments_methods'}
                        label={method.name}
                        title={false}
                        disabled={!values.onlinePayment}
                        value={method.id}
                        checked={values.payments_methods.includes(
                          Number(method.id)
                        )}
                        onChange={() => {
                          const findMethod = values.payments_methods.find(
                            (pm: any) => pm === method.id
                          );
                          if (findMethod) {
                            setFieldValue(
                              'payments_methods',
                              values.payments_methods.filter(
                                (pm: any) => pm !== method.id
                              )
                            );
                          } else {
                            setFieldValue('payments_methods', [
                              ...values.payments_methods,
                              method.id,
                            ]);
                          }
                        }}
                      />
                    </div>
                  ))}
                </div>
              </div>
              {values.payments_methods.includes(
                paymentMethods?.data.data.find(
                  (pm: any) => pm.tag === PAYMENT_METHOD_BANK_TRANSFER
                ).id
              ) && (
                <div className='align-self-left border-bottom ps-5 border-bottom'>
                  <TextaArea
                    name={bank_wire_info_input.name}
                    label={bank_wire_info_input.label}
                    placeholder={bank_wire_info_input.label}
                    data-cy={bank_wire_info_input.dataCy}
                    labelStyles={{ textAlign: 'end' }}
                    disabled={!values.onlinePayment}
                  />
                </div>
              )}

              {values.onlinePayment && (
                <div className='p-3'>
                  <AmountTypeConfig
                    title='Porcentaje'
                    subtitle='Cobra una porción del valor del turno como seña o adelanto para considerar el turno como Confirmado.'
                    notes={[
                      'Puedes configurar este valor en 100% para cobrar la totalidad del turno.',
                      'Recuerda que aplica para turnos con precios configurados y mayores a $0.',
                      'El turno se pasa a "Confirmado" de forma automática cuando el cobro es a través de Mercado Pago.',
                    ]}
                    inputSimbol='%'
                    field={percentage_input}
                    selected={paymentType === 'percent'}
                    onClick={selectPercentage}
                  />
                  <AmountTypeConfig
                    title='Monto Fijo'
                    subtitle='Ajusta el porcentaje del valor del turno que quieres cobrar.'
                    notes={[
                      'Recuerda que aplica para turnos con precios configurados y mayores a $0.',
                    ]}
                    inputSimbol='$'
                    field={fixed_amount_input}
                    selected={paymentType === 'fixed'}
                    onClick={selectFixed}
                  />
                </div>
              )}

              <div className='col-12'>
                <Button
                  variant='primary'
                  type='submit'
                  classes='mx-auto fw-light'
                  style={{ letterSpacing: '2px', maxWidth: 250 }}
                  data-cy='LoginForm_submit_button'
                >
                  {locales_es.save}
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ConfigsForm;
