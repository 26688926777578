import logo from 'src/icons/logo-white.png';
import { AnimatePresence, motion } from 'framer-motion';
import styles from './Loaders.module.scss';

interface Show {
  show: boolean;
  opacity: number;
  text: string;
}
interface Props {
  loader?: Show;
}

const defaultLoader = {
  show: false,
  opacity: 0,
  text: '',
};

export const FullScreenLoader = ({ loader = defaultLoader }: Props) => {
  // const { loading, opacity, text } = useAppSelector((state) => state.loading);
  return (
    <AnimatePresence>
      {loader.show && (
        <motion.div
          className={styles.fullScreenLoader_container}
          style={{ opacity: loader.opacity }}
          exit={{ opacity: 0 }}
        >
          <div className={styles.logo_container}>
            <img src={logo} alt='logo' />
          </div>
          <div className={styles.logo_text}>
            <p>{loader.text}</p>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
