import { IconButtonProps } from 'src/utils/models';
import { icons } from '../IconButton/IconDictionary';
import { clsx } from 'clsx';
import styles from './FloatingActionButton.module.scss';

interface Props extends IconButtonProps {
  active: boolean;
  animate?: boolean;
}

const FloatingActionButton = ({
  variant = 'primary',
  buttonSize = 40,
  iconSize = 18,
  icon = 'menu',
  handleClick,
  classes = '',
  active,
  animate = true,
}: Props) => {
  return (
    <button
      className={`${styles.btn} ${styles[variant]} ${classes}`}
      style={{ width: buttonSize, height: buttonSize }}
      onClick={handleClick}
    >
      <i
        style={{ width: iconSize, height: iconSize }}
        className={clsx(animate && styles.rotate, active && styles.down)}
      >
        {icons[icon]}
      </i>
    </button>
  );
};

export default FloatingActionButton;
