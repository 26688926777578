import { Provider } from 'react-redux';
import { setupStore as store } from './app/store';
import './styles/App.scss';
import { ModalContextProvider } from './contexts/ModalContext';
import { AppRouter } from './router/AppRouter';

function App() {
  return (
    <div className='App'>
      <Provider store={store()}>
        <ModalContextProvider>
          <AppRouter />
        </ModalContextProvider>
      </Provider>
    </div>
  );
}

export default App;
