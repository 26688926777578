import useWindowSize from 'src/hooks/useWindowSize';
import logoWhite from 'src/icons/logo-white.png';
import styles from './AuthHeader.module.scss';

const AuthHeader = () => {
  const { isMobile } = useWindowSize();
  return (
    <header
      className={`container d-flex align-items-center justify-content-between p-2 ${styles.heading}`}
      style={{ height: 60 }}
    >
      <div className={`${styles.logo}`}>
        <img
          alt='logo'
          src={
            isMobile
              ? logoWhite
              : 'https://api.medicloud.com.ar/uploads/images/clinics/medicloud.png'
          }
        />
      </div>
    </header>
  );
};

export default AuthHeader;
