import { flexRender, Table as TableType } from '@tanstack/react-table';
import styles from './Table.module.scss';

interface Props {
  table: TableType<any>;
}

const Table = ({ table }: Props) => {
  return (
    <div className={styles.table_container}>
      <table className={styles.table}>
        <thead className={styles.table_head}>
          {table.getHeaderGroups().map((headerGroup: any) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header: any) => (
                <th key={header.id} className={styles.table_head_th}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row: any) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell: any) => (
                <td key={cell.id} className={styles.table_head_th}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
