import { useEffect } from 'react';
import DynamicForm from 'src/components/DynamicForm';
import { FullScreenLoader } from 'src/components/Loaders/FullScreenLoader';
import Modal from 'src/components/Modal';
import routes from 'src/router/routes';
import locales_es from 'src/locales/es.json';
import { useNavigate } from 'react-router-dom';
import Button from 'src/components/Button';
import { useAppDispatch } from 'src/app/hooks';
import { setUserData } from 'src/app/slices/userSlice';
import { API_ERROR_MESSAGE } from 'src/utils/constants';
import { useLoginMutation } from 'src/queries/mutations';
import { useUserMeData } from 'src/queries/queries';
import loginForm from 'src/utils/forms/login';

const LoginForm = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const userData = useUserMeData({ enabled: false });
  const {
    mutate: postForm,
    isLoading,
    isError,
    error,
    reset,
  } = useLoginMutation();

  useEffect(() => {
    if (userData.data) {
      const data = userData.data.data.data.user;
      dispatch(setUserData({ user: data }));
      navigate(routes.home);
    }
  }, [userData.data]);

  return (
    <>
      {isLoading && (
        <FullScreenLoader
          loader={{ show: true, text: 'Loading...', opacity: 0.5 }}
        />
      )}
      {isError && (
        <Modal
          openModal={isError}
          title={'Error'}
          subTitle={error?.response?.data?.message || API_ERROR_MESSAGE}
        >
          <Button variant='outline' handleClick={reset}>
            {locales_es.accept}
          </Button>
        </Modal>
      )}
      <DynamicForm
        inputs={loginForm.inputs}
        actions={loginForm.actions}
        actionWrapperClassName={loginForm.actionWrapperClassName}
        onSubmit={(values) =>
          postForm(values, {
            onSuccess: () => {
              userData.refetch();
            },
          })
        }
        disableSubmitBtn={isLoading}
      />
    </>
  );
};

export default LoginForm;
