import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import Table from 'src/components/Table';
import styles from './DashboardDrafts.module.scss';
import { getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { createColumnHelper } from '@tanstack/react-table';
import IconRounded from 'src/components/IconRounded';
import { maxDraft, removeDraft } from 'src/app/slices/dockSlice';

const columnHelper = createColumnHelper<any>();
export const draftsTableColumns = (dispatch: any) => [
  columnHelper.accessor('type', {
    header: () => <>Type</>,
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor('windowStatus', {
    header: () => <>Status</>,
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor('data.text', {
    header: () => <>Texto</>,
    cell: (info) => (
      <div className={styles.table_textField}>{info.getValue() || ''}</div>
    ),
  }),
  columnHelper.accessor('data.id', {
    header: () => <>Acciónes</>,
    cell: (info) => (
      <div className={styles.actions_container}>
        <IconRounded
          size={40}
          icon='edit'
          iconColor='#3465b8'
          onClick={() => {
            dispatch(maxDraft({ id: info.getValue() }));
          }}
        />

        <IconRounded
          size={40}
          icon='trash'
          iconColor='#3465b8'
          onClick={() => {
            dispatch(removeDraft({ id: info.getValue() }));
          }}
        />
      </div>
    ),
  }),
];

const DashboardDrafts = () => {
  const drafts = useAppSelector((state) => state.dock.drafts);
  const dispatch = useAppDispatch();

  const savedDrafts = drafts.filter((draft) => draft.saved);

  const tableColumns = draftsTableColumns(dispatch);

  const table = useReactTable({
    data: savedDrafts || [],
    columns: tableColumns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div className={styles.table_container}>
      {savedDrafts.length ? (
        <Table table={table} />
      ) : (
        <h2>No posee borradores</h2>
      )}
    </div>
  );
};

export default DashboardDrafts;
