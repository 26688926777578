import { FormActionButton, FormActionLink } from 'src/utils/models';
import locales_es from 'src/locales/es.json';
import routes from 'src/router/routes';

export const submit_action: FormActionButton = {
  variant: 'primary',
  type: 'submit',
  classes: 'mx-0 mw-50 fw-light',
  style: { letterSpacing: '2px' },
  text: locales_es.register,
  dataCy: 'registerForm_submit_button',
};
export const alreadyHaveAccount_action: FormActionLink = {
  type: 'link',
  to: routes.auth_login,
  classes: 'ms-1',
  style: { fontSize: 12, fontWeight: 600, textDecoration: 'underline' },
  text: locales_es.alreadyHaveAccount,
  dataCy: 'registerForm_toLogin_link',
};

export const back_action: FormActionLink = {
  type: 'link',
  to: routes.auth_login,
  classes: 'ms-1 text-primary',
  style: { fontSize: 12, fontWeight: 600, textDecoration: 'underline' },
  text: locales_es.back,
  dataCy: 'forgotPasswordForm_back_link',
};
