import { useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Icon, NO_ICON } from 'src/icons/constants';
import useOnClickOutside from 'src/hooks/useOnClickOutside';
import IconButton from '../IconButton';
import { icons } from '../IconButton/IconDictionary';
import styles from './Modal.module.scss';
import { clsx } from 'clsx';
import Button from '../Button';
import { ButtonProps } from 'src/utils/models';

export interface Action {
  handleClick: null | (() => any);
  classes?: string;
  variant: ButtonProps['variant'];
  text: string;
  maintainModal?: boolean
}

export interface Props {
  children?: JSX.Element | JSX.Element[];
  openModal: boolean;
  title?: string;
  subTitle?: string;
  icon?: Icon;
  closeButton?: boolean;
  cancellable?: boolean;
  closeModal?: () => void;
  actions?: Action[];
}

const Modal = ({
  children,
  openModal,
  title = '',
  subTitle = '',
  icon = NO_ICON,
  closeButton = false,
  cancellable = false,
  closeModal = () => {},
  actions = [],
}: Props) => {
  const ref = useRef<HTMLDivElement>(null);

  useOnClickOutside(ref, cancellable ? closeModal : null);

  return (
    <AnimatePresence>
      {openModal && (
        <motion.div
          className={`${styles.modal_fullscreen_container} p-5`}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          data-cy='modal'
        >
          <div
            className={clsx(styles.modal_container, !closeButton && 'pt-5')}
            ref={ref}
          >
            {closeButton && (
              <div className={styles.close_button}>
                <IconButton
                  buttonSize={40}
                  icon='crossCircle'
                  variant='white'
                  iconSize={40}
                  handleClick={closeModal}
                />
              </div>
            )}
            {icon.size > 0 && (
              <div
                className='d-flex align-items-center justify-content-end m-auto mb-4'
                style={{
                  width: icon.size,
                  height: icon.size,
                }}
              >
                <i
                  className={`${styles.modal_icon}`}
                  style={{ fill: icon.color || '#1cac81' }}
                >
                  {icons[icon.icon]}
                </i>
              </div>
            )}

            <div
              className={`d-flex flex-column align-items-center justify-between ${
                title || subTitle ? styles.title_container_padding : ''
              } ${styles.title_container}`}
            >
              {title && <h4 className={`${styles.title}`}>{title}</h4>}
              {subTitle && <h6 className={`${styles.subTitle}`}>{subTitle}</h6>}
            </div>

            {children && (
              <div className='d-flex flex-column align-items-center'>
                {children}
              </div>
            )}
            {actions?.length ? (
              <>
                <div
                  className={'d-flex align-items-center justify-content-center'}
                >
                  {actions.map((action) => (
                    <Button
                      key={action.text}
                      classes={action.classes}
                      handleClick={() => {
                        action.handleClick && action.handleClick();
                        !action.maintainModal && closeModal();
                      }}
                      variant={action.variant}
                    >
                      {action.text}
                    </Button>
                  ))}
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default Modal;
