import { ErrorMessage, useField } from 'formik';
import styles from './FormInput.module.scss';

interface Props {
  name: string;
  title: boolean;
  label?: string;
  [x: string]: any;
  disabled?: boolean;
  onChange?: (e: any) => void;
}

export const Switch = ({ label = '', title, disabled, ...props }: Props) => {
  const [field] = useField({ ...props, type: 'checkbox' });
  return (
    <div style={{ opacity: disabled ? 0.5 : 1 }}>
      {title && <p className={styles.label}>{label}</p>}
      <div className={styles.optionContainer}>
        <label className={`${styles.optionContainer} ${styles.switchLabel}`}>
          <input
            type='checkbox'
            className={`${styles.inputSwitch}  me-2`}
            disabled={disabled}
            {...field}
            {...props}
          />
          {label}
        </label>

        <ErrorMessage
          name={props.name}
          component='p'
          className={styles.errorMessage}
        />
      </div>
    </div>
  );
};
