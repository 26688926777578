import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type DraftType = 'APPOINTMENT' | 'PATIENT' | 'PRESCRIPTION';
type WindowStatus = 'MIN' | 'MAX' | 'CLOSED';

export interface Draft {
  type: DraftType;
  windowStatus: WindowStatus;
  data: Record<string, any>;
  saved: boolean;
}

interface DockState {
  loading: boolean;
  drafts: Draft[];
  error: Error;
}

interface Error {
  isError: boolean;
  message: string;
  name: string;
}

const initialState: DockState = {
  loading: false,
  drafts: [],
  error: {
    isError: false,
    message: '',
    name: '',
  },
};

const saveDock = (drafts: Draft[]) => {
  localStorage.setItem('dock', JSON.stringify(drafts));
};

export const dockSlice = createSlice({
  name: 'dock',
  initialState: initialState,
  reducers: {
    startDock: (state, action) => {
      state.drafts = action.payload;
    },
    resetDock: (state) => {
      state = initialState;
    },
    addDraft: (state, action: PayloadAction<Draft>) => {
      state.drafts = [...state.drafts, action.payload];
      saveDock(state.drafts);
    },
    removeDraft: (state, action) => {
      state.drafts = state.drafts.filter(
        (draft) => draft.data.id !== action.payload.id
      );
      saveDock(state.drafts);
    },
    minDraft: (state, action) => {
      state.drafts = state.drafts.map((draft: Draft) => {
        if (draft.data.id !== action.payload.id) {
          return draft;
        }
        return {
          ...draft,
          windowStatus: 'MIN',
        };
      });
      saveDock(state.drafts);
    },
    maxDraft: (state, action) => {
      state.drafts = state.drafts.map((draft: Draft) => {
        if (draft.data.id !== action.payload.id) {
          return draft;
        }
        return {
          ...draft,
          windowStatus: 'MAX',
        };
      });
      saveDock(state.drafts);
    },
    closeDraft: (state, action) => {
      state.drafts = state.drafts.map((draft: Draft) => {
        if (draft.data.id !== action.payload.id) {
          return draft;
        }
        return {
          ...draft,
          windowStatus: 'CLOSED',
        };
      });
      saveDock(state.drafts);
    },
    saveDraft: (state, action) => {
      state.drafts = state.drafts.map((draft) => {
        if (draft.data.id === action.payload.id) {
          draft.saved = true;
        }
        return draft;
      });
      saveDock(state.drafts);
    },
    updateSavedDraft: (state, action) => {
      state.drafts = state.drafts.map((draft) => {
        if (draft.data.id === action.payload.id) {
          draft.data.text = action.payload.text;
        }
        return draft;
      });
      saveDock(state.drafts);
    },
  },
});

export const {
  startDock,
  resetDock,
  addDraft,
  removeDraft,
  minDraft,
  maxDraft,
  closeDraft,
  saveDraft,
  updateSavedDraft,
} = dockSlice.actions;
