import { useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import FloatingActionButton from 'src/components/FloatingActionButton';
import NewButtonsMenu from 'src/components/NewButtonsMenu';
import buttons from './buttons';
import { DayShifts } from 'src/components/DayShifts/DayShifts';
import { useAppDispatch } from 'src/app/hooks';
import { Shift } from 'src/components/DayShifts/Shift';
import { BUTTONS_ANIMATION, shifts_state } from 'src/utils/home';
import styles from 'src/pages/Schedule/Schedule.module.scss';
import MyClinics from 'src/components/MyClinics';

const MobileDashboardSchedule = () => {
  const [showButtons, setShowButtons] = useState(false);
  const dispatch = useAppDispatch();
  const menuButtons = buttons(dispatch);
  const handleButtons = () => {
    setShowButtons(!showButtons);
  };
  return (
    <>
      <div className={`w-100 ${styles.homeScreen_container}`}>
        <div className={`${styles.section_container}`}>
          <MyClinics />
        </div>
        <div className={`${styles.section_container}`}>
          <DayShifts
            text={{
              bigText: '10',
              title: 'Turnos',
              subTitle: 'en el día',
            }}
          >
            {shifts_state.map((shift) => (
              <Shift key={shift.type} {...shift} />
            ))}
          </DayShifts>
        </div>
        <div>
          <AnimatePresence>
            {showButtons && (
              <NewButtonsMenu
                buttons={menuButtons}
                animate
                animation={BUTTONS_ANIMATION}
                closeMenu={handleButtons}
              />
            )}
          </AnimatePresence>

          <FloatingActionButton
            buttonSize={60}
            variant='primary'
            iconSize={30}
            icon='plus'
            handleClick={handleButtons}
            active={showButtons}
          />
        </div>
      </div>
    </>
  );
};

export default MobileDashboardSchedule;
