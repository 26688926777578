import React from 'react';
import styles from './Button.module.scss';
import { ButtonProps } from 'src/utils/models';
import clsx from 'clsx';

const Button: React.FC<ButtonProps> = ({
  children,
  variant,
  handleClick,
  classes = '',
  style = {},
  type = 'button',
  shadow = false,
  disabled = false,
  ...props
}) => (
  <button
    className={clsx(
      styles.btn,
      styles[variant],
      shadow && styles.btn_shadow,
      classes
    )}
    onClick={handleClick}
    type={type}
    style={style}
    disabled={disabled}
    {...props}
  >
    {children}
  </button>
);

export default Button;
