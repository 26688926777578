/* eslint-disable react/prop-types */
import { useRef, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import { ErrorMessage, useField } from 'formik';
import Imask from 'imask';
import { InputValidation } from './InputValidation/InputValidation';
import styles from './FormInput.module.scss';
import clsx from 'clsx';
import 'react-datepicker/dist/react-datepicker.css';

const mask = 'dd/MM/yyyy';

const FormDatePicker = ({
  name,
  minDate,
  maxDate,
  disabled,
  placeholder,
  autoComplete,
  handleChange,
  customClassName,
  wrapperCustomClassName,
  testId,
  label = '',
}) => {
  const [field, meta] = useField({
    name,
  });
  const ref = useRef(null);

  const handleMount = () => {
    Imask(ref.current.input, {
      mask: Date,
      pattern: 'd/m/`Y',
      format: function (date) {
        let day = date.getDate();
        let month = date.getMonth() + 1;
        const year = date.getFullYear();

        if (day < 10) {
          day = '0' + day;
        }
        if (month < 10) {
          month = '0' + month;
        }

        return [day, month, year].join('/');
      },
      parse: function (str) {
        const dayMonthYear = str.split('/');
        return new Date(dayMonthYear[2], dayMonthYear[1] - 1, dayMonthYear[0]);
      },
    });
  };

  useEffect(() => {
    handleMount();
  }, [field.value]);

  return (
    <div className={wrapperCustomClassName}>
      <label className={styles.label}>{label}</label>
      <div
        className='d-flex align-items-center'
        style={{ position: 'relative' }}
      >
        <div className={'w-100'}>
          {/* <div className={pickerStyles['custom-datepicker']}> */}
          <DatePicker
            wrapperClassName={styles.dateInputWrapper}
            className={clsx(styles.simpleField, styles.dateInput, 'w-100')}
            key={name}
            id={name}
            selected={field.value}
            onChange={handleChange}
            disabled={disabled}
            dateFormat={mask}
            placeholderText={placeholder}
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            dropdownMode='select'
            maxDate={new window.Date().getTime()}
            data-testid={testId}
            autoComplete={autoComplete || 'off'}
            ref={ref}
            name={field.name}
          />
        </div>
        {meta.touched && <InputValidation error={meta.error} />}
      </div>

      <div
        className='p2'
        style={{
          minHeight: 12,
          lineHeight: 1.2,
        }}
      >
        <ErrorMessage
          name={name}
          component='p'
          className={styles.errorMessage}
          data-cy='dateInput-errorMessage'
        />
      </div>
    </div>
  );
};

export default FormDatePicker;
