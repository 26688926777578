import { icon as Icon } from 'src/utils/models';
import { icons } from '../IconButton/IconDictionary';
import styles from './IconRounded.module.scss';

interface Props {
  size?: number;
  classes?: string;
  roundness?: string;
  iconColor?: string;
  testId?: string;
  onClick?: (e?: any) => void;
  stroke?: boolean;
  fill?: boolean;
  cursor?: 'pointer' | 'grab' | 'auto';
  icon: Icon;
}

const IconRounded = ({
  size = 100,
  classes = '',
  roundness = '100%',
  iconColor = 'white',
  onClick,
  testId,
  icon,
  fill = true,
  cursor = 'auto',
  stroke = false,
}: Props) => {
  return (
    <div
      style={{
        width: size,
        height: size,
        borderRadius: roundness,
        cursor,
      }}
      className={`${styles.icon_container}  d-flex align-items-center justify-content-center ${classes}`}
      onClick={onClick}
      data-cy={testId}
    >
      <i
        style={{
          width: size / 1.8,
          height: size / 1.8,
          fill: fill ? iconColor : '',
          stroke: stroke ? iconColor : '',
          color: iconColor || '',
        }}
        className={`${styles.icon}`}
      >
        {icons[icon]}
      </i>
    </div>
  );
};

export default IconRounded;
