export interface ShiftState {
  color: string;
  type: string;
  amount: string;
}
export const shifts_state: ShiftState[] = [
  {
    color: '#7EBC7A',
    type: 'Atendidos',
    amount: '03',
  },
  {
    color: '#C782DD',
    type: 'ESPONTÁNEOS',
    amount: '02',
  },
  {
    color: '#8293DD',
    type: 'RESERVADOS',
    amount: '01',
  },
  {
    color: '#E8E359',
    type: 'AUSENTES',
    amount: '01',
  },
  {
    color: '#e94949',
    type: 'CANCELADOS',
    amount: '01',
  },
  {
    color: '#446242',
    type: 'DOBLE TURNO',
    amount: '02',
  },
];

export const BUTTONS_ANIMATION = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
  },
};
