import { submit_action } from 'src/utils/forms/actions';
import {
  date_of_birth_input,
  email_input,
  firstName_input,
  genders_input,
  identification_input,
  identification_type_id_input,
  lastName_input,
  phoneCode_input,
  timeZone_input,
  slugname_input,
  prefix_input,
  specialties_input,
  license_input,
  bio_input,
  facebook_input,
  twitter_input,
  instagram_input,
  linkedin_input,
  webSite_input,
  publicEmail_input,
  publicPhone_input,
} from 'src/utils/forms/inputs';
import locales_es from 'src/locales/es.json';

const editProfileMedicForm = {
  inputs: [
    { ...email_input, wrapperClassName: 'd-block', disabled: true },
    { ...slugname_input, wrapperClassName: 'd-block' },
    {
      ...prefix_input,
      wrapperClassName: 'd-block col-md-12 ',
      style: {
        width: '100%',
        maxWidth: 220,
      },
    },

    { ...firstName_input },
    { ...lastName_input },
    {
      ...phoneCode_input,
      wrapperClassName: 'd-inline-block col-md-4 ',
    },
    {
      ...identification_type_id_input,
      wrapperClassName: 'd-inline-block col-md-4 ',
    },
    {
      ...identification_input,
      wrapperClassName: 'd-inline-block col-md-4 ',
    },
    date_of_birth_input,
    genders_input,
    specialties_input,
    license_input,
    { ...bio_input, wrapperClassName: 'd-block ' },
    facebook_input,
    twitter_input,
    instagram_input,
    linkedin_input,
    webSite_input,
    publicEmail_input,
    publicPhone_input,
    timeZone_input,
  ],
  actions: [{ ...submit_action, text: locales_es.update }],
  actionWrapperClassName:
    'container d-flex align-items-center justify-content-between',
};

export default editProfileMedicForm;
