import { motion } from 'framer-motion';
import NewClinicComponent from 'src/components/NewClinic';

const NewClinic = () => {
  return (
    <motion.div
      animate={{ opacity: 1, transition: { duration: 1 } }}
      initial={{ opacity: 0 }}
    >
      <NewClinicComponent />
    </motion.div>
  );
};

export default NewClinic;
