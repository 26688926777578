import { useState } from 'react';
import { AnimatePresence, Reorder } from 'framer-motion';
import useWindowSize from 'src/hooks/useWindowSize';
import FloatingActionButton from 'src/components/FloatingActionButton';
import NewButtonsMenu from 'src/components/NewButtonsMenu';
import { ButtonProps, icon } from 'src/utils/models';
import Button from 'src/components/Button';
import IconRounded from 'src/components/IconRounded';
import { Link } from 'react-router-dom';
import ListItem from 'src/components/List';
import Clinic from 'src/components/Clinic';
import { buttons, clinicActions } from 'src/utils/clinics';
import { useMyClinics } from 'src/queries/queries';
import { Clinic as ClinicType } from 'src/utils/models';
import styles from 'src/pages/DashboardClinics/DashboardClinicsScreen.module.scss';
import { ClipLoader } from 'react-spinners';
import { BUTTONS_ANIMATION } from 'src/utils/home';

export interface MobileButtonMenu {
  variant: ButtonProps['variant'];
  backgroundColor: string;
  borderColor: string;
  shadow: boolean;
  text: string;
  icon: icon;
  onClick: { (): void };
}

const DashboardClinics = () => {
  const [showButtons, setShowButtons] = useState(false);
  const { data: clinicsApi, isLoading } = useMyClinics();
  const { isMobile } = useWindowSize();

  const { data: clinicsData } = clinicsApi?.data || [];

  const handleButtons = () => {
    setShowButtons(!showButtons);
  };

  return isLoading ? (
    <ClipLoader />
  ) : (
    <div className={styles.dashboardClinicsScreen_container}>
      <div className={`p-5 ${styles.layout_container}`}>
        <Reorder.Group axis='y' values={clinicsData || []} onReorder={() => {}}>
          {clinicsData.map((clinic: ClinicType) => (
            <ListItem
              key={clinic.id + clinic.address}
              item={clinic}
              actionsArray={clinicActions}
            >
              <Clinic clinic={clinic} />
            </ListItem>
          ))}
        </Reorder.Group>

        <div className={styles.clinics_container}>
          <Link to={'https://forms.gle/UJ15igr8fcCVgZX19'} target='_blank'>
            <Button
              variant='primary'
              style={{
                margin: '30px auto',
                width: '50%',
                height: 50,
                minWidth: 190,
              }}
            >
              <div className={styles.button_content}>
                <div className='w-25'>
                  <IconRounded
                    size={30}
                    icon='plus'
                    iconColor='#FFFFFF'
                    classes={'border border-1 border-white'}
                  />
                </div>

                <span>Crear Clínica</span>
              </div>
            </Button>
          </Link>
        </div>

        <AnimatePresence>
          {showButtons && (
            <NewButtonsMenu
              buttons={buttons}
              animate
              animation={BUTTONS_ANIMATION}
            />
          )}
        </AnimatePresence>
        {isMobile && (
          <FloatingActionButton
            buttonSize={60}
            variant='primary'
            iconSize={30}
            icon='plus'
            handleClick={handleButtons}
            active={showButtons}
          />
        )}

        {!isMobile && (
          <div
            className={`d-flex flex-column justify-content-start ${styles.layout_right_container}`}
          >
            <NewButtonsMenu buttons={buttons} animate={false} />
          </div>
        )}
      </div>
    </div>
  );
};

export default DashboardClinics;
