import { useState, useEffect } from 'react';

interface WindowSize {
  width: number | undefined;
  height: number | undefined;
  isDesktop: boolean | undefined;
  isMobile: boolean | undefined;
}

function useWindowSize(): WindowSize {
  const [windowSize, setWindowSize] = useState<WindowSize>({
    width: undefined,
    height: undefined,
    isDesktop: undefined,
    isMobile: undefined,
  });

  const handleSize = () => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
      isDesktop: window.innerWidth >= 768,
      isMobile: window.innerWidth < 768,
    });
  };

  useEffect(() => {
    window.addEventListener('resize', handleSize);
    handleSize();
    return () => window.removeEventListener('resize', handleSize);
  }, []);

  return windowSize;
}

export default useWindowSize;
