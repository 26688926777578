import { useEffect } from 'react';
import { RouterProvider } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import router from './router';
import { useAppDispatch } from 'src/app/hooks';
import { getUserMe } from 'src/services/apiService';
import { setUserData } from 'src/app/slices/userSlice';
import { getAuthToken, logout } from 'src/utils/auth';
import { FullScreenLoader } from 'src/components/Loaders/FullScreenLoader';
import { EXPIRED } from 'src/services/constants';

export const AppRouter = () => {
  const dispatch = useAppDispatch();
  const { mutate: getUserData, isLoading } = useMutation({
    mutationFn: async () => {
      const token = getAuthToken();
      if (token && token !== EXPIRED) return await getUserMe();
      return null;
    },
    onSuccess: (data) => {
      if (!data) return;
      const user = data.data.data.user;
      dispatch(setUserData({ user }));
    },
    onError: () => {
      logout();
    },
  });

  useEffect(() => {
    getUserData();
  }, []);

  return (
    <>
      <FullScreenLoader
        loader={{ show: isLoading, opacity: 1, text: 'Cargando...' }}
      />
      <RouterProvider router={router} />
    </>
  );
};
