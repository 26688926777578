import { useState } from 'react';
import useWindowSize from 'src/hooks/useWindowSize';
import Button from '../Button';
import Modal from '../Modal';
import { MobileHomeHeader } from './MobileHomeHeader';
import locales_es from 'src/locales/es.json';
import styles from './HomeHeader.module.scss';
import { useNavigate } from 'react-router-dom';
import routes from 'src/router/routes';
import DesktopHomeHeaderWrapper from './DesktopHomeHeader';

export interface HeaderProps {
  toggleLogoutModal: () => void;
}

const HomeHeader = () => {
  const [openLogoutModal, setOpenLogoutModal] = useState(false);
  const { isDesktop } = useWindowSize();
  const navigate = useNavigate();

  const toggleLogoutModal = () => {
    setOpenLogoutModal(!openLogoutModal);
  };

  return (
    <>
      <div>
        {isDesktop ? (
          <DesktopHomeHeaderWrapper toggleLogoutModal={toggleLogoutModal} />
        ) : (
          <MobileHomeHeader toggleLogoutModal={toggleLogoutModal} />
        )}
      </div>
      <Modal
        openModal={openLogoutModal}
        subTitle={locales_es.logoutUser}
        cancellable
        icon={{ icon: 'happy', color: '#1cac81', size: 80 }}
      >
        <div className={styles.flex_between}>
          <Button variant='primary' handleClick={() => navigate(routes.logout)}>
            {locales_es.accept}
          </Button>

          <Button variant='outline' handleClick={toggleLogoutModal}>
            {locales_es.cancel}
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default HomeHeader;
