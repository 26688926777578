import React from 'react';
import { NavLink } from 'react-router-dom';

interface Props {
  classes: string;
  to: string;
  styles?: React.CSSProperties;
  text: string;
}

const LinkTo = ({ classes, to, styles = {}, text }: Props) => {
  return (
    <div className={classes}>
      <NavLink to={to} style={{ textDecoration: 'none', ...styles }}>
        {text}
      </NavLink>
    </div>
  );
};

export default LinkTo;
