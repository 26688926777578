import { WeekView } from '@devexpress/dx-react-scheduler-material-ui';
import styles from './CustomComponents.module.scss';

export const DayCell = ({ today, ...props }: any) => {
  return (
    <WeekView.DayScaleCell
      {...props}
      className={`${styles.weekday} ${today && styles.today}`}
    />
  );
};
