import { WeekView } from '@devexpress/dx-react-scheduler-material-ui';
import styles from './CustomComponents.module.scss';

export const WeekdayLayout = (props: any) => {
  return (
    <WeekView.Layout {...props} className={`${styles.weekview_layout}`}>
      <p>{props}</p>
    </WeekView.Layout>
  );
};
