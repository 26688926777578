import { useNavigate } from 'react-router-dom';
import { clsx } from 'clsx';
import logoWhite from 'src/icons/logo-white.png';
import styles from '../HomeHeader.module.scss';

interface Props {
  mobile?: boolean;
  link?: string;
}
export const Logo = ({ mobile = false, link = '/' }: Props) => {
  const navigation = useNavigate();
  return (
    <div
      onClick={() => navigation(link)}
      style={{ cursor: 'pointer' }}
      className={`d-flex align-items-center justify-content-center ${
        mobile ? styles.mobile_logo_container : styles.logo_container
      }`}
    >
      <div className={clsx(styles.logo, mobile && styles.mobile_logo)}>
        <img alt='logo' src={logoWhite} />
      </div>
    </div>
  );
};
