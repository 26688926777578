import React from 'react';
import Avatar from 'src/components/Avatar';
import { Appointment } from './interfaces';
import locales_es from 'src/locales/es.json';
import styles from './AppointmentCard.module.scss';
import Card from '../Card';
import routes from 'src/router/routes';
import { APPOINTMENT_VIRTUAL_TYPE } from 'src/pages/MyAppointments/consts';
import { parseEventDate, parseEventTime } from 'src/utils/date';

interface Props {
  appointment: Appointment;
  timezones: any[];
  children?: React.ReactElement;
}

export const AppointmentCard = ({
  appointment,
  timezones,
  children,
}: Props) => {
  return (
    <Card variant='primary' classes={styles.container}>
      <div className='d-flex align-items-center border-bottom'>
        <Avatar
          size={60}
          imageUrl={appointment.medic.full_profile_image}
          variant='grey'
        />
        <div className='p-3'>
          <a href={`${routes.medic}/${appointment.medic.id}`}>
            <b>
              <p className='m-0'>
                {appointment.medic.prefix_name} {appointment.medic.name}{' '}
                {appointment.medic.lastname}
              </p>
            </b>
          </a>
          <p className='m-0 text-muted'>{appointment.medic.specialty_name}</p>
        </div>
      </div>
      <div className='pt-4 p-0 ps-2 pb-1'>
        <div className='col-12' data-hj-allow>
          <span
            data-hj-allow
            dangerouslySetInnerHTML={{
              __html: parseEventDate(appointment.start, false) as string,
            }}
          />
          <span
            data-hj-allow
            dangerouslySetInnerHTML={{
              __html: parseEventTime(appointment.start, ''),
            }}
          />
          <div></div>
          {appointment.type_id === APPOINTMENT_VIRTUAL_TYPE ? (
            <span>
              {timezones && timezones.length
                ? getTimezoneLabel(timezones, appointment.time_zone)
                : ''}
            </span>
          ) : null}
        </div>
      </div>
      <div className='ps-2 pb-3'>
        <strong>{locales_es.appointmentTypeName[appointment.type_id]}</strong>
      </div>
      <div className='d-flex align-items-center justify-content-around gap-3'>
        {children}
      </div>
    </Card>
  );
};

export default AppointmentCard;

function getTimezoneLabel(timezoneOptions: any[], time_zone: string) {
  const filtered = timezoneOptions.filter((tz) => tz.id === time_zone);

  console.log(filtered, 'filtered', time_zone);
  if (filtered && filtered.length) {
    const text = filtered[0].name;
    return '(' + text.split('(')[1]; // TODO REVIEW LATER
  }
  return '';
}
