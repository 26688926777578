import styles from './Map.module.scss';
interface Center {
  lat: number;
  lng: number;
}

interface Props {
  center: Center;
  onClick: () => void;
  address: string;
}

const ICON_URL = 'https://tinyurl.com/5b7x2ks9';

const PLACES_API_KEY: string = process.env.REACT_APP_PLACES_API_KEY || '';

export const StaticMap = ({ center, onClick, address }: Props) => {
  return (
    <div>
      <img
        src={`https://maps.googleapis.com/maps/api/staticmap?center=${center.lat}%2c%20${center.lng}&style=feature:road|element:labels|visibility:off&style=feature:landscape|color:0xe3e3e3&style=feature:water|color:0xc8d7d4%7Celement:geometry%7Cvisibility:on&style=feature:poi|visibility:off&markers=anchor:bottomcenter%7Cicon:${ICON_URL}%7C${center.lat},${center.lng}&zoom=12&size=400x200&format=PNG&maptype=terrain&key=${PLACES_API_KEY}`}
        alt='Static map - Google Maps'
        className={`${styles.static_map}`}
        onClick={onClick}
      />
      <p className={`${styles.address_text}`}>{address}</p>
    </div>
  );
};
