import React, { useEffect, useState, useRef } from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { useInfiniteQuery } from '@tanstack/react-query';
import InfiniteScroll from 'react-infinite-scroll-component';
import { ClipLoader } from 'react-spinners';
import { FaDownload } from 'react-icons/fa6';
import { FiPlus } from 'react-icons/fi';
import PatientCard from 'src/components/PatientCard';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { queries } from 'src/queries/constants';
import { getMyPatients, searchMyPatients } from 'src/services/apiService';
import Search from 'src/components/Search';
import locales_es from 'src/locales/es.json';
import { useDebounce } from 'src/hooks/useDebounce';
import routes from 'src/router/routes';
import Button from 'src/components/Button';
import Alert from 'src/components/Alert';
import { Patient } from 'src/components/PatientCard/interfaces';
import styles from './MyPatients.module.scss';

export const MyPatientsMobile = () => {
  const [patients, setPatients] = useState<any>([]);
  const [searchQuery, setSearchQuery] = useState<any>('');
  const tableRef = useRef(null);
  const debouncedSearch = useDebounce({ value: searchQuery, delay: 500 });

  const {
    data: patientsData,
    isLoading: patientsLoading,
    fetchNextPage: fetchNextPatients,
    hasNextPage: hasNextPagePatients,
  } = useInfiniteQuery(
    debouncedSearch
      ? [queries.searchPatients, debouncedSearch]
      : [queries.patientsInfinite],
    async ({ pageParam = 1 }) => {
      if (pageParam === null) return;
      let res;
      if (debouncedSearch) {
        res = await searchMyPatients(debouncedSearch, pageParam, 2);
      } else {
        res = await getMyPatients(pageParam, 2);
      }
      return {
        data: res.data,
        nextItem: pageParam + 1,
        lastPage: res.data.last_page,
      };
    },
    {
      getNextPageParam: (lastPage: any) => {
        if (lastPage?.nextItem === lastPage.lastPage) return;
        return lastPage?.nextItem || null;
      },
    }
  );

  useEffect(() => {
    if (patientsData?.pages.length) {
      setPatients(
        patientsData?.pages.reduce((acc, value) => {
          return acc.concat(value?.data?.data);
        }, [])
      );
    } else {
      setPatients([]);
    }
  }, [patientsData]);

  const handleSearch = (patient: string) => {
    setSearchQuery(patient);
  };

  return (
    <>
      <div className='container d-flex align-items-center justify-content-center'>
        <Search
          placeholder={locales_es.searchByNameOrLastName}
          searchBarStyles={{ maxWidth: 430 }}
          onChange={handleSearch}
        />
      </div>

      <div className='container d-flex align-items-center justify-content-around p-3 position-relative'>
        <div>
          <DownloadTableExcel
            filename='Mis-Pacientes'
            sheet='tablexls'
            currentTableRef={tableRef.current}
          >
            <Button
              variant='outline-secondary'
              style={{ margin: 0, minWidth: 0 }}
            >
              <FaDownload size={15} />
            </Button>
          </DownloadTableExcel>
        </div>
        <Link to={routes.home_patients_add}>
          <Button variant='primary' style={{ margin: 0, minWidth: 0 }}>
            <FiPlus size={15} />
          </Button>
        </Link>
        <table id='table-to-xls' className='d-none' ref={tableRef}>
          <thead>
            <tr>
              <th>Nombre</th>
              <th>Apellido</th>
              <th>E-Mail</th>
              <th>N° de Documento</th>
              <th>Teléfono</th>
              <th>Fecha de Nacimiento</th>
            </tr>
          </thead>
          <tbody>
            {patients.length
              ? patients.map((patient: Patient) => {
                  return (
                    <tr key={patient.id}>
                      <td>{patient.name}</td>
                      <td>{patient.lastname}</td>
                      <td>{patient.user?.email || '-'}</td>
                      <td>{patient.identification}</td>
                      <td>{patient.user?.cellphone || '-'}</td>
                      <td>{patient.date_of_birth.toString()}</td>
                    </tr>
                  );
                })
              : null}
          </tbody>
        </table>
      </div>
      <div className='container d-flex align-items-center justify-content-center position-relative'>
        {patientsLoading ? (
          <ClipLoader />
        ) : patients.length ? (
          <InfiniteScroll
            loader={<ClipLoader />}
            dataLength={patients.length}
            hasMore={hasNextPagePatients || false}
            next={() => fetchNextPatients()}
            className={clsx(
              'mx-auto d-flex flex-column align-items-center',
              styles.infiniteScroll
            )}
          >
            {patients.map((patient: any) => (
              <PatientCard patient={patient} key={patient.id} />
            ))}
          </InfiniteScroll>
        ) : (
          <Alert variant='message' open dismissable={false}>
            {locales_es.noPatientsFound}
          </Alert>
        )}
      </div>
    </>
  );
};
