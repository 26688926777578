import { icon, ButtonProps } from 'src/utils/models';
import locales_es from 'src/locales/es.json';
import { Draft, addDraft } from 'src/app/slices/dockSlice';

export interface MobileButtonMenu {
  variant: ButtonProps['variant'];
  backgroundColor: string;
  borderColor: string;
  shadow: boolean;
  text: string;
  icon: icon;
  onClick: { (): void };
}

const buttons: (dispatch: any) => MobileButtonMenu[] = (dispatch) => [
  {
    variant: 'primary',
    backgroundColor: '#1cac81',
    borderColor: '#26a67f',
    shadow: false,
    text: locales_es.newTurn,
    icon: 'calendar',
    onClick: () => {
      const draft: Draft = {
        type: 'APPOINTMENT',
        windowStatus: 'MAX',
        data: { id: `APPOINTMENT${Math.random()}`, bg: '#1cac81' },
        saved: false,
      };
      dispatch(addDraft(draft));
    },
  },
  {
    variant: 'primary',
    backgroundColor: '#1cac81',
    borderColor: '#26a67f',
    shadow: false,
    text: locales_es.newPatient,
    icon: 'addressBook',
    onClick: () => {
      const draft: Draft = {
        type: 'PATIENT',
        windowStatus: 'MAX',
        data: { id: `PATIENT${Math.random()}`, bg: '#13795a' },
        saved: false,
      };
      dispatch(addDraft(draft));
    },
  },
  {
    variant: 'primary',
    backgroundColor: '#1cac81',
    borderColor: '#26a67f',
    shadow: false,
    text: locales_es.newPrescription,
    icon: 'document',
    onClick: () => {
      const draft: Draft = {
        type: 'PRESCRIPTION',
        windowStatus: 'MAX',
        data: { id: `PRESCRIPTION${Math.random()}`, bg: '#0a4534' },
        saved: false,
      };
      dispatch(addDraft(draft));
    },
  },
];

export default buttons;
