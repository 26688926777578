import { useEffect } from 'react';
import { Outlet, useNavigate, useLoaderData } from 'react-router-dom';
import HomeHeader from 'src/components/HomeHeader';
import Dock from 'src/components/Dock';
import { getTokenDuration } from 'src/utils/auth';
import routes from 'src/router/routes';
import { EXPIRED } from 'src/services/constants';

const RootHome = () => {
  const navigate = useNavigate();
  const token = useLoaderData();

  useEffect(() => {
    if (!token || token === EXPIRED) {
      return navigate(routes.logout);
    }
    const tokenDuration = getTokenDuration();

    setTimeout(() => navigate(routes.logout), tokenDuration);
  }, [token, navigate]);

  return (
    <>
      <HomeHeader />
      <Outlet />
      <Dock />
    </>
  );
};

export default RootHome;
