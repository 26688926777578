import Heading from 'src/components/Heading';
import locales_es from 'src/locales/es.json';
import DynamicForm from 'src/components/DynamicForm';
import { useMutation } from '@tanstack/react-query';
import { passwordRequest } from 'src/services/apiService';
import { FullScreenLoader } from 'src/components/Loaders/FullScreenLoader';
import { API_ERROR_MESSAGE } from 'src/utils/constants';
import { API_ERROR_ICON } from 'src/icons/constants';
import Modal from 'src/components/Modal';
import Button from 'src/components/Button';
import forgotPasswordForm from 'src/utils/forms/forgotPassword';

interface Values {
  email: '';
}

const ForgotPasswordForm = () => {
  const {
    mutate: postForm,
    isLoading,
    isError,
    reset,
    error,
  } = useMutation({
    mutationFn: async (values: Values) => await passwordRequest(values.email),
    onError: (error: any) => error,
    onSuccess: (data) => {
      //TODO
    },
  });
  return (
    <>
      {isLoading && (
        <FullScreenLoader
          loader={{ show: true, text: 'Loading...', opacity: 0.5 }}
        />
      )}
      {isError && (
        <Modal
          openModal={isError}
          title={'Error'}
          subTitle={error?.response.data.message || API_ERROR_MESSAGE}
          icon={API_ERROR_ICON}
        >
          <Button variant='outline' handleClick={reset}>
            {locales_es.accept}
          </Button>
        </Modal>
      )}
      <Heading
        title={locales_es.recoverPassword}
        subtitle={locales_es.enterEmailForPasswordRecovery}
        classes='container d-flex justify-content-center p-3'
      />
      <DynamicForm
        inputs={forgotPasswordForm.inputs}
        actions={forgotPasswordForm.actions}
        actionWrapperClassName={forgotPasswordForm.actionWrapperClassName}
        onSubmit={postForm}
      />
    </>
  );
};

export default ForgotPasswordForm;
