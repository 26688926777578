import { useMutation } from '@tanstack/react-query';
import { userTypesType } from 'src/components/RegisterNewForm';
import { setAuthToken } from 'src/utils/auth';
import {
  deleteMedicalRecord,
  login,
  postMedicalRecord,
  postPatient,
  putBankTransferData,
  putMedicalInfo,
  putPaymentConfig,
  putPaymentMethods,
  register,
  updateUser,
} from 'src/services/apiService';

interface PaymentConfigBody {
  before_payment_amount: number;
  before_payment_type: 'fixed' | 'percent';
  enable_before_payment: boolean;
}
interface PaymentBankTransferDataBody {
  data: string;
}
interface PaymentMethodsBody {
  payment_methods: number[];
}
interface PatientBody {
  name: string;
  lastname: string;
  email: string;
  email_repeat: string;
  countryCode: string;
  identification_type_id: number;
  identification: string;
  date_of_birth: Date;
  gender_id: number;
  time_zone: string;
}

interface MedicalProfile {
  patient_id: string;
  pre_existing_conditions: string;
  chronic_medication: string;
  habits: string;
  family_background: string;
}

interface MedicalRecord {
  _date: number;
  title: string;
  text: string;
  internal_notes: string;
  weight: string;
  height: string;
  medical_record_type_id: number;
  patient_id: string;
  date: Date;
}

interface MedicalRecordId {
  id: number;
}

// LOGIN
export const useLoginMutation = () =>
  useMutation<any, any>({
    mutationFn: async (values: any) => {
      const { email, password } = values;
      const res = await login({ email, password, clinicId: '' });
      const token = res.data?.data.token;
      setAuthToken(token);
      return res;
    },
  });

// REGISTER
export const useRegisterMutation = (userType: userTypesType) =>
  useMutation({
    mutationFn: async (values: any) => {
      values.user_type = userType;
      return await register(values);
    },
  });

// UPDATE USER
export const useUpdateUserMutation = () =>
  useMutation({
    mutationFn: async (values: any) => await updateUser(values),
  });

// UPDATE PAYMENT CONFIG
export const usePaymentConfigMutation = () =>
  useMutation({
    mutationFn: async (values: PaymentConfigBody) =>
      await putPaymentConfig(values),
  });

//UPDATE BANK TRANSFER DATA
export const usePaymentBankTransferDataMutation = () =>
  useMutation({
    mutationFn: async (values: PaymentBankTransferDataBody) =>
      await putBankTransferData(values),
  });

// UPDATE PAYMENT METHODS
export const usePaymentMethodsMutation = () =>
  useMutation({
    mutationFn: async (values: PaymentMethodsBody) =>
      await putPaymentMethods(values),
  });

// ADD PATIENT
export const useAddPatientMutation = () =>
  useMutation({
    mutationFn: async (values: PatientBody) => await postPatient(values),
  });

// EDIT PATIENT MEDICAL PROFILE
export const useEditPatientMedicalProfileMutation = () =>
  useMutation({
    mutationFn: async (values: MedicalProfile) => await putMedicalInfo(values),
  });

// CREATE MEDICAL RECORD
export const useMedicalrecordMutation = () =>
  useMutation({
    mutationFn: async (values: MedicalRecord) =>
      await postMedicalRecord(values),
  });

// DELETE MEDICAL RECORD
export const useDeleteMedicalrecordMutation = () =>
  useMutation({
    mutationFn: async ({ id }: MedicalRecordId) =>
      await deleteMedicalRecord(id),
  });
