import { ReactElement } from 'react';
import AuthHeader from 'src/components/AuthHeader';
import { icons } from 'src/components/IconButton/IconDictionary';
import styles from './ScreenDivider.module.scss';
import { useSearchParams } from 'react-router-dom';
import clsx from 'clsx';

interface Props {
  children?: ReactElement | ReactElement[];
}

const ScreenDivider = ({ children }: Props) => {
  const [searchParams] = useSearchParams();
  const bg = searchParams.get('type');
  return (
    <div className='d-flex min-vh-100 vw-100'>
      <section className='container-fluid d-flex flex-column p-0'>
        <AuthHeader />
        <div
          className='container-sm d-flex flex-column justify-content-start justify-content-sm-center align-items-center flex-grow-1 p-4'
          style={{
            minWidth: 200,
          }}
        >
          <div
            className='container d-flex flex-column justify-content-center align-items-center pt-4'
            style={{ maxWidth: 500 }}
          >
            {children}
          </div>
        </div>
      </section>
      <section
        className={clsx(
          'd-none d-sm-flex flex-column justify-content-center align-items-center bg-primary',
          bg && styles[bg]
        )}
        style={{ width: '100%' }}
      >
        <div
          className='w-100 d-flex justify-content-center align-items-center'
          style={{ maxWidth: 400 }}
        >
          <i className={styles.loginBg}>{icons.loginBg}</i>
        </div>
      </section>
    </div>
  );
};

export default ScreenDivider;
