import React, { useEffect, useState } from 'react';
import Modal from '../Modal';
import {
  getCellphoneValidation,
  postCellphoneValidation,
  updateCellphone,
} from 'src/services/apiService';
import { getTimeRemaining } from 'src/utils/date';
import { ClipLoader } from 'react-spinners';
import ReactCodeInput from 'react-verification-code-input';
import locales_es from 'src/locales/es.json';
import styles from './TelephoneValidation.module.scss';
import { API_ERROR_ICON, FORM_HAPPY_ICON } from 'src/icons/constants';
import { useModalContext } from 'src/contexts/ModalContext';

const TelephoneValidation = ({ isValid = null, cellphone, refetch }: any) => {
  const { openModal } = useModalContext();
  const [loading, setLoading] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [resendCountdown, setResendCountdown] = useState<string | null>(null);
  const [enableResend, setEnableResend] = useState<Date | null>(null);
  const [showInvalidCellphoneModal, setShowInvalidCellphoneModal] =
    useState(false);
  const redirect = new URLSearchParams(window.location.search).get('redirect');

  function handleClose() {
    setShowConfirmationModal(false);
    setResendCountdown(null);
    setEnableResend(null);
    setShowInvalidCellphoneModal(false);
    setLoading(false);
  }

  function initUpdate() {
    setLoading(true);
    if (!cellphone || cellphone.length <= 4) {
      setLoading(false);
      setShowInvalidCellphoneModal(true);
    } else {
      updateCellphone({ cellphone })
        .then(() => {
          setLoading(false);
          setShowConfirmationModal(true);
        })
        .catch((err) => {
          handleClose();
          openModal({
            title: locales_es.upss,
            subTitle:
              err?.response?.data?.message ||
              locales_es.experimentingDificulties,
            cancellable: true,
            icon: API_ERROR_ICON,
          });
        });
    }
  }

  function sendValidationCode() {
    setLoading(true);
    getCellphoneValidation()
      .then(() => {
        const enableResend = new Date();
        enableResend.setMinutes(enableResend.getMinutes() + 1);
        setLoading(false);
        setEnableResend(enableResend);
        initCountdown(enableResend);
      })
      .catch((err) => {
        handleClose();
        openModal({
          title: locales_es.upss,
          subTitle:
            err?.response?.data?.message || locales_es.experimentingDificulties,
          cancellable: true,
          icon: API_ERROR_ICON,
        });
      });
  }

  function initCountdown(enableResend: Date) {
    const timeinterval = setInterval(() => {
      const t = getTimeRemaining(enableResend);
      if (t.total <= 0) {
        setResendCountdown(null);
        clearInterval(timeinterval);
      } else {
        setResendCountdown(t.minutes + ':' + t.seconds);
      }
    }, 1000);
  }

  function send(code: string) {
    postCellphoneValidation({ cellphone_validation_code: code })
      .then((res) => {
        handleClose();
        openModal({
          title: locales_es.success,
          subTitle: locales_es.telephoneValidationSuccessfull,
          icon: FORM_HAPPY_ICON,
        });
        refetch();
      })
      .catch((err) => {
        handleClose();
        openModal({
          title: locales_es.upss,
          subTitle:
            err?.response?.data?.message || locales_es.experimentingDificulties,
          cancellable: true,
          icon: API_ERROR_ICON,
        });
      });
  }

  useEffect(() => {
    if (redirect) {
      initUpdate();
    }
  }, []);

  return (
    <>
      {isValid ? (
        <div className={styles.text}>
          <i
            className={`fi fi-rr-info ${styles.info_icon}`}
            onClick={() => {
              openModal({
                title: locales_es.telephoneValidation.title,
                subTitle: locales_es.telephoneValidation.alreadyValidated,
              });
            }}
          />
          {locales_es.valid}
        </div>
      ) : (
        <div className={styles.text} onClick={initUpdate}>
          {locales_es.validate}
        </div>
      )}

      {showConfirmationModal && (
        <Modal
          openModal
          title={locales_es.telephoneValidation.title}
          closeModal={handleClose}
          closeButton
          actions={
            enableResend
              ? [
                  {
                    maintainModal: true,
                    text: locales_es.telephoneValidation.resendCode,
                    variant: 'primary',
                    handleClick: () => {
                      !loading && !resendCountdown && sendValidationCode();
                    },
                  },
                  {
                    text: locales_es.cancel,
                    variant: 'outline',
                    handleClick: handleClose,
                  },
                ]
              : [
                  {
                    maintainModal: true,
                    text: locales_es.telephoneValidation.sendCode,
                    variant: 'primary',
                    handleClick: () => {
                      !loading && sendValidationCode();
                    },
                  },
                  {
                    text: locales_es.cancel,
                    variant: 'outline',
                    handleClick: handleClose,
                  },
                ]
          }
        >
          <>
            {enableResend ? (
              <div className={`text-center ${styles.modal_container}`}>
                <div className='text-center'>
                  {locales_es.telephoneValidation.enableResend[0]}
                  <h4>{cellphone}</h4>
                  {resendCountdown && (
                    <p>
                      {locales_es.telephoneValidation.enableResend[1]}
                      <span id='clock'>{resendCountdown}</span>
                    </p>
                  )}
                </div>
                <div className='text-center'>
                  <p>
                    {locales_es.telephoneValidation.enableResend[2]}
                    <a href='mailto:info@woopi.com.ar'>
                      {locales_es.telephoneValidation.enableResend[3]}
                    </a>
                  </p>
                </div>
                <div className={styles.modal_verification}>
                  <h6>{locales_es.telephoneValidation.enableResend[4]}</h6>
                  <ReactCodeInput
                    fields={5}
                    onComplete={send}
                    autoFocus={true}
                  />
                </div>
              </div>
            ) : (
              <div className={`text-center ${styles.modal_container}`}>
                <i className={`fi fi-brands-whatsapp ${styles.modal_icon}`} />
                <div>
                  {locales_es.telephoneValidation.send[0]}
                  <div className={styles.modal_telephone}>{cellphone}</div>
                </div>
                {locales_es.telephoneValidation.send[1]}
                <div></div>
                {locales_es.telephoneValidation.send[2]}
                {loading && <ClipLoader />}
              </div>
            )}
          </>
        </Modal>
      )}

      {showInvalidCellphoneModal && (
        <Modal
          actions={[
            {
              text: locales_es.accept,
              variant: 'primary',
              handleClick: () => setShowInvalidCellphoneModal(false),
            },
          ]}
          openModal
          title={locales_es.telephoneValidation.title}
          subTitle={locales_es.telephoneValidation.subtitle}
          closeButton
          closeModal={() => setShowInvalidCellphoneModal(false)}
        ></Modal>
      )}
    </>
  );
};

export default TelephoneValidation;
