import { ErrorMessage, useField } from 'formik';
import styles from './FormInput.module.scss';
import { InputValidation } from './InputValidation/InputValidation';
import { clsx } from 'clsx';
import { CSSProperties } from 'react';

interface Props {
  name: string;
  label?: string;
  defaultValue?: string;
  type?: 'text' | 'email' | 'password';
  placeholder?: string;
  id?: string;
  classes?: string;
  handleEyeClick?: () => void;
  disabled?: boolean;
  [x: string]: any;
  style?: React.CSSProperties;
  variant?: 'textInput_transparent' | null;
  testId?: string;
  labelStyles?: CSSProperties;
}

export const TextaArea = ({
  label,
  classes = '',
  placeholder,
  handleEyeClick,
  disabled = false,
  style = {},
  variant,
  testId,
  labelStyles,
  ...props
}: Props) => {
  const [field, meta] = useField(props);
  return (
    <div
      className={clsx(classes)}
      style={{ ...style, opacity: disabled ? 0.5 : 1 }}
    >
      <label
        htmlFor={props.id || props.name}
        className={styles.label}
        style={labelStyles}
      >
        {label}
      </label>

      <div
        className='d-flex align-items-center'
        style={{ position: 'relative' }}
      >
        <textarea
          className={clsx(styles.textarea, variant && styles[variant], 'w-100')}
          autoComplete='off'
          disabled={disabled}
          id={props.id || props.name}
          aria-label={props.id || props.name}
          data-testid={testId}
          placeholder={placeholder}
          {...field}
          {...props}
        />
        {meta.touched && <InputValidation error={meta.error} />}
      </div>

      <div
        className='p2'
        style={{
          minHeight: 12,
          lineHeight: 1.2,
        }}
      >
        <ErrorMessage
          name={props.name}
          component='p'
          className={styles.errorMessage}
          data-cy='textInput-errorMessage'
        />
      </div>
    </div>
  );
};
