import { motion } from 'framer-motion';
import { ClipLoader } from 'react-spinners';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  useMyFutureAppointments,
  useMyPastAppointments,
  useUserMeData,
} from 'src/queries/queries';
import Card from 'src/components/Card';
import styles from './MyAppointmentsScreen.module.scss';
import locales_es from 'src/locales/es.json';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import Table from 'src/components/Table';
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import routes from 'src/router/routes';
import Button from 'src/components/Button';
import clsx from 'clsx';
import { openModalType, useModalContext } from 'src/contexts/ModalContext';
import {
  APPOINTMENT_VIRTUAL_TYPE,
  getClinicAddress,
  HREF_PAGE_VIDEOCALL,
  PAYMENT_STATUS_PENDING,
  showAppointmentInfo,
  showCancelModal,
} from './consts';
import CancelAppointmentModal from 'src/components/CancelAppointmentModal';
import PaymentOptionsModal from 'src/components/PaymentsOptionModal';
import useWindowSize from 'src/hooks/useWindowSize';
import AppointmentCard from 'src/components/AppointmentCard';
import { Appointment } from 'src/components/AppointmentCard/interfaces';
import { getRemoteParsedTimezones } from 'src/services/timezoneService';
import { parseEventDate } from 'src/utils/date';
import { Link } from 'react-router-dom';
import Alert from 'src/components/Alert';

const MyAppointments = () => {
  const { data: userData } = useUserMeData({ enabled: true });
  const user = userData?.data?.data?.user;
  const { isMobile } = useWindowSize();
  const [alert, setAlert] = useState(true);
  const urlParams = new URLSearchParams(window.location.search);
  const now = new Date();
  now.setHours(now.getHours() - 1);
  const { openModal } = useModalContext();
  const myFutureAppointments = useMyFutureAppointments({
    start: now.toISOString(),
  });
  const myPastAppointments = useMyPastAppointments({ end: now.toISOString() });
  const [medicId, setMedicId] = useState<any>();
  const [timezones, setTimezones] = useState<any[]>([]);

  const [currentTab, setCurrentTab] = useState('future');
  const tableColumns = columns(
    currentTab,
    openModal,
    myFutureAppointments.refetch,
    setMedicId
  );
  const [cancelAppointmentId, setCancelAppointmentId] = useState(
    urlParams.get('cancelAppointment')
  );

  const futureAppointmentsTable = useReactTable({
    data: myFutureAppointments.data?.data?.data || [],
    columns: tableColumns,
    getCoreRowModel: getCoreRowModel(),
  });

  const pastAppointmentsTable = useReactTable({
    data: myPastAppointments.data?.data?.data || [],
    columns: tableColumns,
    getCoreRowModel: getCoreRowModel(),
  });

  const [futureAppointmentsPage, setFutureAppointmentsPage] = useState(1);
  const [pastAppointmentsPage, setPastAppointmentsPage] = useState(1);

  const filteredFutureAppointments = [
    ...(myFutureAppointments?.data?.data?.data ?? []),
  ].splice(0, 5 * futureAppointmentsPage);

  const filteredPastAppointments = [
    ...(myPastAppointments?.data?.data?.data ?? []),
  ].splice(0, 5 * pastAppointmentsPage);

  useEffect(() => {
    getRemoteParsedTimezones().then((res) => {
      setTimezones(res);
    });
  }, []);

  return (
    <motion.div
      animate={{ opacity: 1, transition: { duration: 1 } }}
      initial={{ opacity: 0 }}
    >
      {!user?.is_cellphone_validated && alert && (
        <div className='w-100 d-flex p-1 p-lg-5 pb-lg-0'>
          <Alert
            variant='primary'
            open
            dismissable
            close={() => setAlert(false)}
            className={styles.alert}
          >
            <Link
              to={`${routes.profile_edit}?redirect=${routes.home}`}
              className={styles.alert}
            >
              <i className={`fi fi-brands-whatsapp ${styles.alert_icon}`}></i>{' '}
              {locales_es.telephoneNotYetValidated}
            </Link>
          </Alert>
        </div>
      )}
      <div className='w-100 d-flex p-1 p-lg-5'>
        {myFutureAppointments.isLoading ? (
          <ClipLoader className='m-auto' />
        ) : (
          <Card variant='primary' classes={styles.card}>
            <div className='d-flex w-100 justify-content-between align-center'>
              <h3>{locales_es.myAppointments.title}</h3>
              <ul className='d-flex gap-2'>
                <li
                  className={clsx(
                    styles.tab_item,
                    'p-2',
                    currentTab === 'future' && styles.tab_item_active
                  )}
                  onClick={() => setCurrentTab('future')}
                >
                  {locales_es.myAppointments.future}
                </li>
                <li
                  className={clsx(
                    styles.tab_item,
                    'p-2',
                    currentTab === 'past' && styles.tab_item_active
                  )}
                  onClick={() => setCurrentTab('past')}
                >
                  {locales_es.myAppointments.past}
                </li>
              </ul>
            </div>
            {currentTab === 'future' && (
              <motion.div
                className='w-100'
                animate={{ opacity: 1, transition: { duration: 1 } }}
                initial={{ opacity: 0 }}
              >
                {!!myFutureAppointments.data?.data?.data.length ? (
                  <div className={clsx('w-100', styles.table_container)}>
                    {isMobile ? (
                      <InfiniteScroll
                        loader={<ClipLoader />}
                        dataLength={filteredFutureAppointments.length}
                        hasMore={
                          myFutureAppointments.data?.data?.data.length >
                          filteredFutureAppointments.length
                        }
                        next={() => {
                          setTimeout(
                            () =>
                              setFutureAppointmentsPage(
                                futureAppointmentsPage + 1
                              ),
                            1200
                          );
                        }}
                        className={clsx(
                          'mx-auto d-flex flex-column align-items-center',
                          styles.infiniteScroll
                        )}
                      >
                        {filteredFutureAppointments.map(
                          (appointment: Appointment) => {
                            return (
                              <AppointmentCard
                                key={appointment.id}
                                appointment={appointment}
                                timezones={timezones}
                              >
                                {getActions(
                                  { row: { original: appointment } },
                                  openModal,
                                  myFutureAppointments.refetch,
                                  setMedicId
                                )}
                              </AppointmentCard>
                            );
                          }
                        )}
                      </InfiniteScroll>
                    ) : (
                      <Table table={futureAppointmentsTable} />
                    )}
                  </div>
                ) : (
                  locales_es.noResultsForThisSearch
                )}
              </motion.div>
            )}
            {currentTab === 'past' && (
              <motion.div
                className='w-100'
                animate={{ opacity: 1, transition: { duration: 1 } }}
                initial={{ opacity: 0 }}
              >
                {!!myPastAppointments.data?.data?.data.length ? (
                  <div className={clsx('w-100', styles.table_container)}>
                    {isMobile ? (
                      <InfiniteScroll
                        loader={<ClipLoader />}
                        dataLength={filteredPastAppointments.length}
                        hasMore={
                          myPastAppointments.data?.data?.data.length >
                          filteredPastAppointments.length
                        }
                        next={() => {
                          setTimeout(
                            () =>
                              setPastAppointmentsPage(pastAppointmentsPage + 1),
                            1200
                          );
                        }}
                        className={clsx(
                          'mx-auto d-flex flex-column align-items-center',
                          styles.infiniteScroll
                        )}
                      >
                        {filteredPastAppointments.map(
                          (appointment: Appointment) => {
                            return (
                              <AppointmentCard
                                key={appointment.id}
                                appointment={appointment}
                                timezones={timezones}
                              />
                            );
                          }
                        )}
                      </InfiniteScroll>
                    ) : (
                      <Table table={pastAppointmentsTable} />
                    )}
                  </div>
                ) : (
                  locales_es.noResultsForThisSearch
                )}
              </motion.div>
            )}
          </Card>
        )}
      </div>
      {cancelAppointmentId && (
        <CancelAppointmentModal
          appointmentId={cancelAppointmentId}
          closeModal={() => {
            setCancelAppointmentId(null);
          }}
          handleCancelAppointment={() => myFutureAppointments.refetch()}
        />
      )}
      {!!medicId && (
        <PaymentOptionsModal
          medicId={medicId}
          closeModal={() => setMedicId(undefined)}
          returnToMainModal={(medicId) => setMedicId(medicId)}
        />
      )}
    </motion.div>
  );
};

export default MyAppointments;

const columnHelper = createColumnHelper<any>();

const columns = (
  currentTab: string,
  openModal: openModalType,
  refetch: () => void,
  setMedicId: Dispatch<SetStateAction<any>>
) => [
  columnHelper.accessor('id', {
    header: () => <span className='d-flex justify-start'>#</span>,
    cell: (info) => (
      <span className='d-flex justify-start pb-3 pt-3'>{info.getValue()}</span>
    ),
  }),
  columnHelper.accessor('start', {
    header: () => <span className='d-flex justify-start'>Fecha</span>,
    cell: (info) => (
      <span className='d-flex justify-start'>
        {parseEventDate(info.getValue(), false)}
      </span>
    ),
  }),
  columnHelper.accessor('medic', {
    header: () => <span className='d-flex justify-start'>Especialista</span>,
    cell: (info) => (
      <span className='d-flex justify-start'>
        <a href={`${routes.medic}/${info.getValue().id}`}>
          {info.getValue().prefix_name} {info.getValue().name}{' '}
          {info.getValue().lastname}
        </a>
      </span>
    ),
  }),
  columnHelper.accessor('type_id', {
    header: () => <>Tipo de turno</>,
    cell: (info) => locales_es.appointmentTypeName[info.getValue()],
  }),
  columnHelper.accessor('data', {
    header: () => currentTab === 'future' && <>Acciones</>,
    cell: (info) =>
      currentTab === 'future' &&
      getActions(info, openModal, refetch, setMedicId),
  }),
];

function getActions(
  info: any,
  openModal: openModalType,
  refetch: () => void,
  setMedicId: Dispatch<SetStateAction<any>>
) {
  return (
    <div className={styles.actions_container}>
      {info.row.original.payment_status === PAYMENT_STATUS_PENDING && (
        <Button
          classes={styles.actions}
          variant='patient'
          handleClick={() => setMedicId(info.row.original)}
        >
          <i className='fi fi-rr-dollar'></i>
          <span>{locales_es.myAppointments.actions.pay}</span>
        </Button>
      )}
      {info.row.original.type_id === APPOINTMENT_VIRTUAL_TYPE ? (
        <a
          href={`${HREF_PAGE_VIDEOCALL}/${info.row.original.videocall_hash}`}
          type='button'
          target='_blank'
          title={locales_es.myAppointments.actions.videocall}
          rel='noreferrer'
        >
          <Button classes={styles.actions} variant='outline-secondary'>
            <i className='fi fi-rr-computer'></i>
            <span>{locales_es.myAppointments.actions.videocall}</span>
          </Button>
        </a>
      ) : (
        <Button
          classes={styles.actions}
          variant='outline-secondary'
          handleClick={(e) => {
            e.preventDefault();
            getClinicAddress(info.row.original.clinic_id, openModal);
          }}
        >
          <i className='fi fi-rr-map-marker'></i>
          <span>{locales_es.seeLocation}</span>
        </Button>
      )}
      <Button
        classes={styles.actions}
        variant='outline'
        handleClick={() => showAppointmentInfo(info.row.original, openModal)}
      >
        <i className='fi fi-rr-info'></i>
        <span>{locales_es.myAppointments.actions.info}</span>
      </Button>
      <Button
        classes={styles.actions}
        style={{ background: '#eb4b4b' }}
        variant='primary'
        handleClick={() => {
          showCancelModal(info.row.original, openModal, refetch, styles);
        }}
      >
        <i className='fi fi-rr-trash'></i>
        <span>{locales_es.myAppointments.actions.cancel}</span>
      </Button>
    </div>
  );
}
