import { DayView } from '@devexpress/dx-react-scheduler-material-ui';
import styles from './CustomComponents.module.scss';

export const DayLayout = (props: any) => {
  return (
    <DayView.Layout {...props} className={`${styles.dayview_layout}`}>
      <p>{props}</p>
    </DayView.Layout>
  );
};
