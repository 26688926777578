import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { USER_TYPE_MEDIC, USER_TYPE_PATIENT } from 'src/utils/constants';
import { getUser } from './thunksUser';

export interface UserData {
  id: number;
  person_id: number | null;
  user_type: typeof USER_TYPE_MEDIC | typeof USER_TYPE_PATIENT;
  name: string;
  lastname: string;
  email: string;
  email_verified_at: null;
  slugname: string | null;
  date_of_birth: string;
  original_password: null;
  gender_id: number;
  time_zone: string;
  identification_type_id: number;
  identification: string;
  cellphone: string;
  is_cellphone_validated: number;
  cellphone_validation_code: null;
  whatsapp_noitification_enabled: number;
  email_noitification_enabled: number;
  profile_image: string;
  status: string;
  allow_notifications: number;
  domain_redirect_tmp: string | null;
  created_at: Date;
  updated_at: Date;
  deleted_at: Date | null;
  user_id: number;
  specialty_id?: number;
  specialty_name: string | null;
  medical_registration_number: string | null;
  prefix_name: string | null;
  description: string | null;
  twitter_url: string | null;
  instagram_url: string | null;
  linkedin_url: string | null;
  facebook_url: string | null;
  website_url: string | null;
  public_email: string | null;
  public_phone: string | null;
  interrupted_agenda: string | null;
  interrupted_agenda_only_patients: number;
  interrupted_agenda_only_patients_message: string;
  enable_before_payment: boolean;
  before_payment_amount: number;
  before_payment_type: 'fixed' | 'percent';
  full_profile_image: string;
}

interface Error {
  isError: boolean;
  message: string;
  name: string;
}

interface UserState {
  loading: boolean;
  data?: UserData | null;
  error: Error;
}

const initialState: UserState = {
  loading: false,
  data: null,
  error: {
    isError: false,
    message: '',
    name: '',
  },
};

export const userSlice = createSlice({
  name: 'user',
  initialState: initialState,
  reducers: {
    setUserData: (state, action) => {
      state.data = { ...action.payload.user };
    },
    resetUser: (state) => (state = initialState),
  },
  extraReducers: (builder) => {
    builder.addCase(getUser.pending, (state) => {
      state.loading = true;
      state.error = initialState.error;
    });
    builder.addCase(getUser.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = initialState.error;
    });
    builder.addCase(getUser.rejected, (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.data = initialState.data;
      state.error = {
        isError: true,
        message: action.payload.message,
        name: action.payload.name,
      };
    });
  },
});

export const { setUserData, resetUser } = userSlice.actions;
