import { useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Switch, TextInput } from 'src/components/FormInput';
import { Select } from 'src/components/FormInput/Select';
import Button from '../Button';
import locales_es from 'src/locales/es.json';
import {
  FormActionButton,
  FormActionLink,
  InputElement,
} from 'src/utils/models';
import LinkTo from '../LinkTo';
import FormDatePicker from '../FormInput/FormDatePicker';
import { CustomPhoneInput } from '../FormInput/CustomPhoneInput';
import { TextaArea } from '../FormInput/TextArea';
import React from 'react';

export interface DynamicFormProps {
  inputs: InputElement[];
  actions?: (FormActionButton | FormActionLink)[];
  actionWrapperClassName: string;
  onSubmit: (values: any) => void;
  disableSubmitBtn?: boolean;
}

const DynamicForm = ({
  inputs,
  actions,
  actionWrapperClassName,
  onSubmit,
  disableSubmitBtn,
}: DynamicFormProps) => {
  const [seePass, setSeePass] = useState(false);

  const validationSchema = Yup.object().shape(
    inputs.reduce((schema: any, campo: any) => {
      return {
        ...schema,
        [campo.name]: campo.validation,
      };
    }, {})
  );

  const initialValues = inputs.reduce((values: any, campo: any) => {
    return {
      ...values,
      [campo.name]: campo.initialValue,
    };
  }, {});

  const handleSelectChange = (
    fieldName: any,
    value: any,
    setFieldValue: any
  ) => {
    setFieldValue(fieldName, value);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ setFieldValue, handleBlur, values }) => {
        return (
          <Form autoComplete='off'>
            <div className='row'>
              {inputs.map((campo: any, i: number) => (
                <div key={campo.name + i} className={campo.wrapperClassName}>
                  {campo.type === 'text' && (
                    <TextInput
                      name={campo.name}
                      placeholder={campo.label}
                      variant={campo.variant}
                      testId={campo.dataCy}
                      style={campo.style}
                      disabled={campo.disabled}
                      labelStyles={campo.labelStyles}
                      isRequired={
                        validationSchema.fields[campo.name].exclusiveTests
                          .required
                      }
                      label={campo.label}
                    />
                  )}

                  {campo.type === 'password' && (
                    <TextInput
                      name={campo.name}
                      placeholder={campo.label}
                      type={seePass ? 'text' : 'password'}
                      showPass={true}
                      handleEyeClick={() => setSeePass(!seePass)}
                      testId={campo.dataCy}
                      labelStyles={campo.labelStyles}
                      isRequired={
                        validationSchema.fields[campo.name].exclusiveTests
                          .required
                      }
                      label={campo.label}
                    />
                  )}
                  {campo.type === 'select' && (
                    <Select
                      placeholder={campo.label}
                      name={campo.name}
                      list={campo.options}
                      label={campo.label}
                      variant={campo.variant}
                      handleBlur={() => {
                        handleBlur(campo.name);
                      }}
                      onChange={(event: any) => {
                        handleSelectChange(campo.name, event, setFieldValue);
                      }}
                      selectFirstElement={campo.selectFirstElement}
                      testId={campo.dataCy}
                      labelStyles={campo.labelStyles}
                      initialValueName={campo.initialValueName}
                    />
                  )}
                  {campo.type === 'date' && (
                    <FormDatePicker
                      name={campo.name}
                      minDate={campo.minDate}
                      maxDate={campo.maxDate}
                      autoComplete={false}
                      disabled={false}
                      placeholder={campo.placeholder}
                      customClassName=''
                      handleChange={(event: any) => {
                        handleSelectChange(campo.name, event, setFieldValue);
                      }}
                      wrapperCustomClassName=''
                      testId={campo.dataCy}
                      label={campo.label}
                    />
                  )}
                  {campo.type === 'countryCode' && (
                    <CustomPhoneInput
                      name={campo.name}
                      onChange={(event: any) => {
                        handleSelectChange(campo.name, event, setFieldValue);
                      }}
                      placeholder={campo.label}
                      style={campo.style}
                      isRequired={
                        validationSchema.fields[campo.name].exclusiveTests
                          .required
                      }
                      label={campo.label}
                    >
                      {campo.children &&
                        React.cloneElement(campo.children, {
                          [campo.name]: values[campo.name],
                        })}
                    </CustomPhoneInput>
                  )}
                  {campo.type === 'switch' && (
                    <Switch
                      name={campo.name}
                      label={campo.label}
                      title={campo.title}
                      data-cy={campo.dataCy}
                    />
                  )}
                  {campo.type === 'textArea' && (
                    <TextaArea
                      name={campo.name}
                      label={campo.label}
                      placeholder={campo.placeholder}
                      labelStyles={campo.labelStyles}
                    />
                  )}
                </div>
              ))}
              <div className={actionWrapperClassName}>
                {actions && actions?.length ? (
                  actions.map((action: any) => (
                    <div key={action.dataCy}>
                      {action.type === 'button' ||
                        (action.type === 'submit' && (
                          <Button
                            key={action.dataCy}
                            variant={action.variant}
                            type={action.type}
                            classes={action.classes}
                            style={action.style}
                            data-cy={action.dataCy}
                            disabled={disableSubmitBtn}
                          >
                            {action.text}
                          </Button>
                        ))}
                      {action.type === 'link' && (
                        <LinkTo
                          key={action.dataCy}
                          text={action.text}
                          to={action.to}
                          classes={action.classes}
                          styles={action.style}
                          data-cy={action.dataCy}
                        />
                      )}
                    </div>
                  ))
                ) : (
                  <Button
                    variant='primary'
                    type='submit'
                    classes='mx-0 mw-50 fw-light'
                    style={{ letterSpacing: '2px' }}
                    data-cy='LoginForm_submit_button'
                    disabled={disableSubmitBtn}
                  >
                    {locales_es.enter}
                  </Button>
                )}
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default DynamicForm;
