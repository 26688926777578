import React from 'react';
import Avatar from 'src/components/Avatar';
import { DocType, Patient } from './interfaces';
import locales_es from 'src/locales/es.json';
import Button from '../Button';
import styles from './PatiendCard.module.scss';
import clsx from 'clsx';
import Card from '../Card';
import { Link } from 'react-router-dom';
import routes from 'src/router/routes';

interface Props {
  patient: Patient;
  getDocType?: ({ docType }: DocType) => string;
}

const PatientCard = ({ patient, getDocType }: Props) => {
  return (
    <Card variant='primary' classes={styles.container}>
      <div className='d-flex align-items-center border-bottom'>
        <Avatar
          size={60}
          imageUrl={patient.full_profile_image}
          variant='grey'
        />
        <div className='p-3'>
          <p className='m-0'>
            {patient.name} {patient.lastname}
          </p>
          <p className='m-0 text-muted'>{locales_es.patient}</p>
        </div>
      </div>
      <div className='p-3 ps-2'>
        <div className='p-1'>
          <p className={clsx(styles.text, 'text-muted')}>
            {locales_es.emailAddress}:
          </p>
          <p className={styles.text}>{patient.user?.email || 'no ingresa'}</p>
        </div>
        <div className='p-1'>
          <p className={clsx(styles.text, 'text-muted')}>
            {locales_es.numberOf}
            {getDocType &&
              getDocType({ docType: patient.identification_type_id })}
            :
          </p>
          <p className={styles.text}>{patient.identification}</p>
        </div>
        <div className='d-flex p-1'>
          <p className={clsx(styles.text, 'text-muted')}>{locales_es.phone}:</p>
          <p className={styles.text}>
            {patient.user?.cellphone || 'no ingresa'}
          </p>
        </div>
        <div className='p-1'>
          <p className={clsx(styles.text, 'text-muted')}>
            {locales_es.date_of_birth}:
          </p>
          <p className={styles.text}>
            {patient.user?.date_of_birth.toString() || 'no ingresa'}
          </p>
        </div>
      </div>
      <div className='d-flex align-items-center justify-content-around gap-3'>
        <Link to={`${routes.home_patients}/${patient.id}/historiaClinica`}>
          <Button variant='outline' style={{ margin: 0, fontSize: 10 }}>
            Ver Perfil
          </Button>
        </Link>

        <Button
          variant='primary'
          style={{ margin: 0, backgroundColor: '#5867dd', fontSize: 10 }}
        >
          Enviar Mensaje
        </Button>
      </div>
    </Card>
  );
};

export default PatientCard;
