import React from 'react';
import SectionTitle from 'src/components/SectionTitle';
import AddPatientForm from 'src/components/AddPatientForm';
import locales_es from 'src/locales/es.json';

const AddPatient = () => {
  return (
    <>
      <SectionTitle
        sectionText={{ textTitle: locales_es.addPatient, textSubtitle: '' }}
        userText={{ textSubtitle: '', textTitle: '' }}
        showAvatar={false}
        endLine={false}
      />
      <AddPatientForm />
    </>
  );
};

export default AddPatient;
